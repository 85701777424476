import React, { useState } from "react";
import { Formik, FieldArray } from "formik";
import { TabView, TabPanel } from "primereact/tabview";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import useHandleMutationError from "../../../../hooks/useHandleMutationError.js";
import { syncPermissionToRoleService } from "../../../../services/roles/roles-service";

const RowForm = ({ rolesAndModifiedPermissionData }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [createMutationIsLoading, setCreateMutationIsLoading] = useState(false);
    const queryClient = useQueryClient();

    const creactMutation = useMutation({
        mutationFn: syncPermissionToRoleService,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["roles-with-modified-permissions"] });
            toast.success("Roles and Permissions Synced Successfully");
            setCreateMutationIsLoading(false);
        },
    });

    useHandleMutationError(creactMutation?.error, setCreateMutationIsLoading);

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [formValues, setFormValues] = useState(null);

    const handleConfirmOpen = (values) => {
        setFormValues(values);
        setOpenConfirmDialog(true);
    };

    const handleConfirmClose = () => {
        setOpenConfirmDialog(false);
        setCreateMutationIsLoading(false);
    };

    const handleConfirmSubmit = () => {
        setCreateMutationIsLoading(true);
        creactMutation.mutate({ roles: formValues }); // formValues already contains formatted data
        setOpenConfirmDialog(false);
    };

    const handleTabSubmit = (e, values, role) => {
        e.preventDefault(); // Prevent default form submission behavior

        const formattedData = [
            {
                role: role.role, // Include the role name
                permissions: role.permissions, // Include permissions array
            },
        ];
        console.log("🚀 ~ Roles permission sync handleTabSubmit ~ formattedData:", formattedData);
        handleConfirmOpen(formattedData); // Open confirmation dialog with formatted data
    };

    return (
        <>
            <Formik initialValues={{ roles: rolesAndModifiedPermissionData }} onSubmit={() => {}}>
                {({ values, setFieldValue }) => (
                    <form>
                        <TabView scrollable={true} activeIndex={selectedTab} onTabChange={(e) => setSelectedTab(e.index)}>
                            {values.roles.map((role, index) => (
                                <TabPanel key={index} header={role.role}>
                                    <div className="grid">
                                        <FieldArray name={`roles.${index}.permissions`}>
                                            {() =>
                                                role.permissions.map((permission, idx) => (
                                                    <div className="col-12 md:col-6 lg:col-3" key={idx}>
                                                        <div className="p-field-checkbox">
                                                            <Checkbox inputId={`permission-${idx}`} checked={permission.value} onChange={(e) => setFieldValue(`roles.${index}.permissions.${idx}.value`, e.checked)} />
                                                            <label htmlFor={`permission-${idx}`} style={{ marginLeft: "8px" }}>
                                                                {permission.name}
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </FieldArray>
                                    </div>
                                    <div className="p-mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Button
                                            label={createMutationIsLoading ? <ProgressSpinner style={{ width: "20px", height: "20px" }} /> : "Submit to Sync Role Permissions"}
                                            disabled={createMutationIsLoading}
                                            onClick={(e) => handleTabSubmit(e, values, role)}
                                            className="p-button-primary"
                                            style={{ width: "200px" }} // Adjust the width as desired
                                        />
                                    </div>
                                </TabPanel>
                            ))}
                        </TabView>
                    </form>
                )}
            </Formik>

            <Dialog
                header="Confirm Submission"
                visible={openConfirmDialog}
                style={{ width: "400px" }}
                onHide={handleConfirmClose}
                footer={
                    <div>
                        <Button label="Cancel" icon="pi pi-times" onClick={handleConfirmClose} className="p-button-text" />
                        <Button label="Confirm" icon="pi pi-check" onClick={handleConfirmSubmit} autoFocus />
                    </div>
                }
            >
                <p>Are you sure you want to submit?</p>
            </Dialog>
        </>
    );
};

export default RowForm;
