import React from "react";

//
import useAuthContext from "../../../../context/AuthContext";

import FeedbackByTopicPieChart from "./charts/FeedbackByTopicPieChart"; // Import Pie Chart
import FeedbackByTopicDoughnutChart from "./charts/FeedbackByTopicDoughnutChart";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";

const MuiTableServicesFeedbackPdfTemplate = ({ title = "", columns, data, tableData }) => {
    console.log("🚀 ~ MuiTableServicesFeedbackPdfTemplate ~ data:", data);
    console.log("🚀 ~ MuiTableServicesFeedbackPdfTemplate ~ columns:", columns);

    //
    const { getUserQuery } = useAuthContext();

    const loggedInUserDetails = getUserQuery?.data?.data;

    const styles = {
        container: {
            padding: "20px",
            fontFamily: "Arial, sans-serif",
            fontSize: "12px",
        },
        header: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
            flexDirection: "column",
            width: "100%",
        },
        logo: {
            height: "50px",
        },
        title: {
            fontSize: "18px",
            fontWeight: "bold",
        },
        paragraphContainer: {
            marginTop: "20px",
        },
        paragraph: {
            marginBottom: "10px",
            lineHeight: "1.6",
        },
        noDataMessage: {
            fontSize: "14px",
            color: "#888",
            textAlign: "center",
            marginTop: "20px",
        },
        footer: {
            marginTop: "20px",
            textAlign: "center",
            fontSize: "10px",
            color: "#666",
        },
        tablesContainer: {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginTop: "20px",
            gap: "20px",
        },
        table: {
            width: "100%", // Adjust width for responsive design
        },
        statsContainer: {
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            fontSize: "14px",
            fontWeight: "bold",
            borderBottom: "0.2px solid #ddd",
            padding: "10px",
            borderRadius: "5px",
        },
        chartsContainer: {
            display: "flex",
            // flexDirection: "column",
            gap: "1rem",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: "20px",
            alignText: "center",
        },
        chart: {
            display: "flex",
            // flexDirection: "column",
            gap: "1rem",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            width: "500px", // Adjust width for responsive design
        },
    };

    // Helper function to dynamically access nested properties
    function getFlatPathValue(obj, path) {
        return obj[path];
    }

    // Helper function to format date values
    function formatValue(value) {
        if (!value) return "";
        // Check if the value is a valid date
        if (moment(value, moment.ISO_8601, true).isValid()) {
            return moment(value).format("MMMM Do YYYY, h:mm a"); // Customize the format as needed
        }
        return value;
    }

    // Calculate statistics
    const totalFeedback = data.length;
    // Use a Set to find unique region names, accessing the "region.name" key
    const uniqueTopics = new Set(
        data.map((row) => row["topic.name"]).filter((name) => name) // Filter out undefined or null
    ).size;

    return (
        <table id="print-content" style={styles.container}>
            <thead>
                <tr>
                    <td>
                        <div className="header-space">&nbsp;</div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div className="content">
                            {/* Statistics Section */}
                            <div style={styles.statsContainer}>
                                <div>Total Feedback: {totalFeedback}</div>
                                <div>Number of Topics: {uniqueTopics}</div>
                            </div>

                            {/* Paragraph Section or No Data Message */}
                            {/* <div style={styles.paragraphContainer}>
                                {data.length > 0 ? (
                                    data.map((row, rowIndex) => (
                                        <p key={rowIndex} style={styles.paragraph}>
                                            {columns
                                                .map((col) => {
                                                    const value = getFlatPathValue(row, col.field); // Safely access nested property
                                                    return value ? `${col.title}: ${formatValue(value)}.` : "";
                                                })
                                                .join(" ")}
                                        </p>
                                    ))
                                ) : (
                                    <div style={styles.noDataMessage}>No data available</div>
                                )}
                            </div> */}

                            <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                                <DataTable
                                    value={data}
                                    responsiveLayout="scroll"
                                    showGridlines
                                    stripedRows
                                    rowGroupMode="subheader"
                                    groupRowsBy="topic.name"
                                    sortField="topic.name"
                                    sortOrder={1}
                                    rowGroupHeaderTemplate={(group) => <span style={{ fontWeight: "bold", fontSize: "1.1rem" }}>Topic: {group["topic.name"] || "N/A"}</span>}
                                >
                                    {/* Group Header */}
                                    <Column
                                        field="topic.name"
                                        header="topic"
                                        style={{ display: "none" }} // Hide group column from individual rows
                                        headerStyle={{ display: "none" }}
                                    />

                                    {/* Always visible columns */}
                                    <Column field="message" header="Message" />
                                    {/* <Column field="created_at" header="Date Submitted" body={(rowData) => moment(rowData?.created_at).format("MMMM Do YYYY, h:mm:ss a")} />
                                    <Column field="is_anonymous" header="Anonymous Y/N" body={(rowData) => (rowData?.is_anonymous ? "Yes" : "No")} /> */}
                                </DataTable>
                            </div>

                            {/* Charts Section */}
                            <div style={styles.chartsContainer}>
                                <div style={styles.chart}>
                                    <FeedbackByTopicDoughnutChart data={data} />
                                </div>
                                <div style={styles.chart}>
                                    <FeedbackByTopicPieChart data={data} />
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <div className="footer-space">&nbsp;</div>
                    </td>
                </tr>
            </tfoot>
            <div className="header" style={styles.header}>
                <img src="assets/oag_photos/uganda_oag.png" alt="Logo" style={{ height: "50px" }} />
                <span style={styles.title}>{title}</span>
            </div>
            <div className="footer" style={styles.footer}>
                <div>
                    Generated by {loggedInUserDetails?.name} on {moment().format("dddd, MMMM Do YYYY, h:mm:ss A")}
                </div>
                <div>© {new Date().getFullYear()} OAG Citizen Feedback Platform</div>
            </div>
        </table>
    );
};

export default MuiTableServicesFeedbackPdfTemplate;
