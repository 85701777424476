import React, { useState, useEffect, useRef } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { classNames } from "primereact/utils";
import moment from "moment";

import setFieldTouched from "final-form-set-field-touched";
//
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileUpload } from "primereact/fileupload";
import { InputSwitch } from "primereact/inputswitch";
import { Image } from "primereact/image";
import { Toast as PrimeReactToast } from "primereact/toast";
import { Calendar } from "primereact/calendar";

//
import { AutoComplete } from "primereact/autocomplete";
import { ProgressSpinner } from "primereact/progressspinner";

import { getAllCountrys } from "../../../../services/locations/countries-service";
import { getAllRegions } from "../../../../services/locations/regions-service";
import { getAllDistricts } from "../../../../services/locations/districts-service";
import { getAllCounty } from "../../../../services/locations/county-service";
import { getAllSubcounty } from "../../../../services/locations/subcounty-service";
import { getAllParish } from "../../../../services/locations/parish-service";
import { getAllVillage } from "../../../../services/locations/village-service";
import { getAllSectors } from "../../../../services/sectors/sectors-service";
import { getAllDirectorates } from "../../../../services/directorates/directorates-service";

import useHandleQueryError from "../../../../hooks/useHandleQueryError";

//
import countriesData from "./countries.json";
import TaggedFeedbacksTable from "./TaggedFeedbacksTable";

//
import LocationPickerMap from "./LocationPickerMap";
import { getAllAuditAreas } from "../../../../services/audit-areas/audit-areas-service.js";
import { getAllAuditTypes } from "../../../../services/audit-types/audit-types-service";
import { MultiSelect } from "primereact/multiselect";

function SummarizeFeedbackRowForm({ handleSubmit, selectedTableRowsData, initialData, ...props }) {
    console.log("🚀df ~ SummarizeFeedbackRowForm ~ initialData:", initialData);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    //
    const [locationPickerMapSelectedLatLng, setLocationPickerMapSelectedLatLng] = useState({ lat: initialData?.latitude, lng: initialData?.longitude });

    //======   tagged feedback =======
    const [taggedFeedbacks, setTaggedFeedbacks] = useState(selectedTableRowsData ?? []);

    ///
    const [selectedLocationScope, setSelectedLocationScope] = useState(initialData?.location_scope);

    const locationScopeOptions = [
        { label: "Central Government", value: "Central Government" },
        { label: "International", value: "International" },
        { label: "Local Government", value: "Local Government" },
    ];

    //============ Auto Complete States =================
    const [dropdownCountries, setDropdownCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(initialData?.country);

    const [dropdownRegions, setDropdownRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(initialData?.region);

    const [dropdownDistricts, setDropdownDistricts] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState(initialData?.district);

    const [dropdownCounties, setDropdownCounties] = useState([]);
    const [selectedCounty, setSelectedCounty] = useState(initialData?.county);

    const [dropdownSubcounties, setDropdownSubcounties] = useState([]);
    const [selectedSubcounty, setSelectedSubcounty] = useState(initialData?.subcounty);

    const [dropdownParishes, setDropdownParishes] = useState([]);
    const [selectedParish, setSelectedParish] = useState(initialData?.parish);

    const [dropdownVillages, setDropdownVillages] = useState([]);
    const [selectedVillage, setSelectedVillage] = useState(initialData?.village);

    const countriesQuery = useQuery({
        queryKey: "countries",
        queryFn: getAllCountrys,
        enabled: selectedLocationScope === "International" ? true : false,
    });

    useHandleQueryError(countriesQuery?.isError, countriesQuery?.error);

    // Fetch regions
    const regionsQuery = useQuery({
        queryKey: "regions",
        queryFn: getAllRegions,
    });
    useHandleQueryError(regionsQuery?.isError, regionsQuery?.error);

    // Fetch districts based on selected region
    const districtsQuery = useQuery({
        queryKey: ["districts", selectedRegion],
        queryFn: () => getAllDistricts({ region_id: selectedRegion?.id }),
        enabled: !!selectedRegion, // Ensures the query is run only when a region is selected
    });
    useHandleQueryError(districtsQuery?.isError, districtsQuery?.error);

    // Fetch counties based on selected district
    const countiesQuery = useQuery({
        queryKey: ["counties", selectedDistrict],
        queryFn: () => getAllCounty({ district_id: selectedDistrict?.id }),
        enabled: !!selectedDistrict,
    });
    useHandleQueryError(countiesQuery?.isError, countiesQuery?.error);

    // Fetch subcounties based on selected county
    const subcountiesQuery = useQuery({
        queryKey: ["subcounties", selectedCounty],
        queryFn: () => getAllSubcounty({ county_id: selectedCounty?.id }),
        enabled: !!selectedCounty,
    });
    useHandleQueryError(subcountiesQuery?.isError, subcountiesQuery?.error);

    // Fetch parishes based on selected subcounty
    const parishesQuery = useQuery({
        queryKey: ["parishes", selectedSubcounty],
        queryFn: () => getAllParish({ sub_county_id: selectedSubcounty?.id }),
        enabled: !!selectedSubcounty,
    });
    useHandleQueryError(parishesQuery?.isError, parishesQuery?.error);

    // Fetch villages based on selected parish
    const villagesQuery = useQuery({
        queryKey: ["villages", selectedParish],
        queryFn: () => getAllVillage({ parish_id: selectedParish?.id }),
        enabled: !!selectedParish,
    });
    useHandleQueryError(villagesQuery?.isError, villagesQuery?.error);

    //=========================

    const [selectedSector, setSelectedSector] = useState(initialData?.sector);
    const [dropdownSectors, setDropdownSectors] = useState([]);

    const sectorsQuery = useQuery({
        queryKey: ["sectors"],
        queryFn: () => getAllSectors(),
    });

    // Use the custom hook to handle errors for sectorsQuery
    useHandleQueryError(sectorsQuery?.isError, sectorsQuery?.error);

    const [selectedAuditAreas, setSelectedAuditAreas] = useState(initialData?.audit_areas ?? []);
    const getAllAuditAreasQuery = useQuery({
        queryKey: ["audit-areas"],
        queryFn: () => getAllAuditAreas(),
    });

    // Use the custom hook to handle errors for getAllAuditAreasQuery
    useHandleQueryError(getAllAuditAreasQuery?.isError, getAllAuditAreasQuery?.error);

    const [selectedAuditTypes, setSelectedAuditTypes] = useState(initialData?.audit_types ?? []);
    const getAllAuditTypesQuery = useQuery({
        queryKey: ["audit-types"],
        queryFn: () => getAllAuditTypes(),
    });

    // Use the custom hook to handle errors for getAllAuditTypesQuery
    useHandleQueryError(getAllAuditTypesQuery?.isError, getAllAuditTypesQuery?.error);

    //========== directorates =====================
    const [selectedProposedDirectorate, setSelectedProposedDirectorate] = useState(initialData?.proposed_directorate);
    const [selectedResponsibleDirectorate, setSelectedResponsibleDirectorate] = useState(initialData?.responsible_directorate);

    const [proposedDropdownDirectorates, setProposedDropdownDirectorates] = useState([]);
    const [responsibleDropdownDirectorates, setResponsibleDropdownDirectorates] = useState([]);

    const directoratesQuery = useQuery({
        queryKey: ["directorates"],
        queryFn: () => getAllDirectorates(), // Fetch directorates
    });

    // Handle query errors
    useHandleQueryError(directoratesQuery?.isError, directoratesQuery?.error);

    //
    const validate = (values) => {
        const errors = {};

        // if (!values.is_anonymous) errors.is_anonymous = "is anonymous is required";
        // Validate 'name'

        if (!values.title) errors.title = "title is required";
        if (!values.message) errors.message = "message is required";
        if (!values.proposed_actions) errors.proposed_actions = "Proposed actions are required";
        // if (!values.agreed_actions) errors.agreed_actions = "Agreed actions are required";
        if (!values.audit_areas || values.audit_areas.length === 0) errors.audit_areas = "At least one audit area is required";
        if (!values.audit_types || values.audit_types.length === 0) errors.audit_types = "At least one audit type is required";

        // Validate 'status'
        if (!values.status) errors.status = "Status is required";

        // if (!values.latitude) errors.latitude = "latitude is required";

        // if (!values.longitude) errors.longitude = "longitude is required";

        // Validate 'details'
        if (!values.sector) errors.sector = "sector are required";

        // Validate 'proposed_directorate'
        if (!values.proposed_directorate) errors.proposed_directorate = "Proposed directorate is required";

        // // Validate 'responsible_directorate'
        // if (!values.responsible_directorate) errors.responsible_directorate = "Responsible directorate is required";

        if (!values.location_scope) errors.location_scope = "location scope are required";

        if (!values?.country && values?.location_scope === "International") {
            errors.country = "Country must be selected";
        }

        // Validate 'regions'
        if (!values?.region && values?.location_scope === "Local Government") {
            errors.region = "Region must be selected";
        }

        // Validate 'districts'
        if (values?.region && !values?.district && values?.location_scope === "Local Government") {
            errors.district = "District must be selected";
        }

        // Validate 'counties'
        if (values?.district && !values?.county && values?.location_scope === "Local Government") {
            errors.county = "County must be selected";
        }

        // Validate 'subcounties'
        if (values?.county && !values?.subcounty && values?.location_scope === "Local Government") {
            errors.subcounty = "Subcounty must be selected";
        }

        // // Validate 'parishes'
        // if (values?.subcounty && !values?.parish) {
        //     errors.parish = "Parish must be selected";
        // }

        // // Validate 'villages'
        // if (values?.parish && !values?.village) {
        //     errors.village = "Village must be selected";
        // }

        return errors;
    };

    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            // No validation errors
            let finalData = { ...data, tagged_feedback: taggedFeedbacks };
            setPendingData(finalData);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please first fill in all required fields");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <Field name="title">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="title">Title</label>
                                        <InputText {...input} id="title" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="message">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="message">Message</label>
                                        <InputTextarea {...input} id="message" rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="proposed_actions">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="proposed_actions">Proposed Actions</label>
                                        <InputTextarea {...input} id="proposed_actions" rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* <Field name="agreed_actions">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="agreed_actions">Agreed Actions</label>
                                        <InputTextarea {...input} id="agreed_actions" rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}

                            <Field name="audit_areas">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="audit_areas">Audit Areas</label>
                                        <MultiSelect
                                            {...input}
                                            id="audit_areas"
                                            value={selectedAuditAreas} // Sync with local state
                                            options={getAllAuditAreasQuery.data?.data?.data || []}
                                            optionLabel="name"
                                            placeholder="Select Audit Areas"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            disabled={getAllAuditAreasQuery?.isLoading}
                                            dataKey="id"
                                            loading={getAllAuditAreasQuery?.isLoading}
                                            emptyFilterMessage="No audit areas found"
                                            filter // Enable search functionality
                                            filterBy="name" // Search by 'name' field
                                            onChange={(e) => {
                                                setSelectedAuditAreas(e.value); // Update local state
                                                input.onChange(e.value); // Update Final Form state
                                            }}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="audit_types">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="audit_types">Audit Types</label>
                                        <MultiSelect
                                            {...input}
                                            id="audit_types"
                                            value={selectedAuditTypes} // Sync with local state
                                            options={getAllAuditTypesQuery.data?.data?.data || []}
                                            optionLabel="name"
                                            placeholder="Select Audit Types"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            disabled={getAllAuditTypesQuery?.isLoading}
                                            dataKey="id"
                                            loading={getAllAuditTypesQuery?.isLoading}
                                            emptyFilterMessage="No audit types found"
                                            filter // Enable search functionality
                                            filterBy="name" // Search by 'name' field
                                            onChange={(e) => {
                                                setSelectedAuditTypes(e.value); // Update local state
                                                input.onChange(e.value); // Update Final Form state
                                            }}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="proposed_directorate">
                                {({ input, meta }) => {
                                    const directoratesData = directoratesQuery?.data?.data?.data || [];

                                    const fetchSuggestions = (event) => {
                                        let query = event.query.toLowerCase();
                                        let filtered = directoratesData.filter((dir) => dir?.name?.toLowerCase().includes(query));
                                        setProposedDropdownDirectorates(filtered);
                                    };

                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="proposed_directorate">Proposed Directorate</label>
                                            <AutoComplete
                                                {...input}
                                                multiple={false}
                                                suggestions={proposedDropdownDirectorates} // Correct state used here
                                                completeMethod={fetchSuggestions}
                                                field="name"
                                                value={selectedProposedDirectorate}
                                                onChange={(e) => {
                                                    setSelectedProposedDirectorate(e.value);
                                                    input.onChange(e.value);
                                                }}
                                                dropdown={true}
                                                disabled={directoratesQuery?.isLoading}
                                                placeholder="Select proposed directorate"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            {directoratesQuery?.isLoading && <ProgressSpinner style={{ width: "20px", height: "20px" }} />}
                                        </div>
                                    );
                                }}
                            </Field>

                            {/* <Field name="responsible_directorate">
                                {({ input, meta }) => {
                                    const directoratesData = directoratesQuery?.data?.data?.data || [];

                                    const fetchSuggestions = (event) => {
                                        let query = event.query.toLowerCase();
                                        let filtered = directoratesData.filter((dir) => dir?.name?.toLowerCase().includes(query));
                                        setResponsibleDropdownDirectorates(filtered);
                                    };

                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="responsible_directorate">Responsible Directorate</label>
                                            <AutoComplete
                                                {...input}
                                                multiple={false}
                                                suggestions={responsibleDropdownDirectorates} // Correct state used here
                                                completeMethod={fetchSuggestions}
                                                field="name"
                                                value={selectedResponsibleDirectorate}
                                                onChange={(e) => {
                                                    setSelectedResponsibleDirectorate(e.value);
                                                    input.onChange(e.value);
                                                }}
                                                dropdown={true}
                                                disabled={directoratesQuery?.isLoading}
                                                placeholder="Select responsible directorate"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            {directoratesQuery?.isLoading && <ProgressSpinner style={{ width: "20px", height: "20px" }} />}
                                        </div>
                                    );
                                }}
                            </Field>

                            <Field name="timeline_start_date">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="timeline_start_date">Timeline Start Date</label>
                                        <Calendar {...input} id="timeline_start_date" showIcon showButtonBar dateFormat="dd/mm/yy" value={input.value ? moment.utc(input.value).toDate() : null} className={classNames({ "p-invalid": meta.error && meta.touched })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="timeline_end_date">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="timeline_end_date">Timeline End Date</label>
                                        <Calendar {...input} id="timeline_end_date" showIcon showButtonBar dateFormat="dd/mm/yy" value={input.value ? moment.utc(input.value).toDate() : null} className={classNames({ "p-invalid": meta.error && meta.touched })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}

                            <Field name="status">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="status">Status</label>
                                        <Dropdown
                                            {...input}
                                            options={[
                                                { id: "active", name: "Active" },
                                                { id: "deactive", name: "Deactive" },
                                            ].map((role) => ({ label: role.name, value: role.id }))}
                                            placeholder="Select a Status"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* <Field name="feedback_submit_status">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="feedback_submit_status">Feedback Submit Status</label>
                                        <Dropdown
                                            {...input}
                                            options={[
                                                { label: "Draft", value: "draft" },
                                                { label: "Submitted", value: "submitted" },
                                            ]}
                                            placeholder="Select status"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}

                            <Field name="has_attachments" type="checkbox">
                                {({ input }) => (
                                    <div className="p-field-checkbox m-4">
                                        <InputSwitch
                                            {...input}
                                            checked={!!input.checked ? true : false}
                                            onChange={(e) => {
                                                input.onChange(e.value);
                                                // Clear the password fields if `has_attachments` is unchecked
                                                // if (!e.value) {
                                                //     form.change("password", null);
                                                //     form.change("confirm_password", null);
                                                // }
                                            }}
                                        />
                                        <label htmlFor="has_attachments">Has Attachments</label>
                                    </div>
                                )}
                            </Field>
                            {/* Add more fields for accuracy, altitude, speed_accuracy */}
                            {/* Administrative division fields */}
                            {/* <Field name="region_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="region_id">Region</label>
                                        <Dropdown {...input} options={regions} optionLabel="name" placeholder="Select a region" className={classNames({ 'p-invalid': meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}

                            <Field name="sector">
                                {({ input, meta }) => {
                                    const sectorsData = sectorsQuery?.data?.data?.data || [];
                                    const fetchSuggestions = (event) => {
                                        let query = event.query.toLowerCase();
                                        let filtered = sectorsData.filter((sector) => sector?.name?.toLowerCase().includes(query));
                                        setDropdownSectors(filtered);
                                    };

                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="sector">Sector</label>
                                            <AutoComplete
                                                {...input}
                                                multiple={false} // Set to false for single selection
                                                suggestions={dropdownSectors}
                                                completeMethod={fetchSuggestions}
                                                field="name"
                                                value={selectedSector} // Single value for sector
                                                onChange={(e) => {
                                                    setSelectedSector(e.value);
                                                    input.onChange(e.value);
                                                }}
                                                dropdown={true}
                                                disabled={sectorsQuery?.isLoading}
                                                placeholder="Select sector"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                            {/* Show loading spinner if sectorsQuery is loading */}
                                            {sectorsQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    );
                                }}
                            </Field>
                            {/* <Field name="details">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="details">details</label>
                                        <InputTextarea {...input} rows={5} cols={30} id="details" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}

                            {/* Location fields */}
                            {/* Include LocationPickerMap component */}
                            <div className="p-field m-4">
                                <LocationPickerMap
                                    locationPickerMapSelectedLatLng={locationPickerMapSelectedLatLng} // Default map center
                                    // setLocationPickerMapSelectedLatLng={setLocationPickerMapSelectedLatLng}
                                    setLocationPickerMapSelectedLatLng={(newLatLng) => {
                                        setLocationPickerMapSelectedLatLng(newLatLng); // Update the state

                                        // Synchronize with Final Form values
                                        form.change("latitude", newLatLng.lat);
                                        form.change("longitude", newLatLng.lng);
                                    }}
                                />
                            </div>
                            {/* Location fields */}
                            {/* Location fields */}
                            <Field name="latitude">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="latitude">Latitude</label>
                                        <InputText
                                            {...input}
                                            id="latitude"
                                            type="number"
                                            value={locationPickerMapSelectedLatLng.lat}
                                            onChange={(e) => {
                                                const newLat = isNaN(parseFloat(e.target.value)) ? "" : parseFloat(e.target.value); // Parse the value to a number
                                                setLocationPickerMapSelectedLatLng((oldData) => ({
                                                    ...oldData,
                                                    lat: newLat,
                                                }));

                                                input.onChange(newLat); // Pass the numeric value to Final Form
                                            }}
                                            // readOnly
                                        />
                                    </div>
                                )}
                            </Field>
                            <Field name="longitude">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="longitude">Longitude</label>
                                        <InputText
                                            {...input}
                                            id="longitude"
                                            type="number"
                                            value={locationPickerMapSelectedLatLng.lng}
                                            onChange={(e) => {
                                                const newLng = isNaN(parseFloat(e.target.value)) ? "" : parseFloat(e.target.value);
                                                setLocationPickerMapSelectedLatLng((oldData) => ({
                                                    ...oldData,
                                                    lng: newLng,
                                                }));

                                                input.onChange(newLng);
                                            }}
                                            // readOnly
                                        />
                                    </div>
                                )}
                            </Field>

                            {/* Location Scope Field */}
                            {/* Location Scope Field */}
                            <Field name="location_scope">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="location_scope">Location Scope</label>
                                        <Dropdown
                                            {...input}
                                            value={selectedLocationScope}
                                            onChange={(e) => {
                                                setSelectedLocationScope(e.value);
                                                input.onChange(e.value);

                                                switch (e.value) {
                                                    case "Central Government":
                                                        // Clear fields for both "International" and "Local Government"
                                                        setSelectedCountry(null);
                                                        form.change("country", null);

                                                        // // Clear Local Government dependent fields
                                                        // setSelectedRegion(null);
                                                        // setDropdownRegions([]);
                                                        // setSelectedDistrict(null);
                                                        // setDropdownDistricts([]);
                                                        // setSelectedCounty(null);
                                                        // setDropdownCounties([]);
                                                        // setSelectedSubcounty(null);
                                                        // setDropdownSubcounties([]);
                                                        // setSelectedParish(null);
                                                        // setDropdownParishes([]);
                                                        // setSelectedVillage(null);
                                                        // setDropdownVillages([]);

                                                        // // Clear in form state
                                                        // form.change("region", null);
                                                        // form.change("district", null);
                                                        // form.change("county", null);
                                                        // form.change("subcounty", null);
                                                        // form.change("parish", null);
                                                        // form.change("village", null);
                                                        break;

                                                    case "Local Government":
                                                        // Clear fields for both "International" and "Local Government"
                                                        setSelectedCountry(null);
                                                        form.change("country", null);
                                                        break;

                                                    case "International":
                                                        // Clear Local Government dependent fields
                                                        setSelectedRegion(null);
                                                        setDropdownRegions([]);
                                                        setSelectedDistrict(null);
                                                        setDropdownDistricts([]);
                                                        setSelectedCounty(null);
                                                        setDropdownCounties([]);
                                                        setSelectedSubcounty(null);
                                                        setDropdownSubcounties([]);
                                                        setSelectedParish(null);
                                                        setDropdownParishes([]);
                                                        setSelectedVillage(null);
                                                        setDropdownVillages([]);

                                                        // Clear in form state
                                                        form.change("region", null);
                                                        form.change("district", null);
                                                        form.change("county", null);
                                                        form.change("subcounty", null);
                                                        form.change("parish", null);
                                                        form.change("village", null);
                                                        break;

                                                    default:
                                                        // Clear only country field for non-International options
                                                        // setSelectedCountry(null);
                                                        // form.change("country", null);
                                                        break;
                                                }
                                            }}
                                            options={locationScopeOptions}
                                            placeholder="Select Location Scope"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* Country Field (only if location scope is International) */}
                            {/* Country Field (only if location scope is International) */}
                            {selectedLocationScope === "International" && (
                                <>
                                    {/* Country Field */}
                                    <Field name="country">
                                        {({ input, meta }) => {
                                            const countriesData = countriesQuery?.data?.data?.data || [];

                                            console.log("🚀 ~ SummarizeFeedbackRowForm ~ countriesData:", countriesData);

                                            const fetchCountrySuggestions = (event) => {
                                                let query = event.query.toLowerCase();
                                                let filtered = countriesData.filter((country) => country?.name?.toLowerCase().includes(query));
                                                setDropdownCountries(filtered);
                                            };

                                            return (
                                                <div className="p-field m-4">
                                                    <label htmlFor="country">Country</label>
                                                    <AutoComplete
                                                        {...input}
                                                        multiple={false}
                                                        suggestions={dropdownCountries}
                                                        completeMethod={fetchCountrySuggestions}
                                                        field="name"
                                                        value={selectedCountry}
                                                        onChange={(e) => {
                                                            setSelectedCountry(e.value);
                                                            input.onChange(e.value);

                                                            // Reset dependent fields if necessary
                                                            // For example:
                                                            // setSelectedRegion(null);
                                                            // setDropdownRegions([]);
                                                        }}
                                                        dropdown={true}
                                                        disabled={countriesQuery?.isLoading}
                                                        placeholder="Select country"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                    {/* Show loading spinner if countriesQuery is loading */}
                                                    {countriesQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            );
                                        }}
                                    </Field>
                                </>
                            )}

                            {(selectedLocationScope === "Local Government" || selectedLocationScope === "Central Government") && (
                                <>
                                    {/* Region Field */}
                                    <Field name="region">
                                        {({ input, meta }) => {
                                            const regionsData = regionsQuery?.data?.data?.data || [];
                                            const fetchRegionSuggestions = (event) => {
                                                let query = event.query.toLowerCase();
                                                let filtered = regionsData.filter((region) => region?.name?.toLowerCase().includes(query));
                                                setDropdownRegions(filtered);
                                            };

                                            return (
                                                <div className="p-field m-4">
                                                    <label htmlFor="region">Region</label>
                                                    <AutoComplete
                                                        {...input}
                                                        multiple={false}
                                                        suggestions={dropdownRegions}
                                                        completeMethod={fetchRegionSuggestions}
                                                        field="name"
                                                        value={selectedRegion}
                                                        onChange={(e) => {
                                                            setSelectedRegion(e.value);
                                                            input.onChange(e.value);

                                                            // Reset dependent fields
                                                            setSelectedDistrict(null);
                                                            setDropdownDistricts([]);
                                                            setSelectedCounty(null);
                                                            setDropdownCounties([]);
                                                            setSelectedSubcounty(null);
                                                            setDropdownSubcounties([]);
                                                            setSelectedParish(null);
                                                            setDropdownParishes([]);
                                                            setSelectedVillage(null);
                                                            setDropdownVillages([]);

                                                            // Reset form fields for the dependent fields
                                                            form.change("district", null);
                                                            form.change("county", null);
                                                            form.change("subcounty", null);
                                                            form.change("parish", null);
                                                            form.change("village", null);
                                                        }}
                                                        dropdown={true}
                                                        disabled={regionsQuery?.isLoading}
                                                        placeholder="Select region"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                    {/* Show loading spinner if regionsQuery is loading */}
                                                    {regionsQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            );
                                        }}
                                    </Field>

                                    <Field name="district">
                                        {({ input, meta }) => {
                                            const districtsData = districtsQuery?.data?.data?.data || [];
                                            const fetchDistrictSuggestions = (event) => {
                                                let query = event.query.toLowerCase();
                                                let filtered = districtsData.filter((district) => district?.name?.toLowerCase().includes(query));
                                                setDropdownDistricts(filtered);
                                            };

                                            return (
                                                <div className="p-field m-4">
                                                    <label htmlFor="district">District</label>
                                                    <AutoComplete
                                                        {...input}
                                                        multiple={false}
                                                        suggestions={dropdownDistricts}
                                                        completeMethod={fetchDistrictSuggestions}
                                                        field="name"
                                                        value={selectedDistrict}
                                                        onChange={(e) => {
                                                            setSelectedDistrict(e.value);
                                                            input.onChange(e.value);

                                                            // Reset dependent fields
                                                            setSelectedCounty(null);
                                                            setDropdownCounties([]);
                                                            setSelectedSubcounty(null);
                                                            setDropdownSubcounties([]);
                                                            setSelectedParish(null);
                                                            setDropdownParishes([]);
                                                            setSelectedVillage(null);
                                                            setDropdownVillages([]);

                                                            // Reset form fields for the dependent fields
                                                            form.change("county", null);
                                                            form.change("subcounty", null);
                                                            form.change("parish", null);
                                                            form.change("village", null);
                                                        }}
                                                        dropdown={true}
                                                        disabled={!selectedRegion}
                                                        placeholder="Select district"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                    {/* Show loading spinner if districtsQuery is loading */}
                                                    {districtsQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            );
                                        }}
                                    </Field>

                                    {/* County Field */}
                                    <Field name="county">
                                        {({ input, meta }) => {
                                            const countiesData = countiesQuery?.data?.data?.data || [];
                                            const fetchCountySuggestions = (event) => {
                                                let query = event.query.toLowerCase();
                                                let filtered = countiesData.filter((county) => county?.name?.toLowerCase().includes(query));
                                                setDropdownCounties(filtered);
                                            };

                                            return (
                                                <div className="p-field m-4">
                                                    <label htmlFor="county">County</label>
                                                    <AutoComplete
                                                        {...input}
                                                        multiple={false}
                                                        suggestions={dropdownCounties}
                                                        completeMethod={fetchCountySuggestions}
                                                        field="name"
                                                        value={selectedCounty}
                                                        onChange={(e) => {
                                                            setSelectedCounty(e.value);

                                                            input.onChange(e.value);

                                                            // Reset dependent fields

                                                            setSelectedSubcounty(null);
                                                            setDropdownSubcounties([]);
                                                            setSelectedParish(null);
                                                            setDropdownParishes([]);
                                                            setSelectedVillage(null);
                                                            setDropdownVillages([]);

                                                            // Reset form fields for the dependent fields

                                                            form.change("subcounty", null);
                                                            form.change("parish", null);
                                                            form.change("village", null);
                                                        }}
                                                        dropdown={true}
                                                        disabled={!selectedDistrict || countiesQuery?.isLoading}
                                                        placeholder="Select county"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {/* Show loading spinner if regionsQuery is loading */}
                                                    {countiesQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            );
                                        }}
                                    </Field>

                                    {/* Subcounty Field */}
                                    <Field name="subcounty">
                                        {({ input, meta }) => {
                                            const subcountiesData = subcountiesQuery?.data?.data?.data || [];
                                            const fetchSubcountySuggestions = (event) => {
                                                let query = event.query.toLowerCase();
                                                let filtered = subcountiesData.filter((subcounty) => subcounty?.name?.toLowerCase().includes(query));
                                                setDropdownSubcounties(filtered);
                                            };

                                            return (
                                                <div className="p-field m-4">
                                                    <label htmlFor="subcounty">Subcounty</label>
                                                    <AutoComplete
                                                        {...input}
                                                        multiple={false}
                                                        suggestions={dropdownSubcounties}
                                                        completeMethod={fetchSubcountySuggestions}
                                                        field="name"
                                                        value={selectedSubcounty}
                                                        onChange={(e) => {
                                                            setSelectedSubcounty(e.value);

                                                            input.onChange(e.value);

                                                            // Reset dependent fields

                                                            setSelectedParish(null);
                                                            setDropdownParishes([]);
                                                            setSelectedVillage(null);
                                                            setDropdownVillages([]);

                                                            // Reset form fields for the dependent fields

                                                            form.change("parish", null);
                                                            form.change("village", null);
                                                        }}
                                                        dropdown={true}
                                                        disabled={!selectedCounty || subcountiesQuery?.isLoading}
                                                        placeholder="Select subcounty"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {/* Show loading spinner if subcountiesQuery is loading */}
                                                    {subcountiesQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            );
                                        }}
                                    </Field>

                                    {/* Parish Field */}
                                    <Field name="parish">
                                        {({ input, meta }) => {
                                            const parishesData = parishesQuery?.data?.data?.data || [];
                                            const fetchParishSuggestions = (event) => {
                                                let query = event.query.toLowerCase();
                                                let filtered = parishesData.filter((parish) => parish?.name?.toLowerCase().includes(query));
                                                setDropdownParishes(filtered);
                                            };

                                            return (
                                                <div className="p-field m-4">
                                                    <label htmlFor="parish">Parish</label>
                                                    <AutoComplete
                                                        {...input}
                                                        multiple={false}
                                                        suggestions={dropdownParishes}
                                                        completeMethod={fetchParishSuggestions}
                                                        field="name"
                                                        value={selectedParish}
                                                        onChange={(e) => {
                                                            setSelectedParish(e.value);

                                                            input.onChange(e.value);

                                                            setSelectedVillage(null);
                                                            setDropdownVillages([]);

                                                            // Reset form fields for the dependent fields

                                                            form.change("village", null);
                                                        }}
                                                        dropdown={true}
                                                        disabled={!selectedSubcounty || parishesQuery?.isLoading}
                                                        placeholder="Select parish"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {/* Show loading spinner if parishesQuery is loading */}
                                                    {parishesQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            );
                                        }}
                                    </Field>

                                    {/* Village Field */}
                                    <Field name="village">
                                        {({ input, meta }) => {
                                            const villagesData = villagesQuery?.data?.data?.data || [];
                                            const fetchVillageSuggestions = (event) => {
                                                let query = event.query.toLowerCase();
                                                let filtered = villagesData.filter((village) => village?.name?.toLowerCase().includes(query));
                                                setDropdownVillages(filtered);
                                            };

                                            return (
                                                <div className="p-field m-4">
                                                    <label htmlFor="village">Village</label>
                                                    <AutoComplete
                                                        {...input}
                                                        multiple={false}
                                                        suggestions={dropdownVillages}
                                                        completeMethod={fetchVillageSuggestions}
                                                        field="name"
                                                        value={selectedVillage}
                                                        onChange={(e) => {
                                                            setSelectedVillage(e.value);
                                                            input.onChange(e.value);
                                                        }}
                                                        dropdown={true}
                                                        disabled={!selectedParish || villagesQuery?.isLoading}
                                                        placeholder="Select village"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {/* Show loading spinner if villagesQuery is loading */}
                                                    {villagesQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            );
                                        }}
                                    </Field>
                                </>
                            )}

                            {/* ================== Original tagged feedback ============ */}
                            <div className="p-field m-4">
                                <TaggedFeedbacksTable feedbacks={taggedFeedbacks} setFeedbacks={setTaggedFeedbacks} />
                            </div>

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    maximizable
                    style={{ minWidth: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default SummarizeFeedbackRowForm;
