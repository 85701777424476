import React, { useState } from "react";
import useAuthContext from "../../context/AuthContext";
import { TabView, TabPanel } from "primereact/tabview";

import FeedbackReportsListPage from "./ListPage";

import OverviewListPage from "./OverviewListPage";

//
function RowReportsPage() {
    const { getUserQuery } = useAuthContext();
    // State to manage the active tab
    let tabIndex = 0;

    if (getUserQuery?.data?.data?.role === "Director Stakeholder Engagement") {
        tabIndex = 1;
    }

    if (getUserQuery?.data?.data?.role === "AAGC") {
        tabIndex = 2;
    }

    if (getUserQuery?.data?.data?.role === "AAGA") {
        tabIndex = 5;
    }

    if (getUserQuery?.data?.data?.role === "Director SORA") {
        tabIndex = 6;
    }
    if (["Directorate Officer", "AG"].includes(getUserQuery?.data?.data?.role)) {
        tabIndex = 9;
    }

    const [activeTabIndex, setActiveTabIndex] = useState(tabIndex);

    console.log("🚀 ~ RowReportsPage ~ getUserQuery:", getUserQuery);
    return (
        <div>
            <TabView scrollable={true} activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
                {["Feedback Coordinator", "OAG Admin"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="Feedback Reports">
                        <FeedbackReportsListPage showProposingColumns={true} showAgreedColumns={false} level="reports" spatieCurrentStatuses={["draft", "submitted", "new"]} loggedInUserData={getUserQuery?.data?.data} />
                    </TabPanel>
                )}

                {["Feedback Coordinator", "Director Stakeholder Engagement", "OAG Admin"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="Director Stakeholder Engagement">
                        <FeedbackReportsListPage
                            showProposingColumns={true}
                            level="Director Stakeholder Engagement"
                            spatieCurrentStatuses={["Director Stakeholder Engagement"]}
                            multipleSelect={getUserQuery?.data?.data?.role === "Director Stakeholder Engagement" ? false : true}
                            loggedInUserData={getUserQuery?.data?.data}
                            showComments={true}
                        />
                    </TabPanel>
                )}

                {["AAGC", "OAG Admin"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="AAGC">
                        <FeedbackReportsListPage showProposingColumns={true} level="AAGC" spatieCurrentStatuses={["AAGC"]} loggedInUserData={getUserQuery?.data?.data} />
                    </TabPanel>
                )}
                {["AAGC", "OAG Admin"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="AAGC Accepted Reports">
                        <FeedbackReportsListPage showProposingColumns={true} level="Accepted by AAGC" spatieCurrentStatuses={["Accepted by AAGC"]} loggedInUserData={getUserQuery?.data?.data} />
                    </TabPanel>
                )}

                {["AAGC", "Feedback Coordinator", "OAG Admin"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="AAGC Rejected Reports">
                        <FeedbackReportsListPage showProposingColumns={true} level="Rejected by AAGC" spatieCurrentStatuses={["Rejected by AAGC"]} loggedInUserData={getUserQuery?.data?.data} />
                    </TabPanel>
                )}

                {["AAGA", "OAG Admin"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="AAGA">
                        <FeedbackReportsListPage level="AAGA" spatieCurrentStatuses={["AAGA"]} loggedInUserData={getUserQuery?.data?.data} showProposingColumns={true} />
                    </TabPanel>
                )}

                {["Director SORA", "OAG Admin"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="Director SORA">
                        <FeedbackReportsListPage level="Director SORA" spatieCurrentStatuses={["Director SORA"]} loggedInUserData={getUserQuery?.data?.data} multipleSelect={false} showAgreedColumns={true} />
                    </TabPanel>
                )}

                {["Director SORA", "OAG Admin"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="In Progress">
                        <FeedbackReportsListPage level="Director SORA" spatieCurrentStatuses={["In Progress"]} loggedInUserData={getUserQuery?.data?.data} multipleSelect={false} showAgreedColumns={true} />
                    </TabPanel>
                )}

                {["Director SORA", "OAG Admin"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="Completed">
                        <FeedbackReportsListPage level="Director SORA" spatieCurrentStatuses={["Completed"]} loggedInUserData={getUserQuery?.data?.data} multipleSelect={false} showAgreedColumns={true} />
                    </TabPanel>
                )}
                {["OAG Admin", "Feedback Coordinator", "Director Stakeholder Engagement", "AAGC", "AAGA", "Director SORA", "Directorate Officer", "AG"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="Reports Overview">
                        <OverviewListPage loggedInUserData={getUserQuery?.data?.data} showAgreedColumns={true} />
                    </TabPanel>
                )}
            </TabView>
        </div>
    );
}

export default RowReportsPage;
