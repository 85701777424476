import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";

import { getAllFeedbackReports, getFeedbackReportById, postFeedbackReport, postToCreateFeedbackQuarterlyReport, updateFeedbackReport, deleteFeedbackReportById } from "../../services/feedback-reports/feedback-reports-service.js";

import QuarterlyReportRowForm from "./widgets/QuarterlyReportRowForm.jsx";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

import moment from "moment";

//
import useHandleMutationError from "../../hooks/useHandleMutationError.js";

function CreateFormQuarterlyReport({ ...props }) {
    const queryClient = useQueryClient();

    const [creactMutationIsLoading, setCreactMutationIsLoading] = useState(false);
    const creactMutation = useMutation({
        mutationFn: postToCreateFeedbackQuarterlyReport,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["feedback-reports"] });
            // queryClient.invalidateQueries({ queryKey: ["summarised_feedback"] });
            toast.success("Feedback Report Created Successfully");
            props.onClose();
            setCreactMutationIsLoading(false);
            // setTableSelectedRows([]);
            // selectedTableRowsData([])
        },
    });
    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(creactMutation?.error, setCreactMutationIsLoading);

    const handleSubmit = async (data) => {
        console.log("🚀 ~ creating summarised feedback handleSubmit ~ data:", data);

        setCreactMutationIsLoading(true);

        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("description", data?.description);
        formData.append("status", data?.status);
        formData.append("start_date", data?.start_date ? moment(data.start_date).format("YYYY-MM-DD HH:mm:ss") : "");
        formData.append("end_date", data?.end_date ? moment(data.end_date).format("YYYY-MM-DD HH:mm:ss") : "");
        formData.append("type", data?.type);

        switch (data?.type) {
            case "quarterly report":
                formData.append("financial_year_id", data?.financial_year?.id ?? "");
                formData.append("quarter_id", data?.quarter?.id ?? "");
                break;

            case "urgent report":
                // formData.append("country_id", data?.country?.id ?? "");
                break;

            default:
                // Handle unknown location scope if necessary
                console.warn("Unknown location scope, no location data appended.");
                break;
        }

        creactMutation.mutate(formData);
    };

    return (
        <Dialog header="Create Quarterly Feedback Report" visible={props.show} maximizable style={{ minWidth: "50vw" }} onHide={() => props.onHide()}>
            <p>Fill in the form below</p>
            <QuarterlyReportRowForm handleSubmit={handleSubmit} initialData={{ type: "quarterly report" }} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}
            {creactMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
            {/* <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Program Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
        </Modal> */}
        </Dialog>
    );
}

export default CreateFormQuarterlyReport;
