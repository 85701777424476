import axiosAPI from "../axiosApi";

export async function getAllJobs(params = {}) {
    const response = await axiosAPI.get("jobs", { params: params });
    return response;
}

export async function getAllFailedJobs(params = {}) {
    const response = await axiosAPI.get("failed-jobs", { params: params });
    return response;
}

export async function getAllJobStats(params = {}) {
    const response = await axiosAPI.get("job-stats", { params: params });
    return response;
}

export async function postToBulkDestroyFailedJobs(data) {
    const response = await axiosAPI.post(`bulk-destroy-failed-jobs`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function getAuditTypesById(id) {
    const response = await axiosAPI.get(`audit-types/` + id);
    return response;
}

export async function postAuditType(data) {
    const response = await axiosAPI.post(`audit-types`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateAuditType(id, data) {
    const response = await axiosAPI.post(`audit-types/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteAuditTypeById(id) {
    const response = await axiosAPI.delete(`audit-types/${id}`);
    return response;
}
