import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CreateForm from "./CreateForm.jsx";

import EditForm from "./EditForm.jsx";

import moment from "moment";

import { useNavigate } from "react-router-dom";

import { getAllJobs, getAllFailedJobs, getAllJobStats, postToBulkDestroyFailedJobs } from "../../../services/jobs/jobs-service.js";

import { Dropdown } from "primereact/dropdown";
import MuiTable from "../../../components/general_components/MuiTable.jsx";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import { Image } from "primereact/image";

//
import useHandleQueryError from "../../../hooks/useHandleQueryError.js";
//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

import InlineExpandableText from "../../../components/helpers/InlineExpandableText.jsx";

//
import AdvancedFilterForm from "./AdvancedFilterForm";

function FailedJobsListPage({ loggedInUserData, multipleSelect = true, ...props }) {
    const navigate = useNavigate();

    //
    const [selectedFilters, setSelectedFilters] = useState({
        startDate: moment().subtract(30, "days").toDate(),
        endDate: moment().toDate(),
        // selectedTargetAudience: [],
    });

    const queryClient = useQueryClient();
    const { data, isLoading, isError, error, status } = useQuery({
        queryKey: ["failed-jobs", selectedFilters],
        queryFn: () =>
            getAllFailedJobs({
                ...selectedFilters,
                startDate: selectedFilters?.startDate ? moment(selectedFilters?.startDate).format("YYYY-MM-DD HH:mm:ss") : null,
                endDate: selectedFilters?.endDate ? moment(selectedFilters?.endDate).format("YYYY-MM-DD HH:mm:ss") : null,
            }),
    });
    console.log("🚀 ~ Jobs FailedJobsListPage ~ data:", data?.data?.data);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(isError, error);

    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: (variables) => postToBulkDestroyFailedJobs(variables),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["failed-jobs"] });
            toast.success("Deleted Successfully");
            setDeleteMutationIsLoading(false);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(deleteMutation?.error, setDeleteMutationIsLoading);

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, selectedRows) => {
        // let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(selectedRows),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteData) => {
        if (selectedDeleteData !== null) {
            setDeleteMutationIsLoading(true);

            if (multipleSelect) {
                deleteMutation.mutate({ jobs: selectedDeleteData });
            } else {
                deleteMutation.mutate({ jobs: [selectedDeleteData] });
            }
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    // const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;
    const activeUser = loggedInUserData;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const columns = [
        {
            title: "#",
            width: "5%",
            field: "id",
        },
        {
            title: "UUID",
            field: "uuid",
            render: (rowData) => rowData.uuid || "No UUID",
        },
        {
            title: "Connection",
            field: "connection",
            render: (rowData) => rowData.connection || "No connection",
        },
        {
            title: "Queue",
            field: "queue",
            render: (rowData) => rowData.queue || "No queue",
        },
        {
            title: "Payload",
            field: "payload",
            render: (rowData) => {
                const maxLength = 50; // Define max length before truncation
                return <InlineExpandableText text={rowData.payload} maxLength={maxLength} />;
            },
        },
        {
            title: "Exception",
            field: "exception",
            render: (rowData) => {
                const maxLength = 50; // Define max length before truncation
                return <InlineExpandableText text={rowData.exception} maxLength={maxLength} />;
            },
        },
        {
            title: "Failed At",
            field: "failed_at",
            render: (rowData) => moment(rowData.failed_at).format("lll"),
        },
    ];

    //table selection
    const [tableSelectedRows, setTableSelectedRows] = useState([]);
    //============== selected filters ===================

    const handleSubmitAdvancedFilter = (formData) => {
        console.log("Form submitted with:", formData);

        setSelectedFilters(formData);
        // Handle form submission (e.g., API call, state update)
    };

    return (
        <div style={{ width: "100%" }}>
            <div>
                <AdvancedFilterForm initialData={selectedFilters} onSubmit={handleSubmitAdvancedFilter} />
            </div>
            <Panel header="Topics" style={{ marginBottom: "20px" }} toggleable>
                {/* <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {activeUser?.permissions.includes("create topics") && <Button label="Add Topic" className="p-button-primary" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} productCategoryBrandData={productCategoryBrandData} />
                </div> */}

                <MuiTable
                    tableTitle="Jobs"
                    tableData={data?.data?.data ?? []}
                    tableColumns={columns}
                    handleShowEditForm={handleShowEditForm}
                    // showEdit={activeUser?.permissions.includes("update Jobs")}
                    // handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    // showDelete={activeUser?.permissions.includes("delete Jobs")}
                    //--------- bulk delete ------------
                    handleBulkDelete={(e, item) => handleDelete(e, item)}
                    showBulkDelete={activeUser?.permissions.includes("create feedback report")}
                    loading={isLoading || status === "loading" || deleteMutationIsLoading}
                    //
                    // handleViewPage={(rowData) => {
                    //     navigate("directorate", { state: { directorateData: rowData } });
                    // }}
                    // showViewPage={true}
                    // hideRowViewPage={false}
                    //
                    exportButton={true}
                    pdfExportTitle="Jobs"
                    csvExportTitle="Jobs"
                    //multiple select
                    selection={multipleSelect ? true : false}
                    showSelectAllCheckbox={multipleSelect ? true : false}
                    showTextRowsSelected={multipleSelect ? true : false}
                    selectionChange={(selectedRows) => {
                        console.log("selected rows on sselection change : ", selectedRows);
                        setTableSelectedRows(selectedRows);
                    }}
                />

                {selectedItem && <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}
            </Panel>
        </div>
    );
}

export default FailedJobsListPage;
