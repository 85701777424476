import React from "react";

//
import useAuthContext from "../../../context/AuthContext";

import FeedbackBySectorBarChart from "./charts/FeedbackBySectorBarChart"; // Import Bar Chart
import FeedbackByRegionPieChart from "./charts/FeedbackByRegionPieChart"; // Import Pie Chart
import FeedbackByLocationScopeDoughnutChart from "./charts/FeedbackByLocationScopeDoughnutChart";
import FeedbackByRegionTable from "./tables/FeedbackByRegionTable";
import FeedbackBySectorTable from "./tables/FeedbackBySectorTable";
import FeedbackByLocationScopeTable from "./tables/FeedbackByLocationScopeTable";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";

const MuiTablePdfTemplateWithTable = ({ title = "", columns, data, tableData }) => {
    console.log("🚀 ~ MuiTablePdfTemplate ~ data:", data);
    console.log("🚀 ~ MuiTablePdfTemplate ~ columns:", columns);

    //
    const { getUserQuery } = useAuthContext();

    const loggedInUserDetails = getUserQuery?.data?.data;

    const styles = {
        container: {
            padding: "20px",
            fontFamily: "Arial, sans-serif",
            fontSize: "12px",
        },
        header: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
            flexDirection: "column",
            width: "100%",
        },
        logo: {
            height: "50px",
        },
        title: {
            fontSize: "18px",
            fontWeight: "bold",
        },
        // paragraphContainer: {
        //     marginTop: "20px",
        // },
        // paragraph: {
        //     marginBottom: "10px",
        //     lineHeight: "1.6",
        // },
        paragraphContainer: {
            padding: "20px",
            fontFamily: "Arial, sans-serif",
            fontSize: "12px",
            color: "#333",
        },
        sectorGroup: {
            marginBottom: "20px",
            borderBottom: "1px solid #ddd",
            paddingBottom: "10px",
        },
        sectorTitle: {
            fontSize: "14px",
            fontWeight: "bold",
            color: "#007BFF",
            marginBottom: "10px",
        },
        paragraph: {
            margin: "5px 0",
            lineHeight: "1.5",
            color: "#555",
        },
        noDataMessage: {
            fontSize: "14px",
            color: "#888",
            textAlign: "center",
            marginTop: "20px",
        },
        footer: {
            marginTop: "20px",
            textAlign: "center",
            fontSize: "10px",
            color: "#666",
        },
        tablesContainer: {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginTop: "20px",
            gap: "20px",
        },
        table: {
            width: "100%", // Adjust width for responsive design
        },

        chartsContainer: {
            display: "flex",
            // flexDirection: "column",
            marginTop: "20px",
            gap: "20px",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
        },
        statsContainer: {
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            fontSize: "14px",
            fontWeight: "bold",
            borderBottom: "0.2px solid #ddd",
            padding: "10px",
            borderRadius: "5px",
        },
        chart: {
            display: "flex",
            // flexDirection: "column",
            gap: "1rem",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            width: "500px", // Adjust width for responsive design
        },
    };

    // Helper function to dynamically access nested properties
    function getFlatPathValue(obj, path) {
        return obj[path];
    }

    // Helper function to format date values
    function formatValue(value) {
        if (!value) return "";
        // Check if the value is a valid date
        if (moment(value, moment.ISO_8601, true).isValid()) {
            return moment(value).format("MMMM Do YYYY, h:mm a"); // Customize the format as needed
        }
        return value;
    }

    // Calculate statistics
    const totalFeedback = data.length;
    // Use a Set to find unique region names, accessing the "region.name" key
    const uniqueRegions = new Set(
        data.map((row) => row["region.name"]).filter((name) => name) // Filter out undefined or null
    ).size;

    // Use a Set to find unique sector names, accessing the "sector.name" key
    const uniqueSectors = new Set(
        data.map((row) => row["sector.name"]).filter((name) => name) // Filter out undefined or null
    ).size;

    return (
        <table id="print-content" style={styles.container}>
            <thead>
                <tr>
                    <td>
                        <div className="header-space">&nbsp;</div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div className="content">
                            {/* Statistics Section */}
                            <div style={styles.statsContainer}>
                                <div>Total Feedback: {totalFeedback}</div>
                                <div>Number of Regions: {uniqueRegions}</div>
                                <div>Number of Sectors: {uniqueSectors}</div>
                            </div>
                            {/* Tables Section */}
                            <div style={styles.tablesContainer}>
                                <div style={styles.table}>
                                    <FeedbackByLocationScopeTable data={data} />
                                </div>
                                <div style={styles.table}>
                                    <FeedbackByRegionTable data={data} />
                                </div>
                                <div style={styles.table}>
                                    <FeedbackBySectorTable data={data} />
                                </div>
                            </div>
                            <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                                <DataTable
                                    value={data}
                                    responsiveLayout="scroll"
                                    showGridlines
                                    stripedRows
                                    rowGroupMode="subheader"
                                    groupRowsBy="sector.name"
                                    sortField="sector.name"
                                    sortOrder={1}
                                    rowGroupHeaderTemplate={(group) => <span style={{ fontWeight: "bold", fontSize: "1.1rem" }}>Sector: {group["sector.name"] || "N/A"}</span>}
                                >
                                    {/* Group Header */}
                                    <Column
                                        field="sector.name"
                                        header="Sector"
                                        style={{ display: "none" }} // Hide group column from individual rows
                                        headerStyle={{ display: "none" }}
                                    />

                                    {/* Always visible columns */}
                                    <Column field="feedback_no" header="No." />
                                    <Column field="created_at" header="Date Submitted" body={(rowData) => moment(rowData?.created_at).format("MMMM Do YYYY, h:mm:ss a")} />
                                    <Column field="message" header="Issue as Recorded in CFP" />
                                    <Column field="sector.name" header="Sector" />
                                    <Column field="location_scope" header="Scope" />
                                    <Column field="district.name" header="District" />
                                    <Column field="has_attachments" header="Attachment Y/N" body={(rowData) => (rowData?.has_attachments ? "Yes" : "No")} />
                                    {/* <Column
                                        field="audit_areas"
                                        header="Identified Audit Areas"
                                        body={(rowData) => {
                                            const auditAreas = Array.isArray(rowData?.audit_areas) ? rowData.audit_areas : [];
                                            return auditAreas.length > 0 ? auditAreas.map((area, index) => <div key={index}>{area?.name}.</div>) : "N/A";
                                        }}
                                    />
                                    <Column field="agreed_actions" header="Agreed Actions" body={(rowData) => rowData?.agreed_actions || "N/A"} />
                                    <Column field="responsible_directorate.name" header="Agreed Responsible Directorate" body={(rowData) => rowData?.responsible_directorate?.name || "N/A"} /> */}
                                </DataTable>
                            </div>
                            {/* Charts Section */}
                            <div style={styles.chartsContainer}>
                                <div style={styles.chart}>
                                    <FeedbackByLocationScopeDoughnutChart data={data} />
                                </div>
                                <div style={styles.chart}>
                                    <FeedbackByRegionPieChart data={data} />
                                </div>
                                <div style={styles.chart}>
                                    <FeedbackBySectorBarChart data={data} />
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <div className="footer-space">&nbsp;</div>
                    </td>
                </tr>
            </tfoot>
            <div className="header" style={styles.header}>
                <img src="assets/oag_photos/uganda_oag.png" alt="Logo" style={{ height: "50px" }} />
                <span style={styles.title}>{title}</span>
            </div>
            <div className="footer" style={styles.footer}>
                <div>
                    Generated by {loggedInUserDetails?.name} on {moment().format("dddd, MMMM Do YYYY, h:mm:ss A")}
                </div>
                <div>© {new Date().getFullYear()} OAG Citizen Feedback Platform</div>
            </div>
        </table>
    );
};

export default MuiTablePdfTemplateWithTable;
