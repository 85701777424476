import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";
import { Accordion, AccordionTab } from "primereact/accordion";

import moment from "moment";

//
import { AutoComplete } from "primereact/autocomplete";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from "primereact/dropdown";

//
import { getAllFinancialYears } from "../../../services/financial-years/financial-years-service";
import { getAllQuarters } from "../../../services/financial-years/quarters";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
//
import useHandleQueryError from "../../../hooks/useHandleQueryError";

const AdvancedFilterForm = ({ onSubmit, initialData, spatieCurrentStatuses }) => {
    const statuses = [
        { id: 1, name: "Submitted", code: "submitted" },
        { id: 2, name: "Tagged", code: "tagged" },
        { id: 3, name: "Not Started", code: "Not Started" },
        { id: 4, name: "In Progress", code: "In Progress" },
        { id: 5, name: "Completed", code: "Completed" },
        { id: 6, name: "Not Applicable", code: "Not Applicable" },
    ];

    const [type, setType] = useState(initialData?.type);
    // Financial Year and Quarter States
    const [dropdownFinancialYears, setDropdownFinancialYears] = useState([]);
    const [selectedFinancialYear, setSelectedFinancialYear] = useState(initialData?.financial_year);

    const [dropdownQuarters, setDropdownQuarters] = useState([]);
    const [selectedQuarter, setSelectedQuarter] = useState(initialData?.quarter);

    // Fetch Financial Years
    const financialYearsQuery = useQuery({
        queryKey: ["financialYears"],
        queryFn: getAllFinancialYears, // Replace with your actual API service
    });

    useHandleQueryError(financialYearsQuery?.isError, financialYearsQuery?.error);

    // Fetch Quarters (Dependent on Selected Financial Year)
    const quartersQuery = useQuery({
        queryKey: ["quarters"],
        queryFn: () => getAllQuarters(), // Replace with your actual API service
        enabled: selectedFinancialYear ? true : false, // Fetch only when a financial year is selected
    });

    useHandleQueryError(quartersQuery?.isError, quartersQuery?.error);

    const [accordionActiveIndex, setAccordionActiveIndex] = useState(null);

    const validate = (values) => {
        const errors = {};
        if (values.startDate && values.endDate && moment(values.startDate).isAfter(values.endDate)) {
            errors.startDate = "Start date cannot be after end date";
        }
        return errors;
    };

    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            onSubmit(data);
        } else {
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please correct the errors before submitting");
        }
    };

    // const initialValues = {
    //     startDate: initialData?.startDate ?? moment().subtract(30, "days").toDate(),
    //     endDate: initialData?.endDate ?? moment().toDate(),
    //     selectedTargetAudience: initialData?.selectedTargetAudience ?? selectedTargetAudience,
    // };

    const handleClearFilters = (form) => {
        form.restart({}); // Resets all fields to initialValues
        setSelectedFinancialYear(null);
        setSelectedQuarter(null);
        onSubmit({});
    };

    return (
        <Accordion activeIndex={accordionActiveIndex} onTabChange={(e) => setAccordionActiveIndex(e.index)}>
            <AccordionTab header="Filters">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    initialValuesEqual={() => true}
                    validate={validate}
                    render={({ handleSubmit, form, values }) => (
                        <form onSubmit={handleSubmit} className="p-grid p-fluid">
                            <div className="grid">
                                {/* Financial Year Field */}
                                <div className="col-12 md:col-6 lg:col-3">
                                    <Field name="financial_year">
                                        {({ input, meta }) => {
                                            const financialYearsData = financialYearsQuery?.data?.data?.data || [];

                                            const fetchFinancialYearSuggestions = (event) => {
                                                let query = event.query.toLowerCase();
                                                let filtered = financialYearsData.filter((year) => year?.name?.toLowerCase().includes(query));
                                                setDropdownFinancialYears(filtered);
                                            };

                                            return (
                                                <div className="p-field m-4">
                                                    <label htmlFor="financial_year">Financial Year</label>
                                                    <AutoComplete
                                                        {...input}
                                                        multiple={false}
                                                        suggestions={dropdownFinancialYears}
                                                        completeMethod={fetchFinancialYearSuggestions}
                                                        field="name"
                                                        value={selectedFinancialYear}
                                                        onChange={(e) => {
                                                            setSelectedFinancialYear(e.value);
                                                            input.onChange(e.value);

                                                            // Reset quarter when financial year changes
                                                            setSelectedQuarter(null);
                                                            setDropdownQuarters([]);

                                                            // // Update the form with calculated dates
                                                            // form.change("startDate", null);
                                                            // form.change("endDate", null);

                                                            // Prefill startDate and endDate fields dynamically
                                                            if (e.value) {
                                                                const { start_date, end_date } = e.value;

                                                                // Ensure start_date and end_date are valid
                                                                if (moment(start_date, "YYYY-MM-DD", true).isValid() && moment(end_date, "YYYY-MM-DD", true).isValid()) {
                                                                    const startDate = moment(start_date, "YYYY-MM-DD").toDate();
                                                                    const endDate = moment(end_date, "YYYY-MM-DD").toDate();

                                                                    // Update the form with the start and end dates
                                                                    form.change("startDate", startDate);
                                                                    form.change("endDate", endDate);
                                                                }
                                                            }
                                                        }}
                                                        dropdown={true}
                                                        disabled={financialYearsQuery?.isLoading}
                                                        placeholder="Select Financial Year"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {/* Show loading spinner if financialYearsQuery is loading */}
                                                    {financialYearsQuery?.isLoading && financialYearsQuery?.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            );
                                        }}
                                    </Field>
                                </div>

                                {/* Quarter Field */}
                                <div className="col-12 md:col-6 lg:col-3">
                                    <Field name="quarter">
                                        {({ input, meta }) => {
                                            const quartersData = quartersQuery?.data?.data?.data || [];

                                            const fetchQuarterSuggestions = (event) => {
                                                let query = event.query.toLowerCase();
                                                let filtered = quartersData.filter((quarter) => quarter?.name?.toLowerCase().includes(query));
                                                setDropdownQuarters(filtered);
                                            };

                                            return (
                                                <div className="p-field m-4">
                                                    <label htmlFor="quarter">Quarter</label>
                                                    <AutoComplete
                                                        {...input}
                                                        multiple={false}
                                                        suggestions={dropdownQuarters}
                                                        completeMethod={fetchQuarterSuggestions}
                                                        field="name"
                                                        value={selectedQuarter}
                                                        onChange={(e) => {
                                                            setSelectedQuarter(e.value);
                                                            input.onChange(e.value);

                                                            // Prefill logic
                                                            if (selectedFinancialYear && e.value) {
                                                                const { start_month, end_month } = e.value; // Use end_month instead of number_of_months
                                                                const { start_date, end_date } = selectedFinancialYear;

                                                                // Ensure start_date and end_date are valid
                                                                if (moment(start_date, "YYYY-MM-DD", true).isValid() && moment(end_date, "YYYY-MM-DD", true).isValid()) {
                                                                    const financialYearStart = moment(start_date, "YYYY-MM-DD");
                                                                    const financialYearEnd = moment(end_date, "YYYY-MM-DD");

                                                                    // Ensure start_month and end_month are valid
                                                                    if (start_month > 0 && start_month <= 12 && end_month > 0 && end_month <= 12) {
                                                                        // Calculate the number of months
                                                                        let number_of_months;
                                                                        if (end_month >= start_month) {
                                                                            number_of_months = end_month - start_month + 1;
                                                                        } else {
                                                                            // Handles cases where end_month is in the next calendar year
                                                                            number_of_months = 12 - start_month + 1 + end_month;
                                                                        }

                                                                        // Calculate the start date within the financial year
                                                                        let startDate = financialYearStart.clone().month(start_month - 1);

                                                                        // If the start month exceeds the calendar year, adjust the year
                                                                        if (startDate.isBefore(financialYearStart)) {
                                                                            startDate.add(1, "year");
                                                                        }

                                                                        // Calculate the end date
                                                                        let endDate = startDate.clone().add(number_of_months, "months").subtract(1, "day");

                                                                        // Ensure the calculated dates are within the financial year range
                                                                        if (startDate.isBefore(financialYearStart)) {
                                                                            startDate = financialYearStart.clone();
                                                                        }
                                                                        if (endDate.isAfter(financialYearEnd)) {
                                                                            endDate = financialYearEnd.clone();
                                                                        }

                                                                        // Update the form with calculated dates
                                                                        form.change("startDate", startDate.toDate());
                                                                        form.change("endDate", endDate.toDate());
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        dropdown={true}
                                                        disabled={quartersQuery?.isLoading || !selectedFinancialYear}
                                                        placeholder="Select Quarter"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {quartersQuery?.isLoading && quartersQuery?.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            );
                                        }}
                                    </Field>
                                </div>

                                <div className="col-12 md:col-6 lg:col-3">
                                    <Field name="startDate">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label htmlFor="startDate">Start Date</label>
                                                <Calendar
                                                    id="startDate"
                                                    {...input}
                                                    value={input.value ? moment.utc(input.value).toDate() : null}
                                                    dateFormat="dd/mm/yy"
                                                    showButtonBar
                                                    showIcon
                                                    // showTime
                                                    // hourFormat="12"
                                                    className={classNames({ "p-invalid": meta.error && meta.touched })}
                                                />
                                                {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                <div className="col-12 md:col-6 lg:col-3">
                                    <Field name="endDate">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label htmlFor="endDate">End Date</label>
                                                <Calendar
                                                    id="endDate"
                                                    {...input}
                                                    value={input.value ? moment.utc(input.value).toDate() : null}
                                                    dateFormat="dd/mm/yy"
                                                    showButtonBar
                                                    showIcon
                                                    // showTime
                                                    // hourFormat="12"
                                                    className={classNames({ "p-invalid": meta.error && meta.touched })}
                                                />
                                                {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                {spatieCurrentStatuses.includes("submitted") ? (
                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Field name="statuses">
                                            {({ input, meta }) => (
                                                <div className="p-field m-4">
                                                    <label htmlFor="status">Filter by Status</label>
                                                    <MultiSelect id="status" {...input} options={statuses} optionLabel="name" placeholder="Select Statuses" className={classNames("w-full", { "p-invalid": meta.error && meta.touched })} display="chip" />
                                                    {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                ) : (
                                    <></>
                                )}

                                <div className="p-col-12 m-4">
                                    <div className="p-field m-4">
                                        <Button type="submit" onClick={() => setAccordionActiveIndex(null)} label="Apply Filters" className="p-button-primary" />
                                    </div>
                                </div>

                                <div className="p-col-12 m-4">
                                    <div className="p-field m-4">
                                        <Button type="button" label="Clear Filters" className="p-button-secondary ml-2" onClick={() => handleClearFilters(form)} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                />
            </AccordionTab>
        </Accordion>
    );
};

export default AdvancedFilterForm;
