import React, { useState, useEffect } from "react";

import { getAllFeedbackReports, getFeedbackReportById, postFeedbackReport, updateFeedbackReport, deleteFeedbackReportById } from "../../services/feedback-reports/feedback-reports-service.js";

import RowFormOnEdit from "./widgets/RowFormOnEdit";
import { useQueryClient, useMutation } from "@tanstack/react-query";

import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";
import moment from "moment";
//
import useHandleMutationError from "../../hooks/useHandleMutationError.js";

function EditForm(props) {
    const queryClient = useQueryClient();

    const [editMutationIsLoading, setEditMutationIsLoading] = useState(false);
    const editMutation = useMutation({
        mutationFn: (variables) => updateFeedbackReport(props?.rowData?.id, variables),
        onSuccess: () => {
            setEditMutationIsLoading(false);
            props.onClose();
            toast.success("Edited Successfully");
            queryClient.invalidateQueries({ queryKey: ["feedback-reports"] });
            queryClient.invalidateQueries({ queryKey: ["summarised_feedback"] });
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(editMutation?.error, setEditMutationIsLoading);

    //     editMutation.mutate(data);
    // };

    const handleSubmit = async (data) => {
        console.log("🚀 ~ creating summarised feedback report handleSubmit ~ data:", data);

        setEditMutationIsLoading(true);

        const formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("name", data.name);
        formData.append("description", data?.description);
        formData.append("status", data?.status);
        formData.append("start_date", data?.start_date ? moment(data.start_date).format("YYYY-MM-DD HH:mm:ss") : "");
        formData.append("end_date", data?.end_date ? moment(data.end_date).format("YYYY-MM-DD HH:mm:ss") : "");
        formData.append("type", data?.type);

        switch (data?.type) {
            case "quarterly report":
                formData.append("financial_year_id", data?.financial_year?.id ?? "");
                formData.append("quarter_id", data?.quarter?.id ?? "");
                break;

            case "urgent report":
                // formData.append("country_id", data?.country?.id ?? "");
                break;

            default:
                // Handle unknown location scope if necessary
                console.warn("Unknown location scope, no location data appended.");
                break;
        }

        // Push the whole selectedTableRowsData array without modification
        formData.append("feedback_report_summarised_feedbacks", JSON.stringify(data?.tagged_feedback));

        editMutation.mutate(formData);
    };

    return (
        <Dialog header="Edit Feedback Report" visible={props.show} maximizable style={{ minWidth: "50vw" }} onHide={() => props.onHide()}>
            {/* <h3>Programs Edit Form</h3> */}
            <p>Edit Data Below</p>
            <RowFormOnEdit initialData={props.rowData} handleSubmit={handleSubmit} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}

            {editMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
        </Dialog>
    );
}

export default EditForm;
