import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { getAllRegions, getRegionsById, postRegions, updateRegions, deleteRegionById } from "../../../services/locations/regions-service";
import { ProgressBar } from "primereact/progressbar";
import { toast } from "react-toastify";

import { getAllCountrys } from "../../../services/locations/countries-service";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { Dropdown } from "primereact/dropdown";

import useHandleQueryError from "../../../hooks/useHandleQueryError";
//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

function EditForm({ initialData, ...props }) {
    const queryClient = useQueryClient();

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    // Fetch the list of countries
    const getListOfCountries = useQuery({ queryKey: ["countries"], queryFn: getAllCountrys });
    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(getListOfCountries?.isError, getListOfCountries?.error);

    const validate = (values) => {
        const errors = {};
        if (!values.name) errors.name = "Name is required";
        if (!values.countryId) errors.countryId = "Country is required";
        return errors;
    };

    const [editMutationIsLoading, setEditMutationIsLoading] = useState(false);
    const editMutation = useMutation({
        mutationFn: (data) => updateRegions(initialData?.id, data),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["regions"] });
            toast.success("Region updated successfully");
            console.log("success updating regions is : ", data);
            setEditMutationIsLoading(false);
            props.onClose();
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(editMutation?.error, setEditMutationIsLoading);

    const onSubmitForm = (values) => {
        const payload = {
            ...values,
            country_id: values?.countryId, // Extract only the id from the districtId field
        };

        setPendingData(payload);
        setShowConfirmDialog(true);
    };

    const onConfirm = () => {
        if (pendingData) {
            setEditMutationIsLoading(true);
            editMutation.mutate(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <>
            <Dialog header="Regions Edit Form" visible={props.show} maximizable onHide={() => props.onHide()}>
                <div className="col-12 md:col-12">
                    <div className="card p-fluid">
                        <Form
                            initialValues={{
                                name: initialData?.name,
                                countryId: initialData?.country_id,
                            }}
                            validate={validate}
                            onSubmit={onSubmitForm}
                            render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <Field name="name">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label htmlFor="name">Name</label>
                                                <InputText {...input} id="name" />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>

                                    <Field name="countryId">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label htmlFor="countryId">Country</label>
                                                <Dropdown {...input} options={getListOfCountries?.data?.data?.data} optionLabel="name" optionValue="id" placeholder="Select a Country" />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                    <div className="d-grid gap-2">
                                        <Button type="submit" label="Save" className={editMutationIsLoading ? "p-button-loading" : ""} />
                                    </div>
                                </form>
                            )}
                        />

                        <Dialog
                            header="Confirmation"
                            visible={showConfirmDialog}
                            onHide={onCancel}
                            style={{ minWidth: "30vw" }}
                            maximizable
                            footer={
                                <div>
                                    <Button label="Yes" onClick={onConfirm} />
                                    <Button label="No" onClick={onCancel} className="p-button-secondary" />
                                </div>
                            }
                        >
                            Are you sure you want to save the changes?
                        </Dialog>
                        {editMutationIsLoading && (
                            <div className="m-2">
                                <ProgressBar mode="indeterminate" />
                            </div>
                        )}
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default EditForm;
