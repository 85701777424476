import React, { useState, useContext, useMemo, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";
import drilldown from "highcharts/modules/drilldown";
import HC_accessibility from "highcharts/modules/accessibility";

//
import { getSummarisedFeedbackBarChartStatistics } from "../../../services/dashboard-statistics/bar-charts-service";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";

import { Accordion, AccordionTab } from "primereact/accordion";

//lotties
import MaterialUiLoaderLottie from "../../../assets/lotties/oag-lotties/material-ui-loading-lottie.json";
// import RobotProcessorLottie from "../../../assets/lotties/oag-lotties/robot-processor-lottie.json";
import BrownFilesProcessingLottie from "../../../assets/lotties/oag-lotties/brown-files-processing-lottie.json";
// import ComputerInFourCirclesLottie from "../../../assets/lotties/oag-lotties/computer-in-four-circles-lottie.json";
// import ServerProcessingLottie from "../../../assets/lotties/oag-lotties/server-processing-lottie.json";
// import DarkBluePeopleOnServerLottie from "../../../assets/lotties/oag-lotties/dark-blue-people-on-server-lottie.json";
// import BoyGirlPlottingGraphLottie from "../../../assets/lotties/oag-lotties/boy-girl-plotting-graph-lottie.json";
// import BoyBoyScanningFilesLottie from "../../../assets/lotties/oag-lotties/boy-boy-scanning-files-lottie.json";
// import GirlGirlPlottingGraphsLottie from "../../../assets/lotties/oag-lotties/girl-girl-plotting-graphs-lottie.json";
import SnailErrorLottie from "../../../assets/lotties/oag-lotties/snail-error-lottie.json";
import Lottie from "lottie-react";

import { Button } from "primereact/button";
import moment from "moment";

import { Tooltip } from "@mui/material";

//
import useHandleQueryError from "../../../hooks/useHandleQueryError";

//
import SummarisedFiltersForm from "./SummarisedFiltersForm";

// Initialize the required Highcharts modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);
HC_accessibility(Highcharts);

// const getChartOptions = (data, dataFilters) => {
//     // Check if data is valid
//     if (!data || !Array.isArray(data)) {
//         return null;
//     }

//     // Helper function to format arrays into a string of names or labels
//     function formatArray(arr, key) {
//         if (!Array.isArray(arr) || arr.length === 0) return "";
//         return arr.map((item) => item[key]).join(", ");
//     }

//     // Helper function to format data filters for chart title
//     // Helper function to format data filters for chart title
//     function formatFilters(dataFilters) {
//         // Format the dates
//         const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : null;
//         const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : null;

//         // Extract and format the filters
//         const statuses = formatArray(dataFilters?.statuses, "label");
//         const orderBy = dataFilters?.orderBy?.label || null;
//         const dataLimit = dataFilters?.dataLimit?.label || null;
//         const sector = dataFilters?.sector?.name || null;
//         const regions = formatArray(dataFilters?.regions, "name");
//         const districts = formatArray(dataFilters?.districts, "name");
//         const counties = formatArray(dataFilters?.counties, "name");
//         const subcounties = formatArray(dataFilters?.subcounties, "name");
//         const parishes = formatArray(dataFilters?.parishes, "name");
//         const villages = formatArray(dataFilters?.villages, "name");

//         // Constructing the sentence for the chart title
//         let sentence = "Filters: ";
//         if (statuses) sentence += `Statuses: ${statuses}. `;
//         if (sector) sentence += `Sector: ${sector}. `;
//         if (regions) sentence += `Regions: ${regions}. `;
//         if (districts) sentence += `Districts: ${districts}. `;
//         if (counties) sentence += `Counties: ${counties}. `;
//         if (subcounties) sentence += `Subcounties: ${subcounties}. `;
//         if (parishes) sentence += `Parishes: ${parishes}. `;
//         if (villages) sentence += `Villages: ${villages}. `;
//         if (orderBy) sentence += `Order By: ${orderBy}. `;
//         if (dataLimit) sentence += `Data Limit: ${dataLimit}. `;
//         if (startDate) sentence += `Start Date: ${startDate}. `;
//         if (endDate) sentence += `End Date: ${endDate}.`;

//         return sentence.trim();
//     }

//     // Format the chart title based on the applied filters
//     let chartTitle = formatFilters(dataFilters);

//     // Define the Highcharts options for a bar chart
//     const options = {
//         chart: {
//             type: "bar", // Horizontal bar chart
//         },
//         title: {
//             text: `Feedback By Sector <br/> ${chartTitle}`,
//         },
//         xAxis: {
//             categories: data.map((item) => item.sector_name), // Use sector_name for categories
//             title: {
//                 text: "Sectors",
//             },
//         },
//         yAxis: {
//             min: 0,
//             title: {
//                 text: "Feedback Count",
//                 align: "high",
//             },
//             labels: {
//                 overflow: "justify",
//             },
//         },
//         plotOptions: {
//             bar: {
//                 dataLabels: {
//                     enabled: true, // Enable data labels on the bars
//                 },
//             },
//         },
//         legend: {
//             reversed: true,
//         },
//         credits: {
//             enabled: false,
//         },
//         noData: {
//             style: {
//                 fontWeight: "bold",
//                 fontSize: "16px",
//                 color: "#303030",
//             },
//             position: {
//                 align: "center",
//                 verticalAlign: "middle",
//             },
//             text: "No data to display",
//         },
//         series: [
//             {
//                 name: "Feedback Count",
//                 data: data.map((item) => item.feedback_count), // Use feedback_count for the series data
//             },
//         ],
//     };

//     return options;
// };

// ================= vertical chart ================
const getChartOptions = (data, dataFilters) => {
    // Check if data is valid
    if (!data || !Array.isArray(data)) {
        return null;
    }

    // Helper function to format arrays into a string of names or labels
    function formatArray(arr, key) {
        if (!Array.isArray(arr) || arr.length === 0) return "";
        return arr.map((item) => item[key]).join(", ");
    }

    // Helper function to format data filters for chart title
    function formatFilters(dataFilters) {
        // Format the dates
        const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : null;
        const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : null;

        const finacialYear = dataFilters?.financial_year?.name || null;
        const quarter = dataFilters?.quarter?.name || null;

        // Extract and format the filters
        const statuses = formatArray(dataFilters?.statuses, "label");
        const orderBy = dataFilters?.orderBy?.name || null;
        const dataLimit = dataFilters?.dataLimit?.name || null;
        const sectors = formatArray(dataFilters?.sectors, "name");
        const location_scope = dataFilters?.location_scope;
        const countries = formatArray(dataFilters?.countries, "name");
        const regions = formatArray(dataFilters?.regions, "name");
        const districts = formatArray(dataFilters?.districts, "name");
        const counties = formatArray(dataFilters?.counties, "name");
        const subcounties = formatArray(dataFilters?.subcounties, "name");
        const parishes = formatArray(dataFilters?.parishes, "name");
        const villages = formatArray(dataFilters?.villages, "name");
        const auditAreas = formatArray(dataFilters?.auditAreas, "name");
        const auditTypes = formatArray(dataFilters?.auditTypes, "name");
        const directorates = formatArray(dataFilters?.directorates, "name");

        // Constructing the sentence for the chart title
        let sentence = "Filters: ";
        if (finacialYear) sentence += `Finacial Year: ${finacialYear}. `;
        if (quarter) sentence += `Quarter: ${quarter}. `;
        if (startDate) sentence += `Start Date: ${startDate}. `;
        if (endDate) sentence += `End Date: ${endDate}. `;
        if (statuses) sentence += `Statuses: ${statuses}. `;
        if (sectors) sentence += `Sectors: ${sectors}. `;
        if (location_scope) sentence += `Location Scope: ${location_scope}. `;
        if (countries) sentence += `Countries: ${countries}. `;
        if (regions) sentence += `Regions: ${regions}. `;
        if (districts) sentence += `Districts: ${districts}. `;
        if (counties) sentence += `Counties: ${counties}. `;
        if (subcounties) sentence += `Subcounties: ${subcounties}. `;
        if (parishes) sentence += `Parishes: ${parishes}. `;
        if (villages) sentence += `Villages: ${villages}. `;
        if (auditAreas) sentence += `Audit Areas: ${auditAreas}. `;
        if (auditTypes) sentence += `Audit Types: ${auditTypes}. `;
        if (directorates) sentence += `Directorates: ${directorates}. `;
        if (orderBy) sentence += `Order By: ${orderBy}. `;
        if (dataLimit) sentence += `Data Limit: ${dataLimit}. `;

        return sentence.trim();
    }

    // Format the chart title based on the applied filters
    let chartTitle = formatFilters(dataFilters);

    // Define an array of colors to use for each bar
    const colors = ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"];

    // Define the Highcharts options for a vertical column chart
    const options = {
        chart: {
            type: "column", // Vertical column chart (bars standing up)
        },
        title: {
            text: `Summarised Feedback By Sector <br/> ${chartTitle}`,
        },
        xAxis: {
            categories: data.map((item) => item.sector_name), // Use sector_name for categories
            title: {
                text: "Sectors",
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: "Feedback Count",
                align: "high",
            },
            labels: {
                overflow: "justify",
            },
        },
        tooltip: {
            pointFormat: "<b>Feedback Count: {point.y}</b>", // Custom tooltip for feedback count
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true, // Enable data labels on the bars
                },
                colorByPoint: true,
            },
        },
        legend: {
            reversed: true,
        },
        credits: {
            enabled: false,
        },
        noData: {
            style: {
                fontWeight: "bold",
                fontSize: "16px",
                color: "#303030",
            },
            position: {
                align: "center",
                verticalAlign: "middle",
            },
            text: "No data to display",
        },
        series: [
            {
                name: "Sectors",
                data: data.map((item, index) => ({
                    y: item.feedback_count, // Feedback count for each sector
                    // color: colors[index % colors.length], // Cycle through the colors array
                    // colorByPoint: true,
                })),
            },
        ],
    };

    return options;
};

const SummarisedFeedbackBySectorBarChart = () => {
    // filters
    const [showFilters, setShowFilters] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({
        startDate: moment().subtract(30, "days").toDate(),
        endDate: moment().toDate(),
    });

    console.log("🚀 ~ SummarisedFeedbackBySectorBarChart ~ selectedFilters:", selectedFilters);

    // Function to toggle the dialog visibility
    const toggleFilterDialog = () => {
        setShowFilters((prevShow) => !prevShow);
    };

    const handleSubmitFilters = (data) => {
        setSelectedFilters(data);
    };

    //==================== end chart filters ===================

    // Products
    const SummarisedFeedbackBySectorChartQuery = useQuery({
        disable: false,
        // queryKey: ["ProductBarChartStatistics", ...Object.values(selectedFilters)],
        queryKey: ["SummarisedFeedbackBySectorBarChart", selectedFilters],
        queryFn: () =>
            getSummarisedFeedbackBarChartStatistics({
                ...selectedFilters,
                startDate: selectedFilters?.startDate ? moment(selectedFilters?.startDate).format("YYYY-MM-DD HH:mm:ss") : null,
                endDate: selectedFilters?.endDate ? moment(selectedFilters?.endDate).format("YYYY-MM-DD HH:mm:ss") : null,
                created_by_group_types: selectedFilters?.groupTypes,
                created_by_groups: selectedFilters?.groups,
            }),
    });

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(SummarisedFeedbackBySectorChartQuery?.isError, SummarisedFeedbackBySectorChartQuery?.error);

    console.log("data for SummarisedFeedbackBySectorChartQuery ?.data?.data is:", SummarisedFeedbackBySectorChartQuery?.data?.data);

    const seriesData = SummarisedFeedbackBySectorChartQuery?.data?.data?.data;

    //=============== handle displaying filters on Chart end ======================

    return (
        <>
            {SummarisedFeedbackBySectorChartQuery?.isLoading ? (
                <>
                    <div className="col-12">
                        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ maxWidth: "400px" }}>
                                <Lottie animationData={BrownFilesProcessingLottie} style={{ height: "300px" }} loop={true} autoplay={true} />
                                <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                            </div>
                        </div>
                        {/* <ProgressBar mode="indeterminate" style={{ height: "6px" }} /> */}
                    </div>
                </>
            ) : SummarisedFeedbackBySectorChartQuery?.isError ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ maxWidth: "400px" }}>
                        <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                    </div>
                </div>
            ) : (
                <>
                    <div className="col-12 text-right">
                        <Tooltip title="Filter">
                            <Button icon="pi pi-filter" onClick={toggleFilterDialog} />
                        </Tooltip>

                        {/* Filters dialog */}
                        <SummarisedFiltersForm selectedFilters={selectedFilters} show={showFilters} onHide={toggleFilterDialog} handleSubmit={handleSubmitFilters} />
                    </div>
                    <div style={{ height: "400px" }}>
                        <HighchartsReact highcharts={Highcharts} options={getChartOptions(seriesData, SummarisedFeedbackBySectorChartQuery?.data?.data?.requestParams)} immutable={true} />
                    </div>
                </>
            )}
        </>
    );
};

export default SummarisedFeedbackBySectorBarChart;
