import moment from "moment";
import React, { useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { deleteCountyById, getAllCounty } from "../../../services/locations/county-service";
import CountyForm from "./CountyForm";
import EditForm from "./EditForm";
import MuiTable from "../../../components/general_components/MuiTable";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { Button } from "primereact/button";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import { Panel } from "primereact/panel";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import useHandleQueryError from "../../../hooks/useHandleQueryError";
//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

function CountyList({ selectedDistrict }) {
    const queryClient = useQueryClient();
    const [county, setCounty] = useState([]);
    const [selectedItem, setSelectedItem] = useState({ id: null });
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const getListOfCounties = useQuery({
        queryKey: ["counties", selectedDistrict?.id],
        queryFn: () => (!selectedDistrict?.id ? getAllCounty() : getAllCounty({ district_id: selectedDistrict?.id })),
    });

    console.log("getListOfCounties : ", getListOfCounties);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(getListOfCounties?.isError, getListOfCounties?.error);

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteCountiesMutation = useMutation({
        mutationFn: deleteCountyById,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["counties"] });
            setDeleteMutationIsLoading(false);
            toast.success("Record Deleted Successfully");
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(deleteCountiesMutation?.error, setDeleteMutationIsLoading);

    const handleDelete = (event, id) => {
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                setDeleteMutationIsLoading(true);
                deleteCountiesMutation.mutate(id);
            },
        });
    };

    const columns = [
        {
            title: "#",
            field: "id",
            width: "5%",
        },
        ...(!selectedDistrict
            ? [
                  {
                      title: "Name",
                      field: "name",
                      cellStyle: {
                          whiteSpace: "nowrap",
                          padding: "8px",
                      },
                  },
              ]
            : [
                  {
                      title: "Name",
                      cellStyle: {
                          whiteSpace: "nowrap",
                          padding: "8px",
                      },
                      field: "name",
                      render: (rowData) => {
                          return (
                              <Link to="subcounties" state={{ selectedCounty: rowData }} className="text-decoration-none">
                                  {rowData.name}
                              </Link>
                          );
                      },
                  },
              ]),

        {
            title: "District Name",
            field: "district.name",
        },
        {
            title: "Date",
            field: "created_at",
            render: (rowData) => moment(rowData.created_at).format("lll"),
        },
    ];

    return (
        <div className="p-grid">
            <Panel header="Counties" style={{ marginBottom: "20px" }} toggleable>
                {selectedDistrict && (
                    <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end" }}>
                        <Button label="Add County" onClick={() => setShowAddForm(true)} />
                        <CountyForm selectedDistrict={selectedDistrict} show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                    </div>
                )}

                <div className="p-col-12">
                    <MuiTable
                        tableTitle="Counties"
                        tableData={getListOfCounties?.data?.data?.data ?? []}
                        tableColumns={columns}
                        handleShowEditForm={handleShowEditForm}
                        handleDelete={handleDelete}
                        showEdit={selectedDistrict ? true : false}
                        showDelete={selectedDistrict ? true : false}
                        loading={loading || getListOfCounties.isLoading || getListOfCounties.status === "loading" || deleteMutationIsLoading}
                        exportButton={true}
                        pdfExportTitle="Counties"
                        csvExportTitle="Counties"
                    />
                    <EditForm selectedDistrict={selectedDistrict} countyData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />
                    {/* {loading || getListOfCounties.isLoading || getListOfCounties.status === "loading" || deleteMutationIsLoading ? <ProgressSpinner /> : null} */}
                </div>
                {/* <ConfirmDialog />; */}
            </Panel>
        </div>
    );
}

export default CountyList;
