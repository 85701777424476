import React, { useState, useEffect } from "react";

import { getAllQuarters, getQuartersById, postQuarters, updateQuarters, deleteQuarters } from "../../../services/financial-years/quarters";

import RowForm from "./widgets/RowForm";
import { useQueryClient, useMutation } from "@tanstack/react-query";

import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

function EditForm(props) {
    const queryClient = useQueryClient();

    const [editMutationIsLoading, setEditMutationIsLoading] = useState(false);
    const editMutation = useMutation({
        mutationFn: (variables) => updateQuarters(props?.rowData?.id, variables),
        onSuccess: () => {
            setEditMutationIsLoading(false);
            props.onClose();
            toast.success("Edited Successfully");
            queryClient.invalidateQueries({ queryKey: ["quarters"] });
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(editMutation?.error, setEditMutationIsLoading);

    const handleSubmit = (data) => {
        setEditMutationIsLoading(true);
        console.log(data);

        editMutation.mutate(data);
    };

    return (
        <Dialog header="Quarters Form" visible={props.show} maximizable onHide={() => props.onHide()}>
            {/* <h3>Programs Edit Form</h3> */}
            <p>Edit Data Below</p>
            <RowForm initialData={props.rowData} handleSubmit={handleSubmit} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}

            {editMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
        </Dialog>
    );
}

export default EditForm;
