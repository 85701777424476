import axiosAPI from "../axiosApi";

export async function getSummarisedFeedbackPieChartStats(params = {}) {
    const response = await axiosAPI.get("getSummarisedFeedbackPieChartStats", { params: params });
    return response;
}

export async function getSumFbByDirBarChartStats(params = {}) {
    const response = await axiosAPI.get("getSumFbByDirBarChartStats", { params: params });
    return response;
}
