import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import ReportStatisticsPage from "./ReportStatisticsPage";
import RowReportsPage from "./RowReportsPage";

import BreadcrumbNav from "../../components/general_components/BreadcrumbNav";

const FeedbackReportsPage = () => {
    return (
        <div>
            <BreadcrumbNav />

            <TabView>
                <TabPanel header="Statistics">
                    <ReportStatisticsPage />
                </TabPanel>
                <TabPanel header="Reports">
                    <RowReportsPage />
                </TabPanel>
            </TabView>
        </div>
    );
};

export default FeedbackReportsPage;
