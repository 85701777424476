import React, { useMemo, useState } from "react";
import { Dialog } from "primereact/dialog";
import moment from "moment"; // Import moment
import { Tag } from "primereact/tag";
//
import MuiTable from "../../../components/general_components/MuiTable.jsx";

import OriginalFeedbackDetailsModal from "../OriginalFeedbackDetailsModal.jsx";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";

//
import NotificationsForm from "./NotificationsForm";
import { Button } from "primereact/button";

import useAuthContext from "../../../context/AuthContext";

import InlineExpandableText from "../../../components/helpers/InlineExpandableText";

function OriginalTaggedfeedback({ feedbackDetailData, ...props }) {
    const { getUserQuery } = useAuthContext();
    const activeUser = getUserQuery?.data?.data;

    //
    //
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState(null);

    const handleShowFeedbackModal = (rowData) => {
        setSelectedFeedback(rowData?.feedback);
        setShowFeedbackModal(true);
    };

    const handleCloseFeedbackModal = () => {
        setShowFeedbackModal(false);
    };
    //
    const getOriginalFeedbacksSeverityColor = (status) => {
        switch (status) {
            case "submitted":
                return "info"; // Blue
            case "auditable":
                return "warning"; // Yellow/Orange
            case "non audit":
                return "danger"; // Red
            case "summarised":
                return "success"; // Green
            case "tagged":
                return "success"; // Green
            default:
                return "secondary"; // Gray or any other default color
        }
    };

    const columns = [
        { title: "#", width: "5%", field: "id", hidden: true },

        {
            title: "Feedback No",
            field: "feedback.feedback_no",
            render: (rowData) => (
                <Tag
                    value={rowData?.feedback?.feedback_no ? rowData?.feedback?.feedback_no : "N/A"}
                    severity={getOriginalFeedbacksSeverityColor(rowData?.spatie_current_status?.name)} // 'info' for blue, 'success' for green
                    style={{ margin: "1rem" }}
                />
            ),
        },
        { title: "Anonymous", field: "feedback.is_anonymous", render: (rowData) => (rowData?.feedback?.is_anonymous ? "Yes" : "No"), hidden: true },
        { title: "Sector", field: "feedback.sector.name", hidden: true },
        {
            title: "Message",
            field: "feedback.message",
            render: (rowData) => {
                const maxLength = 10; // Define max length before truncation
                const message = rowData?.feedback?.message || "No message";
                return <InlineExpandableText text={message} maxLength={maxLength} />;
            },
        },
        {
            title: "Has Attachments",
            field: "feedback.has_attachments",
            render: (rowData) => {
                return rowData?.feedback?.has_attachments ? "Yes" : "No";
            },
            hidden: true,
        },

        { title: "Status", field: "feedback.status", hidden: true },
        { title: "Submit Status", field: "feedback.feedback_submit_status", hidden: true },
        { title: "Latitude", field: "feedback.latitude", hidden: true },
        { title: "Longitude", field: "feedback.longitude", hidden: true },
        // { title: "Accuracy", field: "feedback.accuracy" },
        // { title: "Altitude", field: "feedback.altitude" },
        // { title: "Speed Accuracy", field: "feedback.speed_accuracy" },
        { title: "Region", field: "feedback.region.name", hidden: true },
        { title: "District", field: "feedback.district.name", hidden: true },
        { title: "County", field: "feedback.county.name", hidden: true },
        { title: "Subcounty", field: "feedback.subcounty.name", hidden: true },
        { title: "Parish", field: "feedback?.parish?.name", hidden: true },
        { title: "Village", field: "feedback.village.name", hidden: true },
        { title: "Location Scope", field: "feedback.location_scope", hidden: true },
        { title: "Country", field: "feedback.country.name", hidden: true },
        { title: "Created By", field: "feedback.created_by.name", hidden: true },
        { title: "Created By Email", field: "feedback.created_by.email", hidden: true },
        { title: "Updated By", field: "feedback.updated_by.name", hidden: true },
        {
            title: "Date",
            field: "feedback.created_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.feedback?.created_at).format("lll");
            },
        },
        {
            title: "Updated At",
            field: "feedback.updated_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.feedback?.updated_at).format("lll");
            },
        },
        {
            title: "View",
            field: "feedback.created_by.name",
            render: (rowData) => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleShowFeedbackModal(rowData)}>
                        <Tooltip title="Click to view feedback">
                            <VisibilityIcon style={{ color: "blue" }} />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    //================================= sent notifications through report =================

    const [showNotificationsForm, setShowNotificationsForm] = useState(false);

    const handleShowNotificationsForm = () => {
        setShowNotificationsForm(true);
    };

    const handleCloseNotificationsForm = () => {
        // setTableSelectedRows([]);
        setShowNotificationsForm(false);
    };

    return (
        <>
            {/* Feedback Attachments */}
            <div style={{ marginTop: "1rem", minWidth: "70vw" }}>
                {activeUser?.permissions.includes("notify users through summarised feedback") && feedbackDetailData?.original_feedbacks?.length > 0 && (
                    <div style={{ height: "3rem", margin: "0.4rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                        <Button
                            label="Notify"
                            className="p-button-primary"
                            onClick={() => {
                                handleShowNotificationsForm();
                            }}
                        />
                    </div>
                )}
                {showNotificationsForm && <NotificationsForm summarisedFeedback={feedbackDetailData} show={showNotificationsForm} onHide={handleCloseNotificationsForm} onClose={handleCloseNotificationsForm} />}
                <MuiTable
                    tableTitle="Orignal Tagged Feedback"
                    tableData={feedbackDetailData?.original_feedbacks ?? []}
                    tableColumns={columns}
                    // handleShowEditForm={handleShowEditForm}
                    // handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    // showEdit={activeUser?.permissions.includes("update")}
                    // showDelete={activeUser?.permissions.includes("delete")}
                    // loading={isLoading || status === "loading" || deleteMutationIsLoading}
                    // //
                    // handleViewPage={(rowData) => {
                    //     navigate("product-category-brand", { state: { productCategoryBrandData: rowData } });
                    // }}
                    // showViewPage={true}
                    // hideRowViewPage={false}
                    //
                    exportButton={true}
                    pdfExportTitle="Orignal Feedback"
                    csvExportTitle="Orignal Feedback"
                />
            </div>

            {showFeedbackModal && <OriginalFeedbackDetailsModal feedbackDetailData={selectedFeedback} show={showFeedbackModal} onHide={handleCloseFeedbackModal} onClose={handleCloseFeedbackModal} />}
        </>
    );
}

export default OriginalTaggedfeedback;
