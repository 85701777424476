import moment from "moment";
import React, { useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { deleteDistrictById, getAllDistricts } from "../../../services/locations/districts-service";
import CreateForm from "./CreateForm";
import EditForm from "./EditForm";
import MuiTable from "../../../components/general_components/MuiTable";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { Button } from "primereact/button";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import { Panel } from "primereact/panel";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import useHandleQueryError from "../../../hooks/useHandleQueryError";
//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

function DistrictsList({ selectedRegion }) {
    const queryClient = useQueryClient();
    const [county, setCounty] = useState([]);
    const [selectedItem, setSelectedItem] = useState({ id: null });
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const getAllDistrictsQuery = useQuery({
        queryKey: ["districts", "by_region", selectedRegion?.id],
        queryFn: () => (!selectedRegion?.id ? getAllDistricts() : getAllDistricts({ region_id: selectedRegion?.id })),
    });

    console.log("getAllDistrictsQuery : ", getAllDistrictsQuery);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(getAllDistrictsQuery?.isError, getAllDistrictsQuery?.error);

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: deleteDistrictById,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["districts"] });
            setDeleteMutationIsLoading(false);
            toast.success("District deleted successfully!");
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(deleteMutation?.error, setDeleteMutationIsLoading);

    const handleDelete = (event, id) => {
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                setDeleteMutationIsLoading(true);
                deleteMutation.mutate(id);
            },
        });
    };

    const columns = [
        {
            title: "#",
            field: "id",
            width: "5%",
        },
        ...(!selectedRegion
            ? [
                  {
                      title: "Name",
                      field: "name",
                      cellStyle: {
                          whiteSpace: "nowrap",
                          padding: "8px",
                      },
                  },
              ]
            : [
                  {
                      title: "Name",
                      cellStyle: {
                          whiteSpace: "nowrap",
                          padding: "8px",
                      },
                      field: "name",
                      render: (rowData) => {
                          return (
                              <Link to="counties" state={{ selectedDistrict: rowData }} className="text-decoration-none">
                                  {rowData.name}
                              </Link>
                          );
                      },
                  },
              ]),

        {
            title: "Region Name",
            field: "region.name",
        },
        {
            title: "Date",
            field: "created_at",
            render: (rowData) => moment(rowData.created_at).format("lll"),
        },
    ];

    return (
        <div className="p-grid">
            <Panel header="Districts" style={{ marginBottom: "20px" }} toggleable>
                {selectedRegion && (
                    <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end" }}>
                        <Button label="Add District" onClick={() => setShowAddForm(true)} />
                        <CreateForm selectedRegion={selectedRegion} show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                    </div>
                )}

                <div className="p-col-12">
                    <MuiTable
                        tableTitle="Districts"
                        tableData={getAllDistrictsQuery?.data?.data?.data ?? []}
                        tableColumns={columns}
                        handleShowEditForm={handleShowEditForm}
                        handleDelete={handleDelete}
                        showEdit={selectedRegion ? true : false}
                        showDelete={selectedRegion ? true : false}
                        loading={loading || getAllDistrictsQuery.isLoading || getAllDistrictsQuery.status === "loading" || deleteMutationIsLoading}
                        exportButton={true}
                        pdfExportTitle="Districts"
                        csvExportTitle="Districts"
                    />
                    <EditForm selectedRegion={selectedRegion} districtData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />
                    {/* {loading || getAllDistrictsQuery.isLoading || getAllDistrictsQuery.status === "loading" || deleteMutationIsLoading ? <ProgressSpinner /> : null} */}
                </div>
                {/* <ConfirmDialog />; */}
            </Panel>
        </div>
    );
}

export default DistrictsList;
