import React, { useEffect, useState, useRef } from "react";

import { getAllUsers, bulkDestroyUsers } from "../../services/auth/user-service";
import EditForm from "./EditForm";
import CreateForm from "./CreateForm";
import WaterIsLoading from "../../components/general_components/WaterIsLoading";
import moment from "moment";
import { Link } from "react-router-dom";
import MuiTable from "../../components/general_components/MuiTable";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import UserDetailsPage from "./UserDetailsPage";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import { Panel } from "primereact/panel";

//
import useHandleQueryError from "../../hooks/useHandleQueryError";
import useHandleMutationError from "../../hooks/useHandleMutationError.js";

//
import SyncLdapUsersButton from "./SyncLdapUsersButton";

//
import NotificationsForm from "./NotificationsForm.jsx";
import { Toast as PrimeReactToast } from "primereact/toast";

function UserListForActiveDirectory({ loggedInUserData }) {
    const queryClient = useQueryClient();
    const [selectedItem, setSelectedItem] = useState({ id: null });

    const [showUserForm, setShowUserForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [userDetailShowModal, setUserDetailShowModal] = useState(false);
    const [userDetail, setUserDetail] = useState();

    const handleOpenuserDetailModal = (rowData) => {
        setUserDetail(rowData);
        setUserDetailShowModal(true);
    };

    const handleCloseuserDetailModal = () => {
        setUserDetailShowModal(false);
    };

    const handleShowEditForm = (item) => {
        // setSelectedItem(item);
        // setShowEditForm(true);

        if (validateSelectedRowsOnEdit()) {
            // Pass the selected item (first item in tableSelectedRows) to the edit handler
            setSelectedItem(tableSelectedRows[0]);
            setShowEditForm(true);
        }
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const handleShowUserForm = () => {
        setShowUserForm(true);
    };
    const handleCloseUserForm = () => {
        setShowUserForm(false);
    };

    const registration_channels = ["active directory"];

    const getListOfUsers = useQuery({ queryKey: ["users", ...registration_channels], queryFn: () => getAllUsers({ registration_channels }) });
    console.log("users list : ", getListOfUsers?.data?.data);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(getListOfUsers?.isError, getListOfUsers?.error);

    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: bulkDestroyUsers,
        onSuccess: (data) => {
            // queryClient.resetQueries(["users"]);
            queryClient.invalidateQueries({ queryKey: ["users"] });
            setDeleteMutationIsLoading(false);
            console.log("deleted user succesfully ooooo: ");
            toast.success("Deleted Successfully");
            setTableSelectedRows([]);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(deleteMutation?.error, setDeleteMutationIsLoading);

    // const handleDelete = async (event, id) => {
    //     console.log("users is xxxxx : ", id);
    //     var result = window.confirm("Are you sure you want to delete? ");
    //     if (result === true) {
    //         setLoading(true);
    //         deleteUserMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        console.log("Delete Id Is : ", id);

        let deleteUserId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(deleteUserId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (deleteUserId) => {
        if (validateSelectedRows()) {
            console.log("Ohh Yea delete User Id Not Null");
            setDeleteMutationIsLoading(true);
            // deleteMutation.mutate(deleteUserId);
            deleteMutation.mutate({ users: tableSelectedRows });
        }
    };

    const cancelDelete = () => {
        // setDeleteDirectorateId(null);
    };

    let tableId = 0;

    const columns = [
        {
            title: "#",
            width: "5%",
            field: "name",
            render: (rowData) => {
                tableId = rowData.tableData.id;
                tableId++;
                return <div>{rowData.tableData.id}</div>;
            },
        },
        {
            title: "Name",
            field: "name",
            render: (rowData) => {
                return (
                    <span style={{ color: "blue", cursor: "pointer" }} onClick={() => handleOpenuserDetailModal(rowData)}>
                        {rowData?.name}
                    </span>
                );
            },
        },
        {
            title: "Email",
            field: "email",
        },
        {
            title: "Phone",
            field: "phone",
        },
        {
            title: "Gender", // New column for gender
            field: "gender",
        },
        // {
        //     title: "Date of Birth", // New column for date_of_birth
        //     field: "date_of_birth",
        //     render: (rowData) => {
        //         return moment(rowData?.date_of_birth).format("DD-MM-YYYY"); // Format using moment
        //     },
        // },
        {
            title: "Role",
            field: "role",
        },
        {
            title: "Registration Channel",
            field: "registration_channel",
        },
        {
            title: "Allow Notifications",
            field: "allow_notifications",
            render: (rowData) => (rowData.allow_notifications ? "Yes" : "No"),
        },
        {
            title: "status",
            field: "status",
        },

        {
            title: "lastlogin",
            field: "lastlogin",
            hidden: true,
        },
        { title: "Directorate", field: "user_directorate.directorate.name", hidden: true },
        { title: "Directorate Office", field: "user_directorate_office.directorate_office.name", hidden: true },
        {
            title: "Citizenship",
            field: "citizenship",
            hidden: true,
        },

        { title: "Region", field: "region.name", hidden: true },
        { title: "District", field: "district.name", hidden: true },
        { title: "County", field: "county.name", hidden: true },
        { title: "Subcounty", field: "subcounty.name", hidden: true },
        { title: "Parish", field: "parish.name", hidden: true },
        { title: "Village", field: "village.name", hidden: true },
        { title: "Created By", field: "created_by.name", hidden: true },
        { title: "Created By Email", field: "created_by.email", hidden: true },
        { title: "Updated By", field: "updated_by.name", hidden: true },
        {
            title: "Created At",
            field: "created_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.created_at).format("lll");
            },
        },
        {
            title: "Updated At",
            field: "updated_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.updated_at).format("lll");
            },
        },
    ];

    //table selection
    const primeReactToastRef = useRef(null);
    const [tableSelectedRows, setTableSelectedRows] = useState([]);
    const [showNotificationsForm, setShowNotificationsForm] = useState(false);

    const validateSelectedRows = () => {
        if (tableSelectedRows.length === 0) {
            primeReactToastRef.current.show({ severity: "warn", summary: "Warning", detail: "No rows selected", life: 3000 });
            return false;
        }

        return true;
    };

    const validateSelectedRowsOnEdit = () => {
        if (tableSelectedRows.length !== 1) {
            const message = tableSelectedRows.length === 0 ? "No rows selected" : "Please select only one row to edit";
            primeReactToastRef.current.show({ severity: "warn", summary: "Warning", detail: message, life: 3000 });
            return false;
        }
        return true;
    };

    //
    const handleShowNotificationsForm = () => {
        if (validateSelectedRows()) {
            setShowNotificationsForm(true);
        }
    };

    const handleCloseNotificationsForm = () => {
        // setTableSelectedRows([]);
        setShowNotificationsForm(false);
    };

    return (
        <div style={{ width: "100%" }}>
            <Panel header="User Management" style={{ marginBottom: "20px" }} toggleable>
                <PrimeReactToast ref={primeReactToastRef} />
                <div>
                    <div xs={12}>
                        <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                            {loggedInUserData?.permissions.includes("notify users") && tableSelectedRows.length > 0 && (
                                <Button
                                    label="Notify"
                                    className="p-button-primary"
                                    onClick={() => {
                                        handleShowNotificationsForm();
                                    }}
                                />
                            )}
                            {showNotificationsForm && <NotificationsForm setTableSelectedRows={setTableSelectedRows} selectedTableRowsData={tableSelectedRows} show={showNotificationsForm} onHide={handleCloseNotificationsForm} onClose={handleCloseNotificationsForm} />}
                            <SyncLdapUsersButton />
                            {loggedInUserData?.permissions?.includes("create user") && (
                                <div>
                                    <div md={12} className="text-end">
                                        <Button onClick={() => handleShowUserForm()}>Add user</Button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            <div>
                                <MuiTable
                                    tableTitle="Users"
                                    tableData={getListOfUsers?.data?.data?.data ?? []}
                                    tableColumns={columns}
                                    handleShowEditForm={handleShowEditForm}
                                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                                    showEdit={loggedInUserData?.permissions?.includes("update user")}
                                    showDelete={loggedInUserData?.permissions?.includes("delete user")}
                                    loading={getListOfUsers.isLoading || deleteMutationIsLoading}
                                    //multiple select
                                    selection={true}
                                    showSelectAllCheckbox={true}
                                    showTextRowsSelected={true}
                                    selectionChange={(selectedRows) => {
                                        console.log("selected rows on sselection change : ", selectedRows);
                                        setTableSelectedRows(selectedRows);
                                    }}
                                    //
                                    exportButton={true}
                                    pdfExportTitle="Active Directory Users"
                                    csvExportTitle="Active Directory Users"
                                />

                                <UserDetailsPage user={userDetail} showModal={userDetailShowModal} handleCloseModal={handleCloseuserDetailModal} />
                                <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} loggedInUserData={loggedInUserData} />
                                <CreateForm show={showUserForm} onHide={handleCloseUserForm} onClose={handleCloseUserForm} loggedInUserData={loggedInUserData} />
                                {/* {(getListOfUsers.isLoading || getListOfUsers.status == "loading") && <WaterIsLoading />} */}
                            </div>
                        </div>
                    </div>
                </div>
            </Panel>
        </div>
    );
}

export default UserListForActiveDirectory;
