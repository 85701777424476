import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { classNames } from "primereact/utils";

import setFieldTouched from "final-form-set-field-touched";
//
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileUpload } from "primereact/fileupload";
import { InputSwitch } from "primereact/inputswitch";

//
import { Calendar } from "primereact/calendar";
import moment from "moment";

//
import { AutoComplete } from "primereact/autocomplete";
import { ProgressSpinner } from "primereact/progressspinner";

import { getAllRegions } from "../../../../services/locations/regions-service.js";
import { getAllDistricts } from "../../../../services/locations/districts-service.js";
import { getAllCounty } from "../../../../services/locations/county-service.js";
import { getAllSubcounty } from "../../../../services/locations/subcounty-service.js";
import { getAllParish } from "../../../../services/locations/parish-service.js";
import { getAllVillage } from "../../../../services/locations/village-service.js";
import { getAllTopics } from "../../../../services/topics/topics-service.js";

import useHandleQueryError from "../../../../hooks/useHandleQueryError.js";

//
import { getAllFinancialYears } from "../../../../services/financial-years/financial-years-service.js";
import { getAllQuarters } from "../../../../services/financial-years/quarters.js";

function ServicesFeedbackFiltersRowForm({ handleSubmit, initialData, onHide = () => {}, ...props }) {
    console.log("🚀df ~ ServicesFeedbackFiltersRowForm ~ initialData:", initialData);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    //================statuses =======================

    const [dropdownStatuses, setDropdownStatuses] = useState([]); // Holds the filtered status suggestions
    const [selectedStatuses, setSelectedStatuses] = useState(initialData?.statuses ?? []); // Holds the selected status(es)

    const statuses = [
        { id: 1, name: "Submitted", code: "submitted" },
        { id: 3, name: "In Progress", code: "in progress" },
        { id: 4, name: "Resolved", code: "resolved" },
        { id: 5, name: "Compliment", code: "compliment" },
    ];

    //============ Auto Complete States =================

    const [dropdownRegions, setDropdownRegions] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState(initialData?.regions ?? []);

    const [dropdownDistricts, setDropdownDistricts] = useState([]);
    const [selectedDistricts, setSelectedDistricts] = useState(initialData?.districts ?? []);

    const [dropdownCounties, setDropdownCounties] = useState([]);
    const [selectedCounties, setSelectedCounties] = useState(initialData?.counties ?? []);

    const [dropdownSubcounties, setDropdownSubcounties] = useState([]);
    const [selectedSubcounties, setSelectedSubcounties] = useState(initialData?.subcounties ?? []);

    const [dropdownParishes, setDropdownParishes] = useState([]);
    const [selectedParishes, setSelectedParishes] = useState(initialData?.parishes ?? []);

    const [dropdownVillages, setDropdownVillages] = useState([]);
    const [selectedVillages, setSelectedVillages] = useState(initialData?.villages ?? []);

    //========= countries query

    // Regions Query
    const regionsQuery = useQuery({
        queryKey: ["regions"],
        queryFn: () => getAllRegions(),
    });
    console.log("🚀 ~ ServicesFeedbackFiltersRowForm ~ regionsQuery:", regionsQuery?.data?.data?.data);
    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(regionsQuery?.isError, regionsQuery?.error);

    // Districts Query (depends on selected regions)
    const districtsQuery = useQuery({
        queryKey: ["districts", selectedRegions],
        queryFn: () => getAllDistricts({ regions: selectedRegions }),
        enabled: selectedRegions.length > 0, // Enabled only if regions are selected
    });

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(districtsQuery?.isError, districtsQuery?.error);

    // Step 2: Queries for each location type with error handling
    const countiesQuery = useQuery({
        queryKey: ["counties"],
        queryFn: () => getAllCounty({ districts: selectedDistricts }),
        enabled: selectedDistricts.length > 0,
    });

    // Use the custom hook to handle errors for countiesQuery
    useHandleQueryError(countiesQuery?.isError, countiesQuery?.error);

    const subcountiesQuery = useQuery({
        queryKey: ["subcounties", selectedCounties],
        queryFn: () => getAllSubcounty({ counties: selectedCounties }),
        enabled: selectedCounties.length > 0, // Only fetch if counties are selected
    });

    // Use the custom hook to handle errors for subcountiesQuery
    useHandleQueryError(subcountiesQuery?.isError, subcountiesQuery?.error);

    const parishesQuery = useQuery({
        queryKey: ["parishes", selectedSubcounties],
        queryFn: () => getAllParish({ subcounties: selectedSubcounties }),
        enabled: selectedSubcounties.length > 0, // Only fetch if subcounties are selected
    });

    // Use the custom hook to handle errors for parishesQuery
    useHandleQueryError(parishesQuery?.isError, parishesQuery?.error);

    const villagesQuery = useQuery({
        queryKey: ["villages", selectedParishes],
        queryFn: () => getAllVillage({ parishes: selectedParishes }),
        enabled: selectedParishes.length > 0, // Only fetch if parishes are selected
    });

    // Use the custom hook to handle errors for villagesQuery
    useHandleQueryError(villagesQuery?.isError, villagesQuery?.error);

    //=========================

    const [selectedTopics, setSelectedTopics] = useState(initialData?.topics ?? []);
    const [dropdownTopics, setDropdownTopics] = useState([]);

    const topicsQuery = useQuery({
        queryKey: ["topics"],
        queryFn: () => getAllTopics(),
    });

    // Use the custom hook to handle errors for topicsQuery
    useHandleQueryError(topicsQuery?.isError, topicsQuery?.error);

    // Financial Year and Quarter States
    const [dropdownFinancialYears, setDropdownFinancialYears] = useState([]);
    const [selectedFinancialYear, setSelectedFinancialYear] = useState(initialData?.financial_year);

    const [dropdownQuarters, setDropdownQuarters] = useState([]);
    const [selectedQuarter, setSelectedQuarter] = useState(initialData?.quarter);

    // Fetch Financial Years
    const financialYearsQuery = useQuery({
        queryKey: ["financialYears"],
        queryFn: getAllFinancialYears, // Replace with your actual API service
    });

    useHandleQueryError(financialYearsQuery?.isError, financialYearsQuery?.error);

    // Fetch Quarters (Dependent on Selected Financial Year)
    const quartersQuery = useQuery({
        queryKey: ["quarters"],
        queryFn: () => getAllQuarters(), // Replace with your actual API service
        enabled: selectedFinancialYear ? true : false, // Fetch only when a financial year is selected
    });

    useHandleQueryError(quartersQuery?.isError, quartersQuery?.error);

    //
    const validate = (values) => {
        const errors = {};

        if (values.startDate && values.endDate && moment(values.startDate).isAfter(values.endDate)) {
            errors.startDate = "Start date cannot be after end date";
        }

        // // Validate 'regions'
        // if (!values?.regions || !Array.isArray(values?.regions) || values?.regions?.length === 0) {
        //     errors.regions = "At least one region must be selected";
        // }

        // // Validate 'districts'
        // if (values?.regions?.length > 0 && (!values?.districts || !Array.isArray(values?.districts) || values?.districts?.length === 0)) {
        //     errors.districts = "At least one district must be selected";
        // }

        // // Validate 'counties'
        // if (values.districts.length > 0 && (!values.counties || !Array.isArray(values.counties) || values.counties.length === 0)) {
        //     errors.counties = "At least one county must be selected";
        // }

        // // Validate 'subcounties'
        // if (values.counties.length > 0 && (!values.subcounties || !Array.isArray(values.subcounties) || values.subcounties.length === 0)) {
        //     errors.subcounties = "At least one subcounty must be selected";
        // }

        // // Validate 'parishes'
        // if (values.subcounties.length > 0 && (!values.parishes || !Array.isArray(values.parishes) || values.parishes.length === 0)) {
        //     errors.parishes = "At least one parish must be selected";
        // }

        // // Validate 'villages'
        // if (values.parishes.length > 0 && (!values.villages || !Array.isArray(values.villages) || values.villages.length === 0)) {
        //     errors.villages = "At least one village must be selected";
        // }

        return errors;
    };

    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            // No validation errors
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please first fill in all required fields");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
            onHide();
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <div className="card p-fluid">
            <Form
                onSubmit={onSubmitForm}
                initialValues={initialData}
                initialValuesEqual={() => true}
                // initialValuesEqual with a function returning true prevents the form from
                // reinitializing when the initialValues prop changes. This is useful when you
                // want to avoid re-rendering or reinitializing the form due to changes in initial values.
                // Be cautious using this if your initial values are meant to be dynamic and responsive
                // to changes in your application's state.
                mutators={{ setFieldTouched }}
                validate={validate}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            console.log("values hhh : ", values);
                            console.log("event fffff : ", event);
                            onSubmitForm(values, form);
                            // handleSubmit(event, values);
                        }}
                    >
                        <div className="grid">
                            {/* Financial Year Field */}
                            <div className="col-12 md:col-6 lg:col-3">
                                <Field name="financial_year">
                                    {({ input, meta }) => {
                                        const financialYearsData = financialYearsQuery?.data?.data?.data || [];

                                        const fetchFinancialYearSuggestions = (event) => {
                                            let query = event.query.toLowerCase();
                                            let filtered = financialYearsData.filter((year) => year?.name?.toLowerCase().includes(query));
                                            setDropdownFinancialYears(filtered);
                                        };

                                        return (
                                            <div className="p-field m-4">
                                                <label htmlFor="financial_year">Financial Year</label>
                                                <AutoComplete
                                                    {...input}
                                                    multiple={false}
                                                    suggestions={dropdownFinancialYears}
                                                    completeMethod={fetchFinancialYearSuggestions}
                                                    field="name"
                                                    value={selectedFinancialYear}
                                                    onChange={(e) => {
                                                        setSelectedFinancialYear(e.value);
                                                        input.onChange(e.value);

                                                        // Reset quarter when financial year changes
                                                        setSelectedQuarter(null);
                                                        setDropdownQuarters([]);

                                                        // Prefill startDate and endDate fields dynamically
                                                        if (e.value) {
                                                            const { start_date, end_date } = e.value;

                                                            // Ensure start_date and end_date are valid
                                                            if (moment(start_date, "YYYY-MM-DD", true).isValid() && moment(end_date, "YYYY-MM-DD", true).isValid()) {
                                                                const startDate = moment(start_date, "YYYY-MM-DD").toDate();
                                                                const endDate = moment(end_date, "YYYY-MM-DD").toDate();

                                                                // Update the form with the start and end dates
                                                                form.change("startDate", startDate);
                                                                form.change("endDate", endDate);
                                                            }
                                                        }
                                                    }}
                                                    dropdown={true}
                                                    disabled={financialYearsQuery?.isLoading}
                                                    placeholder="Select Financial Year"
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                {/* Show loading spinner if financialYearsQuery is loading */}
                                                {financialYearsQuery?.isLoading && financialYearsQuery?.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            </div>
                                        );
                                    }}
                                </Field>
                            </div>
                            {/* Quarter Field */}
                            <div className="col-12 md:col-6 lg:col-3">
                                <Field name="quarter">
                                    {({ input, meta }) => {
                                        const quartersData = quartersQuery?.data?.data?.data || [];

                                        const fetchQuarterSuggestions = (event) => {
                                            let query = event.query.toLowerCase();
                                            let filtered = quartersData.filter((quarter) => quarter?.name?.toLowerCase().includes(query));
                                            setDropdownQuarters(filtered);
                                        };

                                        return (
                                            <div className="p-field m-4">
                                                <label htmlFor="quarter">Quarter</label>
                                                <AutoComplete
                                                    {...input}
                                                    multiple={false}
                                                    suggestions={dropdownQuarters}
                                                    completeMethod={fetchQuarterSuggestions}
                                                    field="name"
                                                    value={selectedQuarter}
                                                    onChange={(e) => {
                                                        setSelectedQuarter(e.value);
                                                        input.onChange(e.value);

                                                        // Prefill logic
                                                        if (selectedFinancialYear && e.value) {
                                                            const { start_month, end_month } = e.value; // Use end_month instead of number_of_months
                                                            const { start_date, end_date } = selectedFinancialYear;

                                                            // Ensure start_date and end_date are valid
                                                            if (moment(start_date, "YYYY-MM-DD", true).isValid() && moment(end_date, "YYYY-MM-DD", true).isValid()) {
                                                                const financialYearStart = moment(start_date, "YYYY-MM-DD");
                                                                const financialYearEnd = moment(end_date, "YYYY-MM-DD");

                                                                // Ensure start_month and end_month are valid
                                                                if (start_month > 0 && start_month <= 12 && end_month > 0 && end_month <= 12) {
                                                                    // Calculate the number of months
                                                                    let number_of_months;
                                                                    if (end_month >= start_month) {
                                                                        number_of_months = end_month - start_month + 1;
                                                                    } else {
                                                                        // Handles cases where end_month is in the next calendar year
                                                                        number_of_months = 12 - start_month + 1 + end_month;
                                                                    }

                                                                    // Calculate the start date within the financial year
                                                                    let startDate = financialYearStart.clone().month(start_month - 1);

                                                                    // If the start month exceeds the calendar year, adjust the year
                                                                    if (startDate.isBefore(financialYearStart)) {
                                                                        startDate.add(1, "year");
                                                                    }

                                                                    // Calculate the end date
                                                                    let endDate = startDate.clone().add(number_of_months, "months").subtract(1, "day");

                                                                    // Ensure the calculated dates are within the financial year range
                                                                    if (startDate.isBefore(financialYearStart)) {
                                                                        startDate = financialYearStart.clone();
                                                                    }
                                                                    if (endDate.isAfter(financialYearEnd)) {
                                                                        endDate = financialYearEnd.clone();
                                                                    }

                                                                    // Update the form with calculated dates
                                                                    form.change("startDate", startDate.toDate());
                                                                    form.change("endDate", endDate.toDate());
                                                                }
                                                            }
                                                        }
                                                    }}
                                                    dropdown={true}
                                                    disabled={quartersQuery?.isLoading || !selectedFinancialYear}
                                                    placeholder="Select Quarter"
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                {quartersQuery?.isLoading && quartersQuery?.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            </div>
                                        );
                                    }}
                                </Field>
                            </div>
                            <div className="col-12 md:col-6 lg:col-3">
                                <Field name="startDate">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="startDate">Start Date</label>
                                            <Calendar
                                                id="startDate"
                                                {...input}
                                                value={input.value ? moment.utc(input.value).toDate() : null}
                                                dateFormat="dd/mm/yy"
                                                showButtonBar
                                                showIcon
                                                // showTime
                                                // hourFormat="12"
                                                className={classNames({ "p-invalid": meta.error && meta.touched })}
                                            />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="col-12 md:col-6 lg:col-3">
                                <Field name="endDate">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="endDate">End Date</label>
                                            <Calendar
                                                id="endDate"
                                                {...input}
                                                value={input.value ? moment.utc(input.value).toDate() : null}
                                                dateFormat="dd/mm/yy"
                                                showButtonBar
                                                showIcon
                                                // showTime
                                                // hourFormat="12"
                                                className={classNames({ "p-invalid": meta.error && meta.touched })}
                                            />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="col-12 md:col-6 lg:col-3">
                                <Field name="statuses">
                                    {({ input, meta }) => {
                                        const fetchStatusSuggestions = (event) => {
                                            let query = event.query.toLowerCase();
                                            let filtered = statuses.filter((status) => status.name.toLowerCase().includes(query));
                                            filtered = filtered.filter((status) => !selectedStatuses.some((selected) => selected.id === status.id));
                                            setDropdownStatuses(filtered);
                                        };

                                        return (
                                            <div className="p-field m-4">
                                                <label htmlFor="statuses">Statuses</label>
                                                <AutoComplete
                                                    {...input}
                                                    multiple={true}
                                                    // selectionLimit={1}
                                                    suggestions={dropdownStatuses}
                                                    completeMethod={fetchStatusSuggestions}
                                                    field="name"
                                                    value={selectedStatuses}
                                                    onChange={(e) => {
                                                        setSelectedStatuses(e.value);
                                                        input.onChange(e.value);
                                                    }}
                                                    dropdown={true}
                                                    placeholder="Select status"
                                                    className={classNames({
                                                        "p-invalid": meta.touched && meta.error,
                                                    })}
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        );
                                    }}
                                </Field>
                            </div>

                            <div className="col-12 md:col-6 lg:col-3">
                                <Field name="topics">
                                    {({ input, meta }) => {
                                        // Checking the fetched topics data
                                        const topicsData = topicsQuery?.data?.data?.data || [];

                                        // Filtering method for the AutoComplete
                                        const fetchSuggestions = (event) => {
                                            let query = event.query.toLowerCase();
                                            let filtered = topicsData.filter((topic) => topic?.name?.toLowerCase().includes(query));
                                            filtered = filtered.filter((topic) => !selectedTopics.some((selected) => selected.id === topic.id));
                                            setDropdownTopics(filtered); // Set the filtered topics
                                        };

                                        return (
                                            <div className="p-field m-4">
                                                <label htmlFor="topics">Topics</label>

                                                <AutoComplete
                                                    {...input}
                                                    multiple={true}
                                                    suggestions={dropdownTopics} // This comes from the filter method
                                                    completeMethod={fetchSuggestions} // Use the custom filter method
                                                    field="name" // Ensure the display field is "name"
                                                    value={selectedTopics}
                                                    onChange={(e) => {
                                                        setSelectedTopics(e.value);
                                                        input.onChange(e.value);
                                                    }}
                                                    dropdown
                                                    placeholder="Select Topics"
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                />

                                                {/* Error message if validation fails */}
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                {/* Show loading spinner if topicsQuery is loading */}
                                                {topicsQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            </div>
                                        );
                                    }}
                                </Field>
                            </div>

                            <>
                                <div className="col-12 md:col-6 lg:col-3">
                                    <Field name="regions">
                                        {({ input, meta }) => {
                                            // Checking the fetched regions data
                                            const regionsData = regionsQuery?.data?.data?.data || [];

                                            // Filtering method for the AutoComplete
                                            const fetchSuggestions = (event) => {
                                                let query = event.query.toLowerCase();
                                                let filtered = regionsData.filter((region) => region?.name?.toLowerCase().includes(query));
                                                filtered = filtered.filter((region) => !selectedRegions.some((selected) => selected.id === region.id));
                                                setDropdownRegions(filtered); // You need a state for filtered regions.
                                            };

                                            return (
                                                <div className="p-field m-4">
                                                    <label htmlFor="regions">Regions</label>

                                                    <AutoComplete
                                                        {...input}
                                                        multiple={true}
                                                        // selectionLimit={1}
                                                        suggestions={dropdownRegions} // This comes from the filter method
                                                        completeMethod={fetchSuggestions} // Use the custom filter method
                                                        field="name" // Ensure the display field is "name"
                                                        value={selectedRegions}
                                                        onChange={(e) => {
                                                            setSelectedRegions(e.value);
                                                            input.onChange(e.value); // Update form field

                                                            // Reset dependent fields (districts)
                                                            setSelectedDistricts([]); // Clear selected districts in local state
                                                            setDropdownDistricts([]); // Clear dropdown districts data
                                                            setSelectedCounties([]);
                                                            setDropdownCounties([]);
                                                            setSelectedSubcounties([]);
                                                            setDropdownSubcounties([]);
                                                            setSelectedParishes([]);
                                                            setDropdownParishes([]);
                                                            setSelectedVillages([]);
                                                            setDropdownVillages([]);

                                                            // Reset Final Form fields for the dependent districts
                                                            form.change("districts", []);
                                                            form.change("counties", []);
                                                            form.change("subcounties", []);
                                                            form.change("parishes", []);
                                                            form.change("villages", []);
                                                        }}
                                                        dropdown
                                                        placeholder="Select regions"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />

                                                    {/* Error message if validation fails */}
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                    {/* Show loading spinner if regionsQuery is loading */}
                                                    {regionsQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            );
                                        }}
                                    </Field>
                                </div>
                                <div className="col-12 md:col-6 lg:col-3">
                                    <Field name="districts">
                                        {({ input, meta }) => {
                                            // Checking the fetched regions data
                                            const districtsData = districtsQuery?.data?.data?.data || [];

                                            // Filtering method for the AutoComplete
                                            const fetchSuggestions = (event) => {
                                                let query = event.query.toLowerCase();
                                                let filtered = districtsData.filter((district) => district?.name?.toLowerCase().includes(query));
                                                filtered = filtered.filter((district) => !selectedDistricts.some((selected) => selected.id === district.id));
                                                setDropdownDistricts(filtered); // You need a state for filtered regions.
                                            };

                                            return (
                                                <div className="p-field m-4">
                                                    <label htmlFor="districts">Districts</label>

                                                    <AutoComplete
                                                        {...input}
                                                        multiple={true}
                                                        // selectionLimit={1}
                                                        suggestions={dropdownDistricts} // This comes from the filter method
                                                        completeMethod={fetchSuggestions} // Use the custom filter method
                                                        field="name"
                                                        value={selectedDistricts}
                                                        onChange={(e) => {
                                                            setSelectedDistricts(e.value);
                                                            input.onChange(e.value); // Use input.onChange to update the form field

                                                            // Reset dependent fields
                                                            setSelectedCounties([]);
                                                            setDropdownCounties([]);
                                                            setSelectedSubcounties([]);
                                                            setDropdownSubcounties([]);
                                                            setSelectedParishes([]);
                                                            setDropdownParishes([]);
                                                            setSelectedVillages([]);
                                                            setDropdownVillages([]);

                                                            // Reset Final Form fields for the dependent fields
                                                            form.change("counties", []);
                                                            form.change("subcounties", []);
                                                            form.change("parishes", []);
                                                            form.change("villages", []);
                                                        }}
                                                        disabled={selectedRegions.length === 0 || regionsQuery?.isLoading}
                                                        dropdown={true}
                                                        placeholder="Select districts"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />

                                                    {/* Error message if validation fails */}
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                    {/* Show loading spinner if districtsQuery is loading */}
                                                    {districtsQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            );
                                        }}
                                    </Field>
                                </div>
                                {/* counties Field */}
                                <div className="col-12 md:col-6 lg:col-3">
                                    <Field name="counties">
                                        {({ input, meta }) => {
                                            const countiesData = countiesQuery?.data?.data?.data || [];
                                            const fetchSuggestions = (event) => {
                                                let query = event.query.toLowerCase();
                                                let filtered = countiesData.filter((county) => county?.name?.toLowerCase().includes(query));
                                                filtered = filtered.filter((county) => !selectedCounties.some((selected) => selected.id === county.id));
                                                setDropdownCounties(filtered);
                                            };
                                            return (
                                                <div className="p-field m-4">
                                                    <label htmlFor="counties">Counties</label>
                                                    <AutoComplete
                                                        {...input}
                                                        multiple={true}
                                                        // selectionLimit={1}
                                                        suggestions={dropdownCounties}
                                                        completeMethod={fetchSuggestions}
                                                        field="name"
                                                        value={selectedCounties}
                                                        onChange={(e) => {
                                                            setSelectedCounties(e.value);
                                                            input.onChange(e.value);

                                                            //
                                                            // Reset dependent fields
                                                            setSelectedSubcounties([]); // Clear selected subcounties in local state
                                                            setDropdownSubcounties([]); // Clear dropdown subcounties data
                                                            setSelectedParishes([]); // Clear selected parishes
                                                            setDropdownParishes([]); // Clear dropdown parishes data
                                                            setSelectedVillages([]); // Clear selected villages
                                                            setDropdownVillages([]); // Clear dropdown villages data

                                                            // Reset Final Form fields for the dependent fields
                                                            form.change("subcounties", []);
                                                            form.change("parishes", []);
                                                            form.change("villages", []);
                                                        }}
                                                        dropdown={true}
                                                        disabled={selectedDistricts.length === 0 || countiesQuery?.isLoading}
                                                        placeholder="Select counties"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {/* Show loading spinner if regionsQuery is loading */}
                                                    {regionsQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            );
                                        }}
                                    </Field>
                                </div>
                                {/* Subcounty Field (Depends on County) */}
                                <div className="col-12 md:col-6 lg:col-3">
                                    <Field name="subcounties">
                                        {({ input, meta }) => {
                                            const subcountiesData = subcountiesQuery?.data?.data?.data || [];
                                            const fetchSuggestions = (event) => {
                                                let query = event.query.toLowerCase();
                                                let filtered = subcountiesData.filter((subcounty) => subcounty?.name?.toLowerCase().includes(query));
                                                filtered = filtered.filter((subcounty) => !selectedSubcounties.some((selected) => selected.id === subcounty.id));
                                                setDropdownSubcounties(filtered);
                                            };
                                            return (
                                                <div className="p-field m-4">
                                                    <label htmlFor="subcounties">Subcounties</label>
                                                    <AutoComplete
                                                        {...input}
                                                        multiple={true}
                                                        // selectionLimit={1}
                                                        suggestions={dropdownSubcounties}
                                                        completeMethod={fetchSuggestions}
                                                        field="name"
                                                        value={selectedSubcounties}
                                                        onChange={(e) => {
                                                            setSelectedSubcounties(e.value);
                                                            input.onChange(e.value);

                                                            //
                                                            // Reset dependent fields
                                                            setSelectedParishes([]);
                                                            setDropdownParishes([]);
                                                            setSelectedVillages([]);
                                                            setDropdownVillages([]);

                                                            // Reset Final Form fields
                                                            form.change("parishes", []);
                                                            form.change("villages", []);
                                                        }}
                                                        dropdown={true}
                                                        disabled={selectedCounties.length === 0 || subcountiesQuery?.isLoading}
                                                        placeholder="Select subcounties"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                    {/* Show loading spinner if subcountiesQuery is loading */}
                                                    {subcountiesQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            );
                                        }}
                                    </Field>
                                </div>
                                {/* Parish Field (Depends on Subcounty) */}
                                <div className="col-12 md:col-6 lg:col-3">
                                    <Field name="parishes">
                                        {({ input, meta }) => {
                                            const parishesData = parishesQuery?.data?.data?.data || [];
                                            const fetchSuggestions = (event) => {
                                                let query = event.query.toLowerCase();
                                                let filtered = parishesData.filter((parish) => parish?.name?.toLowerCase().includes(query));
                                                filtered = filtered.filter((parish) => !selectedParishes.some((selected) => selected.id === parish.id));
                                                setDropdownParishes(filtered);
                                            };
                                            return (
                                                <div className="p-field m-4">
                                                    <label htmlFor="parishes">Parishes</label>
                                                    <AutoComplete
                                                        {...input}
                                                        multiple={true}
                                                        // selectionLimit={1}
                                                        suggestions={dropdownParishes}
                                                        completeMethod={fetchSuggestions}
                                                        field="name"
                                                        value={selectedParishes}
                                                        onChange={(e) => {
                                                            setSelectedParishes(e.value);
                                                            input.onChange(e.value);

                                                            // Reset dependent fields
                                                            setSelectedVillages([]);
                                                            setDropdownVillages([]);

                                                            // Reset Final Form fields
                                                            form.change("villages", []);
                                                        }}
                                                        dropdown={true}
                                                        disabled={selectedSubcounties.length === 0 || parishesQuery?.isLoading}
                                                        placeholder="Select parishes"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                    {/* Show loading spinner if parishesQuery is loading */}
                                                    {parishesQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            );
                                        }}
                                    </Field>
                                </div>
                                {/* Village Field (Depends on Parish) */}
                                <div className="col-12 md:col-6 lg:col-3">
                                    <Field name="villages">
                                        {({ input, meta }) => {
                                            const villagesData = villagesQuery?.data?.data?.data || [];
                                            const fetchSuggestions = (event) => {
                                                let query = event.query.toLowerCase();
                                                let filtered = villagesData.filter((village) => village?.name?.toLowerCase().includes(query));
                                                filtered = filtered.filter((village) => !selectedVillages.some((selected) => selected.id === village.id));
                                                setDropdownVillages(filtered);
                                            };
                                            return (
                                                <div className="p-field m-4">
                                                    <label htmlFor="villages">Villages</label>
                                                    <AutoComplete
                                                        {...input}
                                                        multiple={true}
                                                        // selectionLimit={1}
                                                        suggestions={dropdownVillages}
                                                        completeMethod={fetchSuggestions}
                                                        field="name"
                                                        value={selectedVillages}
                                                        onChange={(e) => {
                                                            setSelectedVillages(e.value);
                                                            input.onChange(e.value);
                                                        }}
                                                        dropdown={true}
                                                        disabled={selectedParishes.length === 0 || villagesQuery?.isLoading}
                                                        placeholder="Select villages"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                    {/* Show loading spinner if villagesQuery is loading */}
                                                    {villagesQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            );
                                        }}
                                    </Field>
                                </div>
                            </>

                            <div className="col-12 md:col-6 lg:col-3">
                                {/* Order By AutoComplete */}
                                <Field name="orderBy">
                                    {({ input, meta }) => {
                                        const orderByOptions = [
                                            { name: "asc", value: "asc" },
                                            { name: "desc", value: "desc" },
                                        ];

                                        const fetchOrderBySuggestions = (event) => {
                                            let query = event.query.toLowerCase();
                                            let filtered = orderByOptions.filter((option) => option.name.toLowerCase().includes(query));
                                            return filtered;
                                        };

                                        return (
                                            <div className="p-field m-4">
                                                <label htmlFor="orderBy">Order By</label>
                                                <AutoComplete
                                                    {...input}
                                                    suggestions={orderByOptions}
                                                    completeMethod={fetchOrderBySuggestions}
                                                    field="name" // Use 'name' to display in the dropdown
                                                    value={input.value}
                                                    onChange={(e) => {
                                                        input.onChange(e.value); // Update the selected value in form
                                                        // setSelectedOrderBy(e.value); // Update the selected order
                                                    }}
                                                    placeholder="Select Order"
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    dropdown={true}
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        );
                                    }}
                                </Field>
                            </div>
                            <div className="col-12 md:col-6 lg:col-3">
                                {/* Data Limit AutoComplete */}
                                <Field name="dataLimit">
                                    {({ input, meta }) => {
                                        const dataLimitOptions = [
                                            { name: "1", value: 1 },
                                            { name: "5", value: 5 },
                                            { name: "10", value: 10 },
                                            { name: "20", value: 20 },
                                            { name: "All", value: "all" },
                                        ];

                                        const fetchDataLimitSuggestions = (event) => {
                                            let query = event.query.toLowerCase();
                                            let filtered = dataLimitOptions.filter((option) => option.name.toLowerCase().includes(query));
                                            return filtered;
                                        };

                                        return (
                                            <div className="p-field m-4">
                                                <label htmlFor="dataLimit">Data Limit</label>
                                                <AutoComplete
                                                    {...input}
                                                    suggestions={dataLimitOptions}
                                                    completeMethod={fetchDataLimitSuggestions}
                                                    field="name" // Use 'name' to display in the dropdown
                                                    value={input.value}
                                                    onChange={(e) => {
                                                        input.onChange(e.value); // Update the selected value in form
                                                        // setSelectedDataLimit(e.value); // Update the selected data limit
                                                    }}
                                                    placeholder="Select Limit"
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    dropdown={true}
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        );
                                    }}
                                </Field>
                            </div>
                            <div className="col-12">
                                <Button type="submit" label="Filter" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </div>
                    </form>
                )}
            />
            <Dialog
                header="Confirmation"
                visible={showConfirmDialog}
                maximizable
                style={{ minWidth: "30vw" }}
                onHide={onCancel}
                footer={
                    <div>
                        <Button label="Yes" onClick={onConfirm} />
                        <Button label="No" onClick={onCancel} className="p-button-secondary" />
                    </div>
                }
            >
                Are you sure you want to submit?
            </Dialog>
        </div>
    );
}

export default ServicesFeedbackFiltersRowForm;
