import React from "react";
import BreadcrumbNav from "../../../components/general_components/BreadcrumbNav";
import useAuthContext from "../../../context/AuthContext";
import { TabView, TabPanel } from "primereact/tabview";
import StatCards from "./StatCards";
import JobsListPage from "./ListPage";
import FailedJobsListPage from "./FailedJobsListPage";

//
function JobsPage({ loggedInUserData }) {
    const { getUserQuery } = useAuthContext();
    return (
        <div>
            <BreadcrumbNav />

            {/* StatCards at the top */}
            <StatCards />

            {/* Tabs for Jobs List and Failed Jobs List */}
            <TabView>
                <TabPanel header="Jobs List">
                    <JobsListPage loggedInUserData={getUserQuery?.data?.data} />
                </TabPanel>
                <TabPanel header="Failed Jobs List">
                    <FailedJobsListPage loggedInUserData={getUserQuery?.data?.data} />
                </TabPanel>
            </TabView>
        </div>
    );
}

export default JobsPage;
