import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";

import SummarisedFiltersRowForm from "./widgets/SummarisedFiltersRowForm";

function SummarisedFiltersForm({ selectedFilters, handleSubmit, ...props }) {
    // const handleSubmit = async (data) => {
    //     console.log("🚀 ~ handleSubmit ~ data:", data);
    // };
    return (
        <Dialog header="Filters Form" visible={props.show} style={{ width: "80%" }} maximizable onHide={() => props.onHide()}>
            <p>Choose From options below</p>
            <SummarisedFiltersRowForm handleSubmit={handleSubmit} onHide={() => props.onHide()} initialData={selectedFilters} />
        </Dialog>
    );
}

export default SummarisedFiltersForm;
