import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";

const CitizenFeedback = () => {
    return (
        <>
            {/* Description of Citizen Feedback */}
            <p>
                <strong>Citizen Feedback</strong> is a process where feedback from citizens is collected through the CFP (Citizen Feedback Platform). This feedback allows OAG to address these issues based on the needs and concerns of the public. The feedback is reviewed by different roles such as
                the Feedback Coordinator, Director Stakeholder Engagement, AAGC, and AAGA, ensuring that issues raised are addressed appropriately.
            </p>

            <p>Below are the different roles and there tasks performed on Citizen Feedback.</p>

            <Accordion multiple>
                {/* Reporter */}
                <AccordionTab header="Reporter">
                    <Accordion multiple>
                        <AccordionTab header="Submit Citizen Feedback">
                            <video controls style={{ width: "80%" }}>
                                <source src="/user_manual/citizen_feedback/cfp_app_citizen_feedback.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </AccordionTab>
                    </Accordion>
                </AccordionTab>

                {/* Feedback Coordinator */}
                <AccordionTab header="Feedback Coordinator">
                    <Accordion multiple>
                        <AccordionTab header="Accepts Feedback">
                            <video controls style={{ width: "80%" }}>
                                <source src="/user_manual/citizen_feedback/accept_citizen_feedback_01.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </AccordionTab>
                        <AccordionTab header="Rejects Feedback">
                            <video controls style={{ width: "80%" }}>
                                <source src="/user_manual/citizen_feedback/aagc_reject_feedback_01.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </AccordionTab>
                        <AccordionTab header="Summarises Feedback">
                            <video controls style={{ width: "80%" }}>
                                <source src="/user_manual/citizen_feedback/summarised_feedback_01.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </AccordionTab>
                        <AccordionTab header="Sends Summarised Feedback to Director Stakeholder Engagement">
                            <video controls style={{ width: "80%" }}>
                                <source src="/user_manual/citizen_feedback/send_fb_to_corporate_mgt.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </AccordionTab>
                        <AccordionTab header="Sends Summarised Feedback to AAGC">
                            <video controls style={{ width: "80%" }}>
                                <source src="/user_manual/citizen_feedback/send_fb_to_aagc_01.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </AccordionTab>
                        <AccordionTab header="Works on Rejected Feedback from AAGC and Resubmits">
                            <video controls style={{ width: "80%" }}>
                                <source src="/user_manual/citizen_feedback/how_feedback_cord_handles_rejected_feedback_from_aagc.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </AccordionTab>
                        <AccordionTab header="Marks Feedback as Satisfied or Not Satisfied">
                            <video controls style={{ width: "80%" }}>
                                <source src="/user_manual/citizen_feedback/feedback_satisfactory_01.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </AccordionTab>
                    </Accordion>
                </AccordionTab>

                {/* Director Stakeholder Engagement */}
                <AccordionTab header="Director Stakeholder Engagement">
                    <Accordion multiple>
                        <AccordionTab header="Adds Comments onto Summarised Feedback">
                            <video controls style={{ width: "80%" }}>
                                <source src="/user_manual/citizen_feedback/comment_on_sum_fb_01.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </AccordionTab>
                    </Accordion>
                </AccordionTab>

                {/* AAGC */}
                <AccordionTab header="AAGC">
                    <Accordion multiple>
                        {/* <AccordionTab header="Accepts Summarised Feedback from Coordinator">
                        <video controls style={{ width: "80%" }}>
                            <source src="/user_manual/citizen_feedback/aagc_reject_feedback_01.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </AccordionTab> */}
                        <AccordionTab header="Rejects Summarised Feedback and Sends it Back">
                            <video controls style={{ width: "80%" }}>
                                <source src="/user_manual/citizen_feedback/aagc_reject_feedback_01.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </AccordionTab>
                        <AccordionTab header="Forwards Accepted Summarised Feedback to AAGA">
                            <video controls style={{ width: "80%" }}>
                                <source src="/user_manual/citizen_feedback/send_fb_to_aaga_01.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </AccordionTab>
                    </Accordion>
                </AccordionTab>

                {/* AAGA */}
                <AccordionTab header="AAGA">
                    <Accordion multiple>
                        <AccordionTab header="Assigns Directorate Officers to Summarised Feedback">
                            <video controls style={{ width: "80%" }}>
                                <source src="/user_manual/citizen_feedback/asign_fb_to_officers_01.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </AccordionTab>
                    </Accordion>
                </AccordionTab>

                {/* Directorate Officer */}
                <AccordionTab header="Directorate Officer">
                    <Accordion multiple>
                        <AccordionTab header="Works on Summarised Feedback and Updates its Status">
                            <video controls style={{ width: "80%" }}>
                                <source src="/user_manual/citizen_feedback/directorate_officer_update_feedback_status_01.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </AccordionTab>
                    </Accordion>
                </AccordionTab>
            </Accordion>
        </>
    );
};

export default CitizenFeedback;
