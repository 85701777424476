import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";

import { getAllFeedbacks, getFeedbackById, postFeedback, postToUpdateFeedbackStatus, postToEditExistingFeedbackStatus, postToBulkUpdateFeedbackStatus, updateFeedback, deleteFeedbackById, postToBulkDestroyFeedback } from "../../../services/feedback/feedback-service";

import ApprovalEditRow from "../widgets/ApprovalEditRow";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

function ApprovalEditForm({ selectedTableRowsData, setTableSelectedRows, ...props }) {
    const queryClient = useQueryClient();

    const [creactMutationIsLoading, setCreactMutationIsLoading] = useState(false);
    const creactMutation = useMutation({
        mutationFn: postToEditExistingFeedbackStatus,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["feedback"] });
            // queryClient.resetQueries(["feedback"]);
            toast.success("Action Taken Successfully");
            props.onClose();
            setCreactMutationIsLoading(false);
            setTableSelectedRows([]);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(creactMutation?.error, setCreactMutationIsLoading);

    const handleSubmit = async (data) => {
        // event.preventDefault();
        setCreactMutationIsLoading(true);

        console.log("Approval form data we are submitting : ", data);
        let finalData = {
            status: data?.action === "accept" ? "auditable" : data?.action === "new" ? "new" : "non audit",
            reason: data?.reason,
            feedbacks: selectedTableRowsData,
        };
        console.log("🚀 ~ handleSubmit ~ finalData:", finalData);
        creactMutation.mutate(finalData);
    };

    return (
        <Dialog header="Take Action" visible={props.show} maximizable style={{ minWidth: "50vw" }} onHide={() => props.onHide()}>
            <p>Fill in the form below</p>
            <ApprovalEditRow onSubmit={handleSubmit} productCategoryBrandData={props?.productCategoryBrandData} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}
            {creactMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
            {/* <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Program Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
        </Modal> */}
        </Dialog>
    );
}

export default ApprovalEditForm;
