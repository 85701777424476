import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";

import { postToValidateWebOtp } from "../../services/auth/auth"; // Mock OTP verification API call
import { Toast as PrimeReactToast } from "primereact/toast";
import { Howl } from "howler"; // Import Howler for sound
import { InputNumber } from "primereact/inputnumber";

//
import useHandleMutationError from "../../hooks/useHandleMutationError.js";

const OtpPage = ({ showOTPDialog, setShowOTPDialog, loginDetails, setLoginDetails }) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const primeReactToastRef = useRef(null);

    // States for Timer
    const [countdown, setCountdown] = useState(120); // 2 minutes countdown
    const [timerExpired, setTimerExpired] = useState(false);

    // Mutation to handle OTP verification
    const otpVerificationMutation = useMutation({
        mutationFn: (variables) => postToValidateWebOtp(variables),
        onSuccess: (data) => {
            setIsLoading(false);
            setLoginDetails({});
            queryClient.invalidateQueries([]);
            queryClient.resetQueries({ queryKey: ["logged-in-user"] });
            toast.success("OTP verified successfully!");

            // Extract the user's name from the response data
            const userName = data?.data?.name || "User";
            const userDetails = data?.data;

            const message = `Hello ${userName} 👋, welcome to the Citizen Feedback Dashboard! Your insights matter. Explore, analyze, and help us make a positive impact! 🚀`;

            navigate("/dashboard", {
                state: { message, userDetails },
            });
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(otpVerificationMutation.error, setIsLoading);

    const onSubmit = (values) => {
        setIsLoading(true);
        otpVerificationMutation.mutate({ email: loginDetails?.email, ...values });
    };

    const validate = (values) => {
        const errors = {};
        if (!values.otp) {
            errors.otp = "OTP is required";
        }

        if (values.otp && values.otp.length < 4) {
            errors.otp = "OTP must be at least 4 characters long";
        }

        if (values.otp && values.otp.length > 20) {
            errors.otp = "OTP is invalid too many characters";
        }

        return errors;
    };

    // Howler sound
    const sound = new Howl({
        src: ["/media/bell.mp3"], // Path to your sound file
    });

    // Timer function
    useEffect(() => {
        let timer;
        if (showOTPDialog && !timerExpired) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown === 1) {
                        clearInterval(timer);
                        setTimerExpired(true); // Timer expired
                        setShowOTPDialog(false); // Close dialog after expiry
                        setLoginDetails({});
                        return 0;
                    }
                    sound.play(); // Play sound every second
                    return prevCountdown - 1;
                });
            }, 1000); // 1 second interval
        }

        return () => clearInterval(timer); // Cleanup timer on unmount
    }, [showOTPDialog, timerExpired]);

    return (
        <Dialog
            header="OTP Verification"
            visible={showOTPDialog}
            maximizable
            onHide={() => {
                setShowOTPDialog(false);
                setCountdown(120); // Reset countdown when dialog is closed
                setTimerExpired(false); // Reset expired state
                setLoginDetails({});
            }}
        >
            <PrimeReactToast ref={primeReactToastRef} />
            <div className="flex flex-column align-items-center justify-content-center">
                <div
                    style={{
                        borderRadius: "56px",
                        padding: "0.3rem",
                        background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)",
                    }}
                >
                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src="assets/oag_photos/uganda_oag.png" alt="logo" style={{ height: "100px" }} />
                        </div>
                        <div className="text-center mb-5">
                            <h3 className="text-900 font-medium">OTP Verification</h3>
                        </div>

                        <Form
                            onSubmit={onSubmit}
                            validate={validate}
                            render={({ handleSubmit, form, submitting, pristine, values }) => (
                                <form onSubmit={handleSubmit} className="p-fluid">
                                    <div className="text-center mt-3">
                                        {!timerExpired ? (
                                            <span>
                                                Time remaining: {Math.floor(countdown / 60)}:{countdown % 60}
                                            </span>
                                        ) : (
                                            <span className="text-danger">OTP Expired</span>
                                        )}
                                    </div>

                                    <div>
                                        {/* <Field name="otp">
                                            {({ input, meta }) => (
                                                <div className="p-field mb-5">
                                                    <label htmlFor="otp" className="block text-900 text-xl font-medium mb-2">
                                                        Enter OTP
                                                    </label>
                                                    <InputText
                                                        id="otp"
                                                        {...input}
                                                        type="text"
                                                        maxLength={6}
                                                        className={classNames("block w-full md:w-30rem p-3", {
                                                            "p-invalid": meta.error && meta.touched,
                                                        })}
                                                    />
                                                    {meta.error && meta.touched && <small className="block p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field> */}

                                        <Field name="otp">
                                            {({ input, meta }) => (
                                                <div className="p-field mb-5">
                                                    <label htmlFor="otp" className="block text-900 text-xl font-medium mb-2">
                                                        Enter OTP
                                                    </label>
                                                    <InputNumber
                                                        id="otp"
                                                        value={input.value}
                                                        onValueChange={(e) => input.onChange(Number(e.value))} // Convert value to integer
                                                        max={999999} // Max OTP value (6 digits)
                                                        min={0} // Minimum OTP value
                                                        maxLength={7} // Limit input to 6 digits
                                                        className={classNames("md:w-30rem p-3", {
                                                            "p-invalid": meta.error && meta.touched,
                                                        })}
                                                        showButtons={false} // Disable increment/decrement buttons
                                                    />
                                                    {meta.error && meta.touched && <small className="block p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                    <center>{isLoading ? <ProgressSpinner style={{ width: "50px", height: "50px" }} /> : <Button label="Verify OTP" className="p-button p-button-lg p-button-primary w-full md:w-30rem" type="submit" disabled={submitting || pristine} />}</center>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default OtpPage;
