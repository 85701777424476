import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

//
import CurrentStatusRow from "./widgets/CurrentStatusRow.jsx";
import useAuthContext from "../../../context/AuthContext.jsx";

//
import { postToUpdateSumFbCurrentStatus } from "../../../services/feedback/summarised-feedback-service.js";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

import moment from "moment";

//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

function CurrentStatusDiaglog({ feedbackDetailData, type, spatieCurrentStatuses, ...props }) {
    console.log("🚀 ~ CurrentStatusDiaglog ~ feedbackDetailData:", feedbackDetailData);
    console.log("🚀 ~ Actions ~ type:", type);
    const { getUserQuery } = useAuthContext();
    const activeUser = getUserQuery?.data?.data;

    const queryClient = useQueryClient();

    const [creactMutationIsLoading, setCreactMutationIsLoading] = useState(false);
    const creactMutation = useMutation({
        mutationFn: postToUpdateSumFbCurrentStatus,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["summarised_feedback"] });
            queryClient.invalidateQueries({ queryKey: ["feedback-reports", "by_id"] });
            toast.success("Updated Successfully");
            setCreactMutationIsLoading(false);
            props.onClose();
        },
    });
    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(creactMutation?.error, setCreactMutationIsLoading);

    const handleSubmit = async (data) => {
        setCreactMutationIsLoading(true);

        let finalData = {
            status: data?.status,
            comment: data?.comment,
            summarised_feedback_id: feedbackDetailData?.id,
        };

        creactMutation.mutate(finalData);
    };

    const initialData = {
        status: feedbackDetailData?.spatie_current_status?.name,
        comment: feedbackDetailData?.spatie_current_status?.reason,
    };

    return (
        <Dialog header={`Timelines`} visible={props.show} maximizable style={{ minWidth: "50vw" }} onHide={() => props.onClose()}>
            <p>Fill in the form below</p>
            <CurrentStatusRow onSubmit={handleSubmit} initialData={initialData} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}
            {creactMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
        </Dialog>
    );
}

export default CurrentStatusDiaglog;
