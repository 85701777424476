import React, { useMemo, useState } from "react";
import { Dialog } from "primereact/dialog";
import moment from "moment"; // Import moment
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";

import SingleCommentsForm from "../../feedback/summarised-feedback-comments/SingleCommentsForm.jsx";
import SingleCommentsEditForm from "../../feedback/summarised-feedback-comments/SingleCommentsEditForm.jsx";

//
import MuiTable from "../../../components/general_components/MuiTable.jsx";
//
import { getAllSummarisedFeedbackComments, getSummarisedFeedbackCommentById, postSummarisedFeedbackComment, updateSummarisedFeedbackComment, deleteSummarisedFeedbackCommentById } from "../../../services/feedback/summarised-feedback-comments-service.js";
import { toast } from "react-toastify";
//
import useHandleQueryError from "../../../hooks/useHandleQueryError.js";
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";

import useAuthContext from "../../../context/AuthContext.jsx";

function Comments({ feedbackDetailData, ...props }) {
    const { getUserQuery } = useAuthContext();
    const activeUser = getUserQuery?.data?.data;
    //=================== comments ====================

    const queryClient = useQueryClient();
    const { data, isLoading, isError, error, status } = useQuery({
        queryKey: ["summarised_feedback_comments", "summarised_feedback_id", feedbackDetailData?.id],
        queryFn: () => getAllSummarisedFeedbackComments({ summarised_feedback_id: feedbackDetailData?.id }),
    });
    console.log("🚀 ~summarised feedback comments ListPage ~ data:", data?.data?.data);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(isError, error);

    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: (variables) => deleteSummarisedFeedbackCommentById(variables),
        onSuccess: (data) => {
            queryClient.resetQueries({
                queryKey: ["summarised_feedback_comments", "summarised_feedback_id", feedbackDetailData?.id],
                exact: true,
            });

            queryClient.invalidateQueries({ queryKey: ["feedback-reports", "by_id"] });

            // queryClient.invalidateQueries({ queryKey: ["summarised_feedback_comments"] });
            toast.success("Deleted Successfully");
            setDeleteMutationIsLoading(false);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(deleteMutation?.error, setDeleteMutationIsLoading);

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(selectedDeleteId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteId) => {
        if (selectedDeleteId !== null) {
            setDeleteMutationIsLoading(true);
            deleteMutation.mutate(selectedDeleteId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const commentColumns = [
        { title: "#", width: "5%", field: "id", hidden: true },
        {
            title: "comment",
            field: "comment",
        },
        { title: "Created By", field: "created_by.name", hidden: false },
        { title: "Created By Email", field: "created_by.email", hidden: true },
        { title: "Updated By", field: "updated_by.name", hidden: true },
        {
            title: "Date",
            field: "feedback.created_at",
            hidden: false,
            render: (rowData) => {
                return moment(rowData?.feedback?.created_at).format("lll");
            },
        },
        {
            title: "Updated At",
            field: "feedback.updated_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.feedback?.updated_at).format("lll");
            },
        },
    ];

    //
    //table selection
    const [showCommentsForm, setShoCommentsForm] = useState(false);

    const handleShowCommentsForm = () => {
        if (feedbackDetailData) {
            setShoCommentsForm(true);
        }
    };

    const handleCloseCommentsForm = () => {
        // setTableSelectedRows([]);
        setShoCommentsForm(false);
    };

    return (
        <div style={{ marginTop: "1rem", minWidth: "70vw" }}>
            {/* <Typography variant="body1">
        <strong>Attachments:</strong>
    </Typography> */}

            <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                {activeUser?.permissions.includes("create summarised feedback comments") && (
                    <Button
                        label="Comment"
                        className="p-button-primary"
                        onClick={() => {
                            handleShowCommentsForm();
                        }}
                    />
                )}
                {showCommentsForm && <SingleCommentsForm selectedTableRowData={feedbackDetailData} show={showCommentsForm} onHide={handleCloseCommentsForm} onClose={handleCloseCommentsForm} />}
            </div>

            <MuiTable
                tableTitle="Comments"
                tableData={data?.data?.data ?? []}
                tableColumns={commentColumns}
                handleShowEditForm={handleShowEditForm}
                handleDelete={(e, item_id) => handleDelete(e, item_id)}
                showEdit={activeUser?.permissions.includes("update summarised feedback comments")}
                showDelete={activeUser?.permissions.includes("delete summarised feedback comments")}
                loading={isLoading || status === "loading" || deleteMutationIsLoading}
                //
                //
                hideRowEdit={(rowData) => (rowData?.created_by?.id !== activeUser?.id ? true : false)}
                hideRowDelete={(rowData) => (rowData?.created_by?.id !== activeUser?.id ? true : false)}
                // //
                // handleViewPage={(rowData) => {
                //     navigate("product-category-brand", { state: { productCategoryBrandData: rowData } });
                // }}
                // showViewPage={true}
                // hideRowViewPage={false}
                //
                exportButton={true}
                pdfExportTitle="Feedback Comments"
                csvExportTitle="Feedback Comments"
            />

            {selectedItem && <SingleCommentsEditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}
        </div>
    );
}

export default Comments;
