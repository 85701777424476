import React from "react";
import moment from "moment";

//
import useAuthContext from "../../../context/AuthContext";

import FeedbackBySectorBarChart from "./charts/FeedbackBySectorBarChart"; // Import Bar Chart
import FeedbackByRegionPieChart from "./charts/FeedbackByRegionPieChart"; // Import Pie Chart
import FeedbackByLocationScopeDoughnutChart from "./charts/FeedbackByLocationScopeDoughnutChart";
import FeedbackByRegionTable from "./tables/FeedbackByRegionTable";
import FeedbackBySectorTable from "./tables/FeedbackBySectorTable";
import FeedbackByLocationScopeTable from "./tables/FeedbackByLocationScopeTable";

const MuiTablePdfTemplate = ({ title = "", columns, data, tableData }) => {
    console.log("🚀 ~ MuiTablePdfTemplate ~ data:", data);
    console.log("🚀 ~ MuiTablePdfTemplate ~ columns:", columns);

    //
    const { getUserQuery } = useAuthContext();

    const loggedInUserDetails = getUserQuery?.data?.data;

    const styles = {
        container: {
            padding: "20px",
            fontFamily: "Arial, sans-serif",
            fontSize: "12px",
        },
        header: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
            flexDirection: "column",
            width: "100%",
        },
        logo: {
            height: "50px",
        },
        title: {
            fontSize: "18px",
            fontWeight: "bold",
        },
        // paragraphContainer: {
        //     marginTop: "20px",
        // },
        // paragraph: {
        //     marginBottom: "10px",
        //     lineHeight: "1.6",
        // },
        paragraphContainer: {
            padding: "20px",
            fontFamily: "Arial, sans-serif",
            fontSize: "12px",
            color: "#333",
        },
        sectorGroup: {
            marginBottom: "20px",
            borderBottom: "1px solid #ddd",
            paddingBottom: "10px",
        },
        sectorTitle: {
            fontSize: "14px",
            fontWeight: "bold",
            color: "#007BFF",
            marginBottom: "10px",
        },
        paragraph: {
            margin: "5px 0",
            lineHeight: "1.5",
            color: "#555",
        },
        noDataMessage: {
            fontSize: "14px",
            color: "#888",
            textAlign: "center",
            marginTop: "20px",
        },
        footer: {
            marginTop: "20px",
            textAlign: "center",
            fontSize: "10px",
            color: "#666",
        },
        tablesContainer: {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginTop: "20px",
            gap: "20px",
        },
        table: {
            width: "100%", // Adjust width for responsive design
        },

        chartsContainer: {
            display: "flex",
            // flexDirection: "column",
            marginTop: "20px",
            gap: "20px",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
        },
        statsContainer: {
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            fontSize: "14px",
            fontWeight: "bold",
            borderBottom: "0.2px solid #ddd",
            padding: "10px",
            borderRadius: "5px",
        },
        chart: {
            display: "flex",
            // flexDirection: "column",
            gap: "1rem",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            width: "500px", // Adjust width for responsive design
        },
    };

    // Helper function to dynamically access nested properties
    function getFlatPathValue(obj, path) {
        return obj[path];
    }

    // Helper function to format date values
    function formatValue(value) {
        if (!value) return "";
        // Check if the value is a valid date
        if (moment(value, moment.ISO_8601, true).isValid()) {
            return moment(value).format("MMMM Do YYYY, h:mm a"); // Customize the format as needed
        }
        return value;
    }

    // Calculate statistics
    const totalFeedback = data.length;
    // Use a Set to find unique region names, accessing the "region.name" key
    const uniqueRegions = new Set(
        data.map((row) => row["region.name"]).filter((name) => name) // Filter out undefined or null
    ).size;

    // Use a Set to find unique sector names, accessing the "sector.name" key
    const uniqueSectors = new Set(
        data.map((row) => row["sector.name"]).filter((name) => name) // Filter out undefined or null
    ).size;

    return (
        <table id="print-content" style={styles.container}>
            <thead>
                <tr>
                    <td>
                        <div className="header-space">&nbsp;</div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div className="content">
                            {/* Statistics Section */}
                            <div style={styles.statsContainer}>
                                <div>Total Feedback: {totalFeedback}</div>
                                <div>Number of Regions: {uniqueRegions}</div>
                                <div>Number of Sectors: {uniqueSectors}</div>
                            </div>
                            {/* Tables Section */}
                            <div style={styles.tablesContainer}>
                                <div style={styles.table}>
                                    <FeedbackByLocationScopeTable data={data} />
                                </div>
                                <div style={styles.table}>
                                    <FeedbackByRegionTable data={data} />
                                </div>
                                <div style={styles.table}>
                                    <FeedbackBySectorTable data={data} />
                                </div>
                            </div>
                            {/* Paragraph Section or No Data Message */}
                            {/* <div style={styles.paragraphContainer}>
                                {data.length > 0 ? (
                                    data.map((row, rowIndex) => (
                                        <p key={rowIndex} style={styles.paragraph}>
                                            {columns
                                                .map((col) => {
                                                    const value = getFlatPathValue(row, col.field); // Safely access nested property
                                                    return value ? `${col.title}: ${formatValue(value)}.` : "";
                                                })
                                                .join(" ")}
                                        </p>
                                    ))
                                ) : (
                                    <div style={styles.noDataMessage}>No data available</div>
                                )}
                            </div> */}
                            {/* Paragraph Section or No Data Message */}
                            <div style={styles.paragraphContainer}>
                                {data.length > 0 ? (
                                    Object.entries(
                                        data.reduce((acc, row) => {
                                            const sectorName = row["sector.name"] || "Uncategorized"; // Group by sector.name or fallback
                                            acc[sectorName] = acc[sectorName] || [];
                                            acc[sectorName].push(row);
                                            return acc;
                                        }, {})
                                    ).map(([sectorName, rows], sectorIndex) => (
                                        <div key={sectorIndex} style={styles.sectorGroup}>
                                            {/* Sector Title */}
                                            <h4 style={styles.sectorTitle}>{sectorName}</h4>

                                            {/* Rows under this sector */}
                                            {rows.map((row, rowIndex) => (
                                                <p key={rowIndex} style={styles.paragraph}>
                                                    {columns
                                                        .map((col) => {
                                                            const value = getFlatPathValue(row, col.field); // Safely access nested property
                                                            return value ? `${col.title}: ${formatValue(value)}.` : "";
                                                        })
                                                        .join(" ")}
                                                </p>
                                            ))}
                                        </div>
                                    ))
                                ) : (
                                    <div style={styles.noDataMessage}>No data available</div>
                                )}
                            </div>
                            {/* Charts Section */}
                            <div style={styles.chartsContainer}>
                                <div style={styles.chart}>
                                    <FeedbackByLocationScopeDoughnutChart data={data} />
                                </div>
                                <div style={styles.chart}>
                                    <FeedbackByRegionPieChart data={data} />
                                </div>
                                <div style={styles.chart}>
                                    <FeedbackBySectorBarChart data={data} />
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <div className="footer-space">&nbsp;</div>
                    </td>
                </tr>
            </tfoot>
            <div className="header" style={styles.header}>
                <img src="assets/oag_photos/uganda_oag.png" alt="Logo" style={{ height: "50px" }} />
                <span style={styles.title}>{title}</span>
            </div>
            <div className="footer" style={styles.footer}>
                <div>
                    Generated by {loggedInUserDetails?.name} on {moment().format("dddd, MMMM Do YYYY, h:mm:ss A")}
                </div>
                <div>© {new Date().getFullYear()} OAG Citizen Feedback Platform</div>
            </div>
        </table>
    );
};

export default MuiTablePdfTemplate;
