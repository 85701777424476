import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

//
import AuditAreasRowForm from "./widgets/AuditAreasRowForm";
import useAuthContext from "../../../context/AuthContext.jsx";

//
import { postToUpdateSummarisedFeedbackSatisAuditAreas } from "../../../services/feedback/summarised-feedback-service";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

import moment from "moment";

//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

function AuditAreas({ feedbackDetailData, ...props }) {
    const { getUserQuery } = useAuthContext();
    const activeUser = getUserQuery?.data?.data;

    const [showDialog, setShowDialog] = useState(false);

    const handleUpdateClick = () => {
        setShowDialog(true);
    };

    const queryClient = useQueryClient();

    const [creactMutationIsLoading, setCreactMutationIsLoading] = useState(false);
    const creactMutation = useMutation({
        mutationFn: postToUpdateSummarisedFeedbackSatisAuditAreas,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["summarised_feedback", "by_id", feedbackDetailData?.id] });
            // queryClient.invalidateQueries({ queryKey: ["summarised_feedback"] });
            queryClient.invalidateQueries({ queryKey: ["feedback-reports", "by_id"] });
            toast.success("Updated Successfully");
            setCreactMutationIsLoading(false);
            setShowDialog(false);
        },
    });
    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(creactMutation?.error, setCreactMutationIsLoading);

    const handleSubmit = async (data) => {
        setCreactMutationIsLoading(true);

        creactMutation.mutate({ ...data, summarised_feedback_id: feedbackDetailData?.id });
    };

    return (
        <div style={{ minWidth: "70vw" }}>
            {activeUser?.permissions.includes("update summarised feedback audit areas") && (
                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    <Button label="Update" icon="pi pi-pencil" onClick={() => handleUpdateClick()} />
                    {/* kkk */}
                </div>
            )}

            <DataTable value={feedbackDetailData?.audit_areas ?? []} responsiveLayout="scroll">
                {/* <Column field="id" header="ID"></Column> */}
                <Column field="name" header="Name"></Column>
                <Column field="description" header="Description"></Column>
            </DataTable>

            <Dialog visible={showDialog} onHide={() => setShowDialog(false)} header="Update Audit Areas" maximizable style={{ minWidth: "50vw" }}>
                <AuditAreasRowForm handleSubmit={handleSubmit} initialData={{ audit_areas: feedbackDetailData?.audit_areas }} />

                {creactMutationIsLoading && (
                    <center>
                        <ProgressSpinner
                            style={{
                                width: "50px",
                                height: "50px",
                                borderWidth: "8px", // Border thickness
                                borderColor: "blue", // Border color
                                animationDuration: "1s",
                            }}
                            strokeWidth="8"
                            animationDuration="1s"
                        />
                    </center>
                )}
            </Dialog>
        </div>
    );
}

export default AuditAreas;
