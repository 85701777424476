import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const useHandleMutationError = (error, setIsLoading) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();

    // Memoize the error object
    const memoizedError = useMemo(() => error, [error]);

    // Log the error and memoizedError inside useEffect to confirm it triggers
    console.log("🚀 ~ useHandleMutationError ~ error:", error);

    // Utility function to check online status
    const checkInternetConnection = () => {
        if (typeof navigator !== "undefined" && "onLine" in navigator) {
            return navigator.onLine;
        }
        // Assume online if unsupported
        return true;
    };

    useEffect(() => {
        if (memoizedError) {
            setIsLoading(false);
            const errorMessage = memoizedError?.response?.data?.message;

            // Check if the user is offline
            const isOnline = checkInternetConnection();

            if (!isOnline) {
                toast.warning("You are offline. Please check your internet connection.");
                return;
            }

            if (errorMessage) {
                if (errorMessage === "Unauthenticated.") {
                    toast.warning("Session expired. Please log in again.");
                    if (!["/login", "/sign-up", "/reset-password"].includes(location.pathname)) {
                        queryClient.resetQueries({ queryKey: ["logged-in-user"] });
                        queryClient.removeQueries({ queryKey: ["logged-in-user"] });
                        queryClient.clear();
                        navigate("/login");
                    }
                } else {
                    const additionalError = memoizedError?.response?.data?.error ? `Error: ${memoizedError.response.data.error}` : "";
                    toast.error(`${errorMessage} ${additionalError}`);
                }
            } else if (!memoizedError?.response) {
                toast.warning("Unable to connect to the server. Please check your internet connection or contact the administrator.");
            } else {
                toast.error("An error occurred. Please contact admin.");
            }
        }
    }, [memoizedError]);
};

export default useHandleMutationError;
