import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Card } from "primereact/card";
import { Tooltip } from "primereact/tooltip";
import { getAllJobStats } from "../../../services/jobs/jobs-service";
import useHandleQueryError from "../../../hooks/useHandleQueryError";

const StatCards = () => {
    const { data, isLoading, isError, error } = useQuery({
        queryKey: ["job-stats"],
        queryFn: () => getAllJobStats(),
    });

    // Handle query errors
    useHandleQueryError(isError, error);

    const stats = data?.data?.data;

    return (
        <div className="grid">
            {isLoading ? (
                <div className="col-12 text-center p-3 border-round-sm bg-warning font-bold">Loading stats...</div>
            ) : isError ? (
                <div className="col-12 text-center p-3 border-round-sm bg-danger font-bold">Error loading stats!</div>
            ) : (
                stats?.map((stat, index) => (
                    <div key={index} className="col-12 md:col-6 lg:col-3">
                        <Card
                            title={stat.name}
                            style={{ textAlign: "center" }}
                            header={
                                <span
                                    className={`pi ${stat.icon}`}
                                    style={{
                                        fontSize: "2rem",
                                        color: "#007bff",
                                    }}
                                    data-pr-tooltip={stat.description}
                                ></span>
                            }
                        >
                            <strong>{stat.value}</strong>
                        </Card>
                        <Tooltip target={`.pi.${stat.icon}`} />
                    </div>
                ))
            )}
        </div>
    );
};

export default StatCards;
