import React, { useState } from "react";
import { Panel } from "primereact/panel";

//
import FeedbackStatusPieChart from "./stat-charts/FeedbackStatusPieChart";
import ServicesFbByTopicBarChart from "./stat-charts/ServicesFbByTopicBarChart";

const ReportStatisticsPage = () => {
    const [isPieChartVisible, setPieChartVisible] = useState(true);
    const [isBarChartVisible, setBarChartVisible] = useState(true);

    return (
        <div className="p-grid p-dir-col">
            <Panel header="Status Report" toggleable collapsed={!isPieChartVisible} onToggle={(e) => setPieChartVisible(!isPieChartVisible)}>
                <FeedbackStatusPieChart />
            </Panel>
            <Panel header="Report Allocation Perfomance" toggleable collapsed={!isBarChartVisible} onToggle={(e) => setBarChartVisible(!isBarChartVisible)}>
                <ServicesFbByTopicBarChart />
            </Panel>
        </div>
    );
};

export default ReportStatisticsPage;
