import React, { useState } from "react";
import { Form, Field } from "react-final-form";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { classNames } from "primereact/utils";

import setFieldTouched from "final-form-set-field-touched";
//
import { toast } from "react-toastify";

import useHandleQueryError from "../../../../hooks/useHandleQueryError";

//
import { getAllAuditAreas } from "../../../../services/audit-areas/audit-areas-service.js";
import { MultiSelect } from "primereact/multiselect";

function AuditAreasRowForm({ handleSubmit, initialData, ...props }) {
    console.log("🚀df ~ AuditAreasRowForm ~ initialData:", initialData);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    const [selectedAuditAreas, setSelectedAuditAreas] = useState(initialData?.audit_areas ?? []);
    const getAllAuditAreasQuery = useQuery({
        queryKey: ["audit-areas"],
        queryFn: () => getAllAuditAreas(),
    });

    // Use the custom hook to handle errors for getAllAuditAreasQuery
    useHandleQueryError(getAllAuditAreasQuery?.isError, getAllAuditAreasQuery?.error);

    //
    const validate = (values) => {
        const errors = {};

        if (!values.audit_areas || values.audit_areas.length === 0) errors.audit_areas = "At least one audit area is required";

        return errors;
    };

    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            // No validation errors
            let finalData = { ...data };
            setPendingData(finalData);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please first fill in all required fields");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <Field name="audit_areas">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="audit_areas">Audit Areas</label>
                                        <MultiSelect
                                            {...input}
                                            id="audit_areas"
                                            value={selectedAuditAreas} // Sync with local state
                                            options={getAllAuditAreasQuery.data?.data?.data || []}
                                            optionLabel="name"
                                            placeholder="Select Audit Areas"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            disabled={getAllAuditAreasQuery?.isLoading}
                                            dataKey="id"
                                            loading={getAllAuditAreasQuery?.isLoading}
                                            emptyFilterMessage="No audit areas found"
                                            filter // Enable search functionality
                                            filterBy="name" // Search by 'name' field
                                            onChange={(e) => {
                                                setSelectedAuditAreas(e.value); // Update local state
                                                input.onChange(e.value); // Update Final Form state
                                            }}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    maximizable
                    style={{ minWidth: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default AuditAreasRowForm;
