import React from "react";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import moment from "moment";
import "primeicons/primeicons.css"; // Ensure PrimeIcons are imported for use

function SystemLogDetails({ log, showModal, handleCloseModal }) {
    return (
        <Dialog header="System Log Details" visible={showModal} onHide={handleCloseModal} maximizable modal>
            <div style={{ margin: "1rem" }}>
                <Panel header="Log Information" toggleable>
                    <div className="p-grid" style={{ padding: "1rem" }}>
                        <div className="p-col-12 p-md-6">
                            <strong>Log Name:</strong> {log?.log_name}
                        </div>
                        <div className="p-col-12 p-md-6" style={{ wordWrap: "break-word", whiteSpace: "normal", maxWidth: "90vw" }}>
                            <p>
                                <strong>Description:</strong> {log?.description}
                            </p>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>Ip Address:</strong> {log?.properties?.ip || "System"}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>Country:</strong> {log?.properties?.country || "N/A"}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>City:</strong> {log?.properties?.city || "N/A"}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>Region:</strong> {log?.properties?.region || "N/A"}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>Latitude:</strong> {log?.properties?.latitude || "N/A"}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>Longitude:</strong> {log?.properties?.longitude || "N/A"}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>Timezone:</strong> {log?.properties?.timezone || "N/A"}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>User Agent:</strong> {log?.properties?.user_agent || "N/A"}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>Device:</strong> {log?.properties?.device || "N/A"}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>Platform:</strong> {log?.properties?.platform || "N/A"}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>Platform Version:</strong> {log?.properties?.platform_version || "N/A"}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>Browser:</strong> {log?.properties?.browser || "N/A"}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>Browser Version:</strong> {log?.properties?.browser_version || "N/A"}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>Causer:</strong> {log?.properties?.created_by || "System"}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>Causer Email:</strong> {log?.properties?.created_by_email || "N/A"}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>Date:</strong> {moment(log?.created_at).format("YYYY-MM-DD")}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>Time:</strong> {moment(log?.created_at).format("HH:mm:ss")}
                        </div>
                    </div>
                </Panel>
            </div>
        </Dialog>
    );
}

export default SystemLogDetails;
