import React, { useState, useEffect } from "react";

import { getAllFeedbacks, getFeedbackById, postFeedback, postToUpdateFeedbackStatus, updateFeedback, deleteFeedbackById } from "../../services/feedback/feedback-service";

import RowForm from "./widgets/RowForm";
import { useQueryClient, useMutation } from "@tanstack/react-query";

import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

//
import useHandleMutationError from "../../hooks/useHandleMutationError.js";

function EditForm(props) {
    const queryClient = useQueryClient();

    const [editMutationIsLoading, setEditMutationIsLoading] = useState(false);
    const editMutation = useMutation({
        mutationFn: (variables) => updateFeedback(props?.rowData?.id, variables),
        onSuccess: () => {
            setEditMutationIsLoading(false);
            props.onClose();
            toast.success("Edited Successfully");
            queryClient.invalidateQueries({ queryKey: ["feedback"] });
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(editMutation?.error, setEditMutationIsLoading);

    //     editMutation.mutate(data);
    // };

    const handleSubmit = async (data) => {
        setEditMutationIsLoading(true);
        console.log("Data we are submitting: ", data);

        const formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("name", data.name);
        formData.append("is_anonymous", data?.is_anonymous);
        formData.append("sector_id", data?.sector?.id);
        formData.append("message", data?.message);
        formData.append("status", data?.status);
        formData.append("feedback_submit_status", data?.feedback_submit_status);
        formData.append("latitude", data?.latitude);
        formData.append("longitude", data?.longitude);
        formData.append("accuracy", data?.accuracy);
        formData.append("altitude", data?.altitude);
        formData.append("speed_accuracy", data?.speed_accuracy);
        formData.append("location_scope", data?.location_scope);

        switch (data?.location_scope) {
            case "International":
                formData.append("country_id", data?.country?.id ?? "");
                formData.append("region_id", data?.region?.id ?? "");
                formData.append("district_id", data?.district?.id ?? "");
                formData.append("county_id", data?.county?.id ?? "");
                formData.append("subcounty_id", data?.subcounty?.id ?? "");
                formData.append("parish_id", data?.parish?.id ?? "");
                formData.append("village_id", data?.village?.id ?? "");

                break;

            case "Central Government":
                formData.append("country_id", data?.country?.id ?? "");
                formData.append("region_id", data?.region?.id ?? "");
                formData.append("district_id", data?.district?.id ?? "");
                formData.append("county_id", data?.county?.id ?? "");
                formData.append("subcounty_id", data?.subcounty?.id ?? "");
                formData.append("parish_id", data?.parish?.id ?? "");
                formData.append("village_id", data?.village?.id ?? "");

                break;

            case "Local Government":
                formData.append("country_id", data?.country?.id ?? "");
                formData.append("region_id", data?.region?.id ?? "");
                formData.append("district_id", data?.district?.id ?? "");
                formData.append("county_id", data?.county?.id ?? "");
                formData.append("subcounty_id", data?.subcounty?.id ?? "");
                formData.append("parish_id", data?.parish?.id ?? "");
                formData.append("village_id", data?.village?.id ?? "");

                break;

            default:
                // Handle unknown location scope if necessary
                console.warn("Unknown location scope, no location data appended.");
                break;
        }

        // // Append images with captions
        // data.uploadedImages.forEach((item) => {
        //     // Assuming 'item' is the File object or you have access to the file object here
        //     const file = item.file; // Direct reference if 'item' is the File
        //     formData.append(`images[]`, file);
        //     formData.append(`imageCaptions[${item.id}]`, data.imageCaptions[item.id]); // Append image caption using the custom ID
        // });

        // Append attachments and dynamically determine file type
        data.uploadedImages.forEach((item, index) => {
            const file = item.file; // Assuming this is the File object

            // Dynamically get the file type or fallback to "image"
            const fileType = file.type || "image";

            // Append the file under 'file_path'
            formData.append(`attachments[${index}][file_path]`, file);

            // Append the type
            formData.append(`attachments[${index}][type]`, fileType);

            // Append image captions if available
            if (data.imageCaptions && data.imageCaptions[item.id]) {
                formData.append(`imageCaptions[${item.id}]`, data.imageCaptions[item.id]);
            }
        });

        editMutation.mutate(formData);
    };
    return (
        <Dialog header="Feedback Form" visible={props.show} maximizable style={{ minWidth: "50vw" }} onHide={() => props.onHide()}>
            {/* <h3>Programs Edit Form</h3> */}
            <p>Edit Data Below</p>
            <RowForm initialData={props.rowData} handleSubmit={handleSubmit} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}

            {editMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
        </Dialog>
    );
}

export default EditForm;
