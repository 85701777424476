import React, { useState, useContext, useMemo, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";
import drilldown from "highcharts/modules/drilldown";
import HC_accessibility from "highcharts/modules/accessibility";

//
import { getSumFbByDirBarChartStats } from "../../../services/feedback-reports/feedback-report-stats-service";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";

import { Accordion, AccordionTab } from "primereact/accordion";

//lotties
import MaterialUiLoaderLottie from "../../../assets/lotties/oag-lotties/material-ui-loading-lottie.json";
// import RobotProcessorLottie from "../../../assets/lotties/oag-lotties/robot-processor-lottie.json";
import BrownFilesProcessingLottie from "../../../assets/lotties/oag-lotties/brown-files-processing-lottie.json";
// import ComputerInFourCirclesLottie from "../../../assets/lotties/oag-lotties/computer-in-four-circles-lottie.json";
// import ServerProcessingLottie from "../../../assets/lotties/oag-lotties/server-processing-lottie.json";
// import DarkBluePeopleOnServerLottie from "../../../assets/lotties/oag-lotties/dark-blue-people-on-server-lottie.json";
// import BoyGirlPlottingGraphLottie from "../../../assets/lotties/oag-lotties/boy-girl-plotting-graph-lottie.json";
// import BoyBoyScanningFilesLottie from "../../../assets/lotties/oag-lotties/boy-boy-scanning-files-lottie.json";
// import GirlGirlPlottingGraphsLottie from "../../../assets/lotties/oag-lotties/girl-girl-plotting-graphs-lottie.json";
import SnailErrorLottie from "../../../assets/lotties/oag-lotties/snail-error-lottie.json";
import Lottie from "lottie-react";

import { Button } from "primereact/button";
import moment from "moment";

import { Tooltip } from "@mui/material";

//
import useHandleQueryError from "../../../hooks/useHandleQueryError";

//
import SummarisedFiltersForm from "./SummarisedFiltersForm";

import { Dropdown } from "primereact/dropdown";

// Initialize the required Highcharts modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);
HC_accessibility(Highcharts);

// ================= vertical chart ================
const getChartOptions = (data, dataFilters) => {
    // Check if data is valid
    if (!data || !Array.isArray(data)) {
        return null;
    }

    // Helper function to format arrays into a string of names or labels
    function formatArray(arr, key) {
        if (!Array.isArray(arr) || arr.length === 0) return "";
        return arr.map((item) => item[key]).join(", ");
    }

    // Helper function to format data filters for chart title
    function formatFilters(dataFilters) {
        // Format the dates
        const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : null;
        const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : null;

        const finacialYear = dataFilters?.financial_year?.name || null;
        const quarter = dataFilters?.quarter?.name || null;

        // Extract and format the filters
        const statuses = formatArray(dataFilters?.statuses, "label");
        const orderBy = dataFilters?.orderBy?.name || null;
        const dataLimit = dataFilters?.dataLimit?.name || null;
        const sectors = formatArray(dataFilters?.sectors, "name");
        const location_scope = dataFilters?.location_scope;
        const countries = formatArray(dataFilters?.countries, "name");
        const regions = formatArray(dataFilters?.regions, "name");
        const districts = formatArray(dataFilters?.districts, "name");
        const counties = formatArray(dataFilters?.counties, "name");
        const subcounties = formatArray(dataFilters?.subcounties, "name");
        const parishes = formatArray(dataFilters?.parishes, "name");
        const villages = formatArray(dataFilters?.villages, "name");
        const auditAreas = formatArray(dataFilters?.auditAreas, "name");
        const auditTypes = formatArray(dataFilters?.auditTypes, "name");
        const directorates = formatArray(dataFilters?.directorates, "name");

        // Constructing the sentence for the chart title
        let sentence = "Filters: ";
        if (finacialYear) sentence += `Finacial Year: ${finacialYear}. `;
        if (quarter) sentence += `Quarter: ${quarter}. `;
        if (startDate) sentence += `Start Date: ${startDate}. `;
        if (endDate) sentence += `End Date: ${endDate}. `;
        if (statuses) sentence += `Statuses: ${statuses}. `;
        if (sectors) sentence += `Sectors: ${sectors}. `;
        if (location_scope) sentence += `Location Scope: ${location_scope}. `;
        if (countries) sentence += `Countries: ${countries}. `;
        if (regions) sentence += `Regions: ${regions}. `;
        if (districts) sentence += `Districts: ${districts}. `;
        if (counties) sentence += `Counties: ${counties}. `;
        if (subcounties) sentence += `Subcounties: ${subcounties}. `;
        if (parishes) sentence += `Parishes: ${parishes}. `;
        if (villages) sentence += `Villages: ${villages}. `;
        if (auditAreas) sentence += `Audit Areas: ${auditAreas}. `;
        if (auditTypes) sentence += `Audit Types: ${auditTypes}. `;
        if (directorates) sentence += `Directorates: ${directorates}. `;
        if (orderBy) sentence += `Order By: ${orderBy}. `;
        if (dataLimit) sentence += `Data Limit: ${dataLimit}. `;

        return sentence.trim();
    }

    // Format the chart title based on the applied filters
    let chartTitle = formatFilters(dataFilters);

    // Function to generate a random color in hexadecimal format
    const generateRandomColor = () => {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    // Function to generate dynamic colors for each slice
    const generateDynamicColors = (data = []) => {
        return data.map(() => generateRandomColor()); // Generate random color for each data item
    };

    // Define the Highcharts options for a vertical column chart
    const options = {
        chart: {
            type: "bar", // Horizontal bar chart
        },
        title: {
            text: `Summarised Feedback By ${dataFilters?.selectedChart?.title} <br/> ${chartTitle}`,
        },
        tooltip: {
            pointFormat: `<b>${dataFilters?.selectedChart?.title}:</b> {point.name}<br/><b>Count:</b> {point.y}<br/><b>Percentage:</b> {point.percentage:.1f}%`, // Show count and percentage in tooltip
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true, // Enable data labels
                    format: "<b>{point.name}</b><br/>Count: {point.y}<br/>Percentage: {point.percentage:.1f}%", // Show count and percentage in data labels
                },
            },
        },
        legend: {
            enabled: false, // Hide the legend
        },
        credits: {
            enabled: false, // Disable credits
        },
        noData: {
            style: {
                fontWeight: "bold",
                fontSize: "16px",
                color: "#303030",
            },
            position: {
                align: "center",
                verticalAlign: "middle",
            },
            text: "No data to display",
        },
        colors: generateDynamicColors(data), // Assign dynamic colors to each bar
        xAxis: {
            categories: data.map((item) => item.name), // Display directorate names on the Y-axis
            title: {
                text: `${dataFilters?.selectedChart?.xAxis}`, // Label for Y-axis
            },
        },
        series: [
            {
                name: "Feedback Count",
                data: data.map((item) => ({
                    name: item.name, // Directorate name
                    y: item.value, // Feedback count
                    percentage: (item.value / data.reduce((sum, current) => sum + current.value, 0)) * 100, // Calculate percentage for each item
                })),
                colorByPoint: true, // Ensure each bar gets a different color
            },
        ],
    };

    return options;
};

const SumFbByDirectorateBarChart = () => {
    const [selectedChart, setSelectedChart] = useState({ label: "Directorates Perfomance", code: "directorate-perfomance", title: "Directorate", xAxis: "Directorates" });
    console.log("🚀 ~ SumFbByDirectorateBarChart ~ selectedChart:", selectedChart);

    const chartOptions = [
        { label: "Directorates Perfomance", code: "directorate-perfomance", title: "Directorate", xAxis: "Directorates" },
        { label: "Audit Areas Perfomance", code: "audit-areas-perfomance", title: "Audit Area", xAxis: "Audit Areas" },
        { label: "Audit Types Perfomance", code: "audit-types-perfomance", title: "Audit Type", xAxis: "Audit Types" },
        { label: "Location Scope Performance", code: "location-scope", title: "Location Scope", xAxis: "Location Scopes" },
        { label: "District Performance", code: "district", title: "District", xAxis: "Districts" },
    ];

    // filters
    const [showFilters, setShowFilters] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({
        startDate: moment().subtract(30, "days").toDate(),
        endDate: moment().toDate(),
    });

    console.log("🚀 ~ SumFbByDirectorateBarChart ~ selectedFilters:", selectedFilters);

    // Function to toggle the dialog visibility
    const toggleFilterDialog = () => {
        setShowFilters((prevShow) => !prevShow);
    };

    const handleSubmitFilters = (data) => {
        setSelectedFilters(data);
    };

    //==================== end chart filters ===================

    // Products
    const getSumFbByDirBarChartStatsQuery = useQuery({
        disable: false,
        // queryKey: ["ProductBarChartStatistics", ...Object.values(selectedFilters)],
        queryKey: ["getSumFbByDirBarChartStats", selectedFilters, selectedChart],
        queryFn: () =>
            getSumFbByDirBarChartStats({
                selectedChart,
                ...selectedFilters,
                startDate: selectedFilters?.startDate ? moment(selectedFilters?.startDate).format("YYYY-MM-DD HH:mm:ss") : null,
                endDate: selectedFilters?.endDate ? moment(selectedFilters?.endDate).format("YYYY-MM-DD HH:mm:ss") : null,
            }),
    });

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(getSumFbByDirBarChartStatsQuery?.isError, getSumFbByDirBarChartStatsQuery?.error);

    console.log("data for getSumFbByDirBarChartStatsQuery ?.data?.data is:", getSumFbByDirBarChartStatsQuery?.data?.data);

    const seriesData = getSumFbByDirBarChartStatsQuery?.data?.data?.data;

    //=============== handle displaying filters on Chart end ======================

    return (
        <>
            <Dropdown value={selectedChart} options={chartOptions} optionLabel="label" onChange={(e) => setSelectedChart(e.value)} placeholder="Select a chart" className="p-mb-4" />
            {getSumFbByDirBarChartStatsQuery?.isLoading ? (
                <>
                    <div className="col-12">
                        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ maxWidth: "400px" }}>
                                <Lottie animationData={BrownFilesProcessingLottie} style={{ height: "300px" }} loop={true} autoplay={true} />
                                <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                            </div>
                        </div>
                        {/* <ProgressBar mode="indeterminate" style={{ height: "6px" }} /> */}
                    </div>
                </>
            ) : getSumFbByDirBarChartStatsQuery?.isError ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ maxWidth: "400px" }}>
                        <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                    </div>
                </div>
            ) : (
                <>
                    <div className="col-12 text-right">
                        <Tooltip title="Filter">
                            <Button icon="pi pi-filter" onClick={toggleFilterDialog} />
                        </Tooltip>

                        {/* Filters dialog */}
                        <SummarisedFiltersForm selectedFilters={selectedFilters} show={showFilters} onHide={toggleFilterDialog} handleSubmit={handleSubmitFilters} />
                    </div>
                    <div style={{ height: "400px" }}>
                        <HighchartsReact highcharts={Highcharts} options={getChartOptions(seriesData, getSumFbByDirBarChartStatsQuery?.data?.data?.requestParams)} immutable={true} />
                    </div>
                </>
            )}
        </>
    );
};

export default SumFbByDirectorateBarChart;
