import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";

import { getAllUsers, getUserById, getApproverRoles, createUser, updateUser, deleteUserById, getAssignableRoles } from "../../services/auth/user-service";

import RowForm from "./widgets/RowForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

//
import useHandleMutationError from "../../hooks/useHandleMutationError.js";

function CreateForm({ loggedInUserData, ...props }) {
    const queryClient = useQueryClient();

    const [creactMutationIsLoading, setCreactMutationIsLoading] = useState(false);
    const creactMutation = useMutation({
        mutationFn: createUser,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["users"] });
            toast.success("created Successfully");
            setCreactMutationIsLoading(false);
            props.onClose();
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(creactMutation?.error, setCreactMutationIsLoading);

    const handleSubmit = async (data) => {
        // event.preventDefault();
        setCreactMutationIsLoading(true);

        let finalData = {
            ...data,
            directorate_id: data?.directorate?.id,
            directorate_office_id: data?.directorate_office?.id,
            region_id: data?.region?.id,
            district_id: data?.district?.id,
            county_id: data?.county?.id,
            subcounty_id: data?.subcounty?.id,
            parish_id: data?.parish?.id,
            village_id: data?.village?.id,
        };

        console.log("data we are submitting while creating a user : ", finalData);
        creactMutation.mutate(finalData);
    };

    return (
        <Dialog header="Users Form" visible={props.show} style={{ width: "60vw" }} onHide={() => props.onHide()} maximizable>
            <p>Fill in the form below</p>
            <RowForm loggedInUserData={loggedInUserData} handleSubmit={handleSubmit} project_id={props?.projectId} selectedParentItem={props?.selectedParentItem} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}
            {creactMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
            {/* <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Program Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
        </Modal> */}
        </Dialog>
    );
}

export default CreateForm;
