import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import { MapContainer, TileLayer, Marker, useMapEvents, LayersControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import MapIcon from "@mui/icons-material/Map";

// Fix marker icon issue in Leaflet with Webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const parseSafeFloat = (value) => {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? "" : parsedValue;
};

const LocationPickerMap = ({ setLocationPickerMapSelectedLatLng, locationPickerMapSelectedLatLng }) => {
    const { BaseLayer } = LayersControl;
    const [visible, setVisible] = useState(false); // Controls map dialog visibility
    const [localLatLng, setLocalLatLng] = useState(locationPickerMapSelectedLatLng);
    const [confirmDialogVisible, setConfirmDialogVisible] = useState(false); // Controls confirmation dialog
    const [warningMessage, setWarningMessage] = useState(""); // Warning message for JSX display

    const MapClickHandler = () => {
        useMapEvents({
            click(e) {
                setLocalLatLng(e.latlng);
                setWarningMessage(""); // Clear warning when a location is selected
            },
        });
        return localLatLng ? <Marker position={{ lat: parseSafeFloat(localLatLng?.lat), lng: parseSafeFloat(localLatLng?.lng) }} /> : null;
    };

    const handleSetLocation = () => {
        if (!localLatLng) {
            const warning = "No location selected. Please select a location on the map.";
            setWarningMessage(warning);
            toast.warn(warning);
            return;
        }
        setConfirmDialogVisible(true); // Show confirmation dialog
    };

    const confirmSetLocation = () => {
        setLocationPickerMapSelectedLatLng(localLatLng);
        setConfirmDialogVisible(false); // Close confirmation dialog
        setVisible(false); // Close the map dialog
        toast.success("Location successfully set!");
    };

    return (
        <>
            {/* Warning Display */}
            {warningMessage && <div style={{ color: "red", marginBottom: "1rem", fontWeight: "bold" }}>{warningMessage}</div>}

            {/* Button to open the map */}
            <button type="button" className="p-button" onClick={() => setVisible(true)}>
                <MapIcon style={{ marginRight: "0.5rem" }} />
                Open Map
            </button>

            {/* Map Dialog */}
            <Dialog header="Select Location" visible={visible} onHide={() => setVisible(false)} style={{ minWidth: "60vw" }} maximizable>
                <MapContainer center={[parseSafeFloat(localLatLng?.lat) || 0, parseSafeFloat(localLatLng?.lng) || 0]} zoom={7} scrollWheelZoom={true} attributionControl={false} fullscreenControl={true} style={{ minHeight: "60vh", width: "100%" }}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors' />
                    <LayersControl>
                        <BaseLayer checked name="OpenStreetMap">
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                        </BaseLayer>
                        <BaseLayer name="Terrain View">
                            <TileLayer url="https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}" maxZoom={20} subdomains={["mt1", "mt2", "mt3"]} />
                        </BaseLayer>
                        <BaseLayer name="Satellite View">
                            <TileLayer url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" maxZoom={20} subdomains={["mt1", "mt2", "mt3"]} />
                        </BaseLayer>
                        <BaseLayer name="Hybrid View">
                            <TileLayer url="https://{s}.google.com/vt/lyrs=h&x={x}&y={y}&z={z}" maxZoom={20} subdomains={["mt1", "mt2", "mt3"]} />
                        </BaseLayer>
                    </LayersControl>
                    <MapClickHandler />
                    {localLatLng && (
                        <Marker
                            position={[parseSafeFloat(localLatLng?.lat), parseSafeFloat(localLatLng?.lng)]}
                            draggable={true}
                            eventHandlers={{
                                dragend: (event) => {
                                    const latlng = event.target.getLatLng();
                                    setLocalLatLng(latlng);
                                },
                            }}
                        />
                    )}
                </MapContainer>
                <div style={{ margin: "0.4rem", textAlign: "center" }}>
                    <button type="button" className="p-button" onClick={handleSetLocation}>
                        Set Location
                    </button>
                </div>
            </Dialog>

            {/* Confirmation Dialog */}
            <Dialog
                header="Confirmation"
                visible={confirmDialogVisible}
                onHide={() => setConfirmDialogVisible(false)}
                maximizable
                footer={
                    <>
                        <Button
                            label="Cancel"
                            icon="pi pi-times"
                            className="p-button-outlined p-button-danger"
                            onClick={() => {
                                setLocalLatLng(locationPickerMapSelectedLatLng);
                                setConfirmDialogVisible(false);
                            }}
                        />
                        <Button label="Confirm" icon="pi pi-check" className="p-button-success" onClick={confirmSetLocation} />
                    </>
                }
            >
                Are you sure you want to set this location?
            </Dialog>
        </>
    );
};

export default LocationPickerMap;
