import moment from "moment";
import React, { useState } from "react";
import { deleteVillageById, getAllVillage } from "../../../services/locations/village-service";
import VillageForm from "./VillageForm";
import EditForm from "./EditForm";
import MuiTable from "../../../components/general_components/MuiTable";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { Button } from "primereact/button";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import { Panel } from "primereact/panel";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import useHandleQueryError from "../../../hooks/useHandleQueryError";
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

function VillageList({ selectedParish }) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [selectedItem, setSelectedItem] = useState({ id: null });
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
    };

    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const getListOfVillages = useQuery({ queryKey: ["villages", selectedParish?.id], queryFn: () => (!selectedParish?.id ? getAllVillage() : getAllVillage({ parish_id: selectedParish?.id })) });

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(getListOfVillages?.isError, getListOfVillages?.error);

    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: deleteVillageById,
        onSuccess: (data) => {
            toast.success("Record Deleted Successfully");
            queryClient.invalidateQueries({ queryKey: ["villages"] });
            setDeleteMutationIsLoading(false);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(deleteMutation?.error, setDeleteMutationIsLoading);

    const handleDelete = (event, id) => {
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                setDeleteMutationIsLoading(true);
                deleteMutation.mutate(id);
            },
        });
    };

    let tableId = 0;
    const columns = [
        {
            title: "#",
            field: "name",
            width: "5%",
            render: (rowData) => {
                tableId = rowData.tableData.id;
                tableId++;
                return <div>{rowData.tableData.id}</div>;
            },
        },
        {
            title: "Village Name",
            field: "name",
        },
        {
            title: "Parish Name",
            field: "parish.name",
        },
        {
            title: "Date",
            field: "created_at",
            render: (rowData) => moment(rowData.created_at).format("lll"),
        },
    ];

    return (
        <div>
            <Panel header="Villages" style={{ marginBottom: "20px" }} toggleable>
                {selectedParish && (
                    <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end" }}>
                        <Button label="Add Village" onClick={() => setShowAddForm(true)} />
                        <VillageForm selectedParish={selectedParish} show={showAddForm} onHide={() => setShowAddForm(false)} onClose={() => setShowAddForm(false)} />
                    </div>
                )}
                <MuiTable
                    tableTitle="Villages"
                    tableData={getListOfVillages?.data?.data?.data ?? []}
                    tableColumns={columns}
                    handleShowEditForm={handleShowEditForm}
                    handleDelete={handleDelete}
                    showEdit={selectedParish ? true : false}
                    showDelete={selectedParish ? true : false}
                    // handleViewPage={(rowData) => {
                    //     navigate("village", { state: { villageData: rowData } });
                    // }}
                    // showViewPage={true}
                    // hideRowViewPage={false}
                    loading={loading || getListOfVillages.isLoading || deleteMutationIsLoading}
                    exportButton={true}
                    pdfExportTitle="Villages"
                    csvExportTitle="Villages"
                />
                {/* //village */}
                {/* <ConfirmDialog /> */}
                <EditForm selectedParish={selectedParish} villageData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />
            </Panel>
        </div>
    );
}

export default VillageList;
