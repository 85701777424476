import React, { lazy, Suspense, useState } from "react";
import { useRoutes, Navigate } from "react-router-dom";

//
import useAuthContext from "./context/AuthContext";

//==================== my car routes ====================
import NewUsersPage from "./views/users/UserPage";

import RegionPage from "./views/locations/regions/RegionPage.jsx";
import DistrictsPage from "./views/locations/districts/DistrictsPage.jsx";
import CountiesPage from "./views/locations/county/CountyPage";
import SubCountiesPage from "./views/locations/subcounty/SubcountyPage";
import ParishesPage from "./views/locations/parish/ParishPage";
import VillagesPage from "./views/locations/village/VillagePage";
import CountryPage from "./views/locations/country/CountryPage";

//
import PollsPage from "./views/polls/PollsPage";
import GroupsPage from "./views/groups/GroupsPage.jsx";
import GroupTypesPage from "./views/groupTypes/GroupTypesPage.jsx";
import QuestionsPage from "./views/poll-questions/QuestionsPage.jsx";
import ResponsesPage from "./views/poll-question-responses/ResponsesPage.jsx";
import NotificationsPage from "./views/notifications/NotificationsPage";
import FeedbacksPage from "./views/feedback/FeedbacksPage";
import SectorsPage from "./views/configurations/sectors/SectorsPage";
import DirectoratesPage from "./views/configurations/directorates/DirectoratesPage";
import DirectoratesViewPage from "./views/configurations/directorates/DirectoratesViewPage";

import ServicesFeedbacksPage from "./views/services-feedback/ServicesFeedbacksPage";
import TopicsPage from "./views/configurations/topics/TopicsPage.jsx";
import AuditAreasPage from "./views/configurations/audit-areas/AuditAreasPage";
import AuditTypesPage from "./views/configurations/audit-types/AuditTypesPage";

//
import DashboardPage from "./views/dashboard/dashboardPage";
import FaqsPage from "./views/faqs/FaqsPage";
import GroupsViewPage from "./views/groups/GroupsViewPage";

//
import NotFoundPage from "./components/not_found/NotFoundPage";

//
import UserManualPage from "./views/user_manual/UserManualPage";

//
import SystemLogsPage from "./views/users/system-logs/SystemLogsPage";

//
import FinancialYearsPage from "./views/configurations/finacial-years/FinancialYearsPage.js";
import QuartersPage from "./views/configurations/quarters/QuartersPage";

//
import FeedbackReportsPage from "./views/feedback-reports/FeedbackReportsPage";
//
import JobsPage from "./views/configurations/jobs/JobsPage.jsx";

// ============ Customm component routes ========================
const DashboardTwoPage = lazy(() => import("./components/Dashboard"));
const FormLayoutDemo = lazy(() => import("./components/FormLayoutDemo"));
const InputDemo = lazy(() => import("./components/InputDemo"));
const FloatLabelDemo = lazy(() => import("./components/FloatLabelDemo"));
const InvalidStateDemo = lazy(() => import("./components/InvalidStateDemo"));
const ButtonDemo = lazy(() => import("./components/ButtonDemo"));
const TableDemo = lazy(() => import("./components/TableDemo"));
const ListDemo = lazy(() => import("./components/ListDemo"));
const TreeDemo = lazy(() => import("./components/TreeDemo"));
const PanelDemo = lazy(() => import("./components/PanelDemo"));
const OverlayDemo = lazy(() => import("./components/OverlayDemo"));
const MediaDemo = lazy(() => import("./components/MediaDemo"));
const MenuDemo = lazy(() => import("./components/MenuDemo"));
const MessagesDemo = lazy(() => import("./components/MessagesDemo"));
const FileDemo = lazy(() => import("./components/FileDemo"));
const ChartDemo = lazy(() => import("./components/ChartDemo"));
const MiscDemo = lazy(() => import("./components/MiscDemo"));
const Crud = lazy(() => import("./pages/Crud"));
const EmptyPage = lazy(() => import("./pages/EmptyPage"));
const TimelineDemo = lazy(() => import("./pages/TimelineDemo"));

function AppRoutes() {
    //
    const { getUserQuery } = useAuthContext();
    const activeUser = getUserQuery?.data?.data;
    const adminRoutes = [
        {
            path: "/dashboard",
            name: "dashboard",
            element: <DashboardPage />, // Replace with the actual component
            layout: "/private",
        },
        {
            path: "/dashboard-two",
            name: "dashboard-two",
            element: <DashboardTwoPage />, // Replace with the actual component
            layout: "/private",
        },

        {
            path: "/formlayout",
            name: "formlayout",
            element: <FormLayoutDemo />,
            layout: "/private",
        },
        {
            path: "/input",
            name: "input",
            element: <InputDemo />,
            layout: "/private",
        },
        {
            path: "/floatlabel",
            name: "floatlabel",
            element: <FloatLabelDemo />,
            layout: "/private",
        },
        {
            path: "/invalidstate",
            name: "invalidstate",
            element: <InvalidStateDemo />,
            layout: "/private",
        },
        {
            path: "/button",
            name: "button",
            element: <ButtonDemo />,
            layout: "/private",
        },
        {
            path: "/table",
            name: "table",
            element: <TableDemo />,
            layout: "/private",
        },
        {
            path: "/list",
            name: "list",
            element: <ListDemo />,
            layout: "/private",
        },
        {
            path: "/tree",
            name: "tree",
            element: <TreeDemo />,
            layout: "/private",
        },
        {
            path: "/panel",
            name: "panel",
            element: <PanelDemo />,
            layout: "/private",
        },
        {
            path: "/overlay",
            name: "overlay",
            element: <OverlayDemo />,
            layout: "/private",
        },
        {
            path: "/media",
            name: "media",
            element: <MediaDemo />,
            layout: "/private",
        },
        {
            path: "/menu",
            name: "menu",
            element: <MenuDemo />,
            layout: "/private",
        },
        {
            path: "/messages",
            name: "messages",
            element: <MessagesDemo />,
            layout: "/private",
        },
        {
            path: "/file",
            name: "file",
            element: <FileDemo />,
            layout: "/private",
        },
        {
            path: "/chart",
            name: "chart",
            element: <ChartDemo />,
            layout: "/private",
        },
        {
            path: "/misc",
            name: "misc",
            element: <MiscDemo />,
            layout: "/private",
        },
        {
            path: "/timeline",
            name: "timeline",
            element: <TimelineDemo />,
            layout: "/private",
        },
        {
            path: "/crud",
            name: "crud",
            element: <Crud />,
            layout: "/private",
        },
        {
            path: "/empty",
            name: "empty",
            element: <EmptyPage />,
            layout: "/private",
        },

        // ============================= Pesa Routes ==================================

        {
            path: "/users",
            name: "users",
            element: <NewUsersPage />,
            layout: "/admin",
        },

        {
            path: "/countries",
            name: "countries",
            element: <CountryPage />,
            layout: "/admin",
        },

        {
            path: "/regions",
            name: "regions",
            element: <RegionPage />,
            layout: "/admin",
        },

        {
            path: "/regions/districts",
            name: "/regionsdistricts",
            element: <DistrictsPage />,
            layout: "/admin",
        },
        {
            path: "/districts",
            name: "districts",
            element: <DistrictsPage />,
            layout: "/admin",
        },
        {
            path: "/regions/districts/counties",
            name: "districts/counties",
            element: <CountiesPage />,
            layout: "/admin",
        },

        {
            path: "/counties",
            name: "counties",
            element: <CountiesPage />,
            layout: "/admin",
        },

        {
            path: "/regions/districts/counties/subcounties",
            name: "/regionsdistricts/counties/subcounties",
            element: <SubCountiesPage />,
            layout: "/admin",
        },
        {
            path: "/subcounties",
            name: "subcounties",
            element: <SubCountiesPage />,
            layout: "/admin",
        },
        {
            path: "/regions/districts/counties/subcounties/parishes",
            name: "/regionsdistricts/counties/subcounties/parishes",
            element: <ParishesPage />,
            layout: "/admin",
        },
        {
            path: "/parishes",
            name: "parishes",
            element: <ParishesPage />,
            layout: "/admin",
        },
        {
            path: "/regions/districts/counties/subcounties/parishes/villages",
            name: "/regionsdistricts/counties/subcounties/parishes/villages",
            element: <VillagesPage />,
            layout: "/admin",
        },
        {
            path: "/villages",
            name: "villages",
            element: <VillagesPage />,
            layout: "/admin",
        },

        {
            path: "/notifications",
            name: "notifications",
            element: <NotificationsPage />,
            layout: "/admin",
        },

        {
            path: "/polls",
            name: "polls",
            element: <PollsPage />,
            layout: "/admin",
        },

        {
            path: "/feedback",
            name: "feedback",
            element: <FeedbacksPage />,
            layout: "/admin",
        },
        {
            path: "/sectors",
            name: "sectors",
            element: <SectorsPage />,
            layout: "/admin",
        },
        {
            path: "/directorates",
            name: "directorates",
            element: <DirectoratesPage />,
            layout: "/admin",
        },

        {
            path: "/directorates/directorate",
            name: "directorate",
            element: <DirectoratesViewPage />,
            layout: "/admin",
        },

        {
            path: "/groups",
            name: "groups",
            element: <GroupsPage />,
            layout: "/admin",
        },
        {
            path: "/groups/group",
            name: "/groupsgroup",
            element: <GroupsViewPage />,
            layout: "/admin",
        },

        {
            path: "/grouptypes",
            name: "grouptypes",
            element: <GroupTypesPage />,
            layout: "/admin",
        },
        {
            path: "/polls/questions",
            name: "questions",
            element: <QuestionsPage />,
            layout: "/admin",
        },
        {
            path: "/polls/questions/responses",
            name: "responses",
            element: <ResponsesPage />,
            layout: "/admin",
        },
        {
            path: "/services",
            name: "services",
            element: <ServicesFeedbacksPage />,
            layout: "/admin",
        },
        {
            path: "/faqs",
            name: "faqs",
            element: <FaqsPage />,
            layout: "/admin",
        },
        {
            path: "/topics",
            name: "topics",
            element: <TopicsPage />,
            layout: "/admin",
        },
        {
            path: "/audit-areas",
            name: "audit-areas",
            element: <AuditAreasPage />,
            layout: "/admin",
        },
        {
            path: "/audit-types",
            name: "audit-types",
            element: <AuditTypesPage />,
            layout: "/admin",
        },

        {
            path: "/user-manual",
            name: "user manual",
            element: <UserManualPage />,
            layout: "/admin",
        },
        {
            path: "/audit-trail",
            name: "audit trail",
            element: <SystemLogsPage />,
            layout: "/admin",
        },
        {
            path: "/quarters",
            name: "quarters",
            element: <QuartersPage />,
            layout: "/admin",
        },
        {
            path: "/financial-years",
            name: "financial-years",
            element: <FinancialYearsPage />,
            layout: "/admin",
        },
        {
            path: "/reports",
            name: "reports",
            element: <FeedbackReportsPage />,
            layout: "/admin",
        },
        {
            path: "/queue-jobs",
            name: "queue-jobs",
            element: <JobsPage />,
            layout: "/admin",
        },
    ];

    const asistantAuditorGeneralRoutes = [
        {
            path: "/dashboard",
            name: "dashboard",
            element: <DashboardPage />, // Replace with the actual component
            layout: "/private",
        },
        {
            path: "/dashboard-two",
            name: "dashboard-two",
            element: <DashboardTwoPage />, // Replace with the actual component
            layout: "/private",
        },

        // ============================= Pesa Routes ==================================

        {
            path: "/notifications",
            name: "notifications",
            element: <NotificationsPage />,
            layout: "/admin",
        },

        {
            path: "/polls",
            name: "polls",
            element: <PollsPage />,
            layout: "/admin",
        },

        {
            path: "/feedback",
            name: "feedback",
            element: <FeedbacksPage />,
            layout: "/admin",
        },

        {
            path: "/groups",
            name: "groups",
            element: <GroupsPage />,
            layout: "/admin",
        },
        {
            path: "/groups/group",
            name: "/groupsgroup",
            element: <GroupsViewPage />,
            layout: "/admin",
        },

        {
            path: "/polls/questions",
            name: "questions",
            element: <QuestionsPage />,
            layout: "/admin",
        },
        {
            path: "/polls/questions/responses",
            name: "responses",
            element: <ResponsesPage />,
            layout: "/admin",
        },
        {
            path: "/services",
            name: "services",
            element: <ServicesFeedbacksPage />,
            layout: "/admin",
        },
        {
            path: "/faqs",
            name: "faqs",
            element: <FaqsPage />,
            layout: "/admin",
        },

        {
            path: "/user-manual",
            name: "user manual",
            element: <UserManualPage />,
            layout: "/admin",
        },

        {
            path: "/reports",
            name: "reports",
            element: <FeedbackReportsPage />,
            layout: "/admin",
        },
    ];

    const auditorGeneralRoutes = [
        {
            path: "/dashboard",
            name: "dashboard",
            element: <DashboardPage />, // Replace with the actual component
            layout: "/private",
        },
        {
            path: "/dashboard-two",
            name: "dashboard-two",
            element: <DashboardTwoPage />, // Replace with the actual component
            layout: "/private",
        },

        // ============================= Pesa Routes ==================================

        {
            path: "/notifications",
            name: "notifications",
            element: <NotificationsPage />,
            layout: "/admin",
        },

        {
            path: "/polls",
            name: "polls",
            element: <PollsPage />,
            layout: "/admin",
        },

        {
            path: "/feedback",
            name: "feedback",
            element: <FeedbacksPage />,
            layout: "/admin",
        },

        {
            path: "/groups",
            name: "groups",
            element: <GroupsPage />,
            layout: "/admin",
        },
        {
            path: "/groups/group",
            name: "/groupsgroup",
            element: <GroupsViewPage />,
            layout: "/admin",
        },

        {
            path: "/polls/questions",
            name: "questions",
            element: <QuestionsPage />,
            layout: "/admin",
        },
        {
            path: "/polls/questions/responses",
            name: "responses",
            element: <ResponsesPage />,
            layout: "/admin",
        },
        {
            path: "/services",
            name: "services",
            element: <ServicesFeedbacksPage />,
            layout: "/admin",
        },

        {
            path: "/user-manual",
            name: "user manual",
            element: <UserManualPage />,
            layout: "/admin",
        },
        {
            path: "/reports",
            name: "reports",
            element: <FeedbackReportsPage />,
            layout: "/admin",
        },
    ];

    const commonRoutes = [
        {
            path: "/dashboard",
            name: "dashboard",
            element: <DashboardPage />, // Replace with the actual component
            layout: "/private",
        },
        {
            path: "/dashboard-two",
            name: "dashboard-two",
            element: <DashboardTwoPage />, // Replace with the actual component
            layout: "/private",
        },

        // ============================= Pesa Routes ==================================

        {
            path: "/notifications",
            name: "notifications",
            element: <NotificationsPage />,
            layout: "/admin",
        },

        {
            path: "/polls",
            name: "polls",
            element: <PollsPage />,
            layout: "/admin",
        },

        {
            path: "/feedback",
            name: "feedback",
            element: <FeedbacksPage />,
            layout: "/admin",
        },

        {
            path: "/groups",
            name: "groups",
            element: <GroupsPage />,
            layout: "/admin",
        },

        {
            path: "/groups/group",
            name: "/groupsgroup",
            element: <GroupsViewPage />,
            layout: "/admin",
        },

        {
            path: "/polls/questions",
            name: "questions",
            element: <QuestionsPage />,
            layout: "/admin",
        },
        {
            path: "/polls/questions/responses",
            name: "responses",
            element: <ResponsesPage />,
            layout: "/admin",
        },
        {
            path: "/faqs",
            name: "faqs",
            element: <FaqsPage />,
            layout: "/admin",
        },
        {
            path: "/user-manual",
            name: "user manual",
            element: <UserManualPage />,
            layout: "/admin",
        },

        {
            path: "/reports",
            name: "reports",
            element: <FeedbackReportsPage />,
            layout: "/admin",
        },
    ];

    const csoRoutes = [
        {
            path: "/dashboard",
            name: "dashboard",
            element: <DashboardPage />, // Replace with the actual component
            layout: "/private",
        },
        {
            path: "/dashboard-two",
            name: "dashboard-two",
            element: <DashboardTwoPage />, // Replace with the actual component
            layout: "/private",
        },

        // ============================= Pesa Routes ==================================

        {
            path: "/notifications",
            name: "notifications",
            element: <NotificationsPage />,
            layout: "/admin",
        },

        {
            path: "/polls",
            name: "polls",
            element: <PollsPage />,
            layout: "/admin",
        },

        {
            path: "/feedback",
            name: "feedback",
            element: <FeedbacksPage />,
            layout: "/admin",
        },

        {
            path: "/groups",
            name: "groups",
            element: <GroupsPage />,
            layout: "/admin",
        },
        {
            path: "/groups/group",
            name: "/groupsgroup",
            element: <GroupsViewPage />,
            layout: "/admin",
        },

        {
            path: "/polls/questions",
            name: "questions",
            element: <QuestionsPage />,
            layout: "/admin",
        },
        {
            path: "/polls/questions/responses",
            name: "responses",
            element: <ResponsesPage />,
            layout: "/admin",
        },
        {
            path: "/user-manual",
            name: "user manual",
            element: <UserManualPage />,
            layout: "/admin",
        },
    ];

    const routes = useRoutes([
        ...(activeUser?.role.includes("CSO Admin") ? csoRoutes : []),
        ...(["AAGC", "AAGA", "AG"].includes(activeUser?.role) ? asistantAuditorGeneralRoutes : []),
        ...(["AG"].includes(activeUser?.role) ? auditorGeneralRoutes : []),
        ...(activeUser?.role.includes("OAG Admin") ? adminRoutes : []),
        ...(!activeUser?.role.includes("OAG Admin") && !activeUser?.role.includes("AAGC") && !activeUser?.role.includes("CSO Admin") ? commonRoutes : []),

        { path: "*", element: <NotFoundPage /> }, // Handle 404
    ]);

    return routes;
}

export default AppRoutes;
