import axiosAPI from "../axiosApi";

export async function getAllSummarisedFeedbacks(params = {}) {
    const response = await axiosAPI.get("summarised-feedbacks", { params: params });
    return response;
}

export async function getSummarisedFeedbackById(id) {
    const response = await axiosAPI.get(`summarised-feedbacks/` + id);
    return response;
}

export async function postSummarisedFeedback(data) {
    const response = await axiosAPI.post(`summarised-feedbacks`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToUpdateSummarisedFeedbackStatus(data) {
    const response = await axiosAPI.post(`bulk-update-summarised-feedback-status`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToBulkDestroySummarisedFeedback(data) {
    const response = await axiosAPI.post(`summarised-feedbacks-bulk-delete`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToBulkStoreSummarisedFeedbackComments(data) {
    const response = await axiosAPI.post(`bulk-store-summarised-feedback-comments`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateSummarisedFeedback(id, data) {
    const response = await axiosAPI.post(`summarised-feedbacks/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteSummarisedFeedbackById(id) {
    const response = await axiosAPI.delete(`summarised-feedbacks/${id}`);
    return response;
}

//
export async function postToBulkSetSummarisedFeedbackSatisfactoryStatus(data) {
    const response = await axiosAPI.post(`set-summarised-feedback-satisfactory-action`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

//update audit areas

export async function postToUpdateSummarisedFeedbackSatisAuditAreas(data) {
    const response = await axiosAPI.post(`update-summarised-feedback-audit-areas`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

//update audit areas
export async function postToUpdateSummarisedFeedbackAuditTypes(data) {
    const response = await axiosAPI.post(`update-sum-feedback-audit-types`, data);
    return response;
}

export async function postToUpdateSummarisedFeedbackSatisAgreedActions(data) {
    const response = await axiosAPI.post(`update-summarised-feedback-agreed-actions`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToUpdateSumFBResDirectorate(data) {
    const response = await axiosAPI.post(`update-sum-feedback-res-directorate`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToUpdateSumFBTimelines(data) {
    const response = await axiosAPI.post(`update-sum-feedback-timelines`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToUpdateSumFbCurrentStatus(data) {
    const response = await axiosAPI.post(`updateSumFbCurrentStatus`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}
