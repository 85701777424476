import React, { useMemo, useState } from "react";
import { Dialog } from "primereact/dialog";
import moment from "moment"; // Import moment
import { Tag } from "primereact/tag";

import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { FileDownload } from "@mui/icons-material";
import { Grid, Typography, Card, IconButton } from "@mui/material";

import { TabView, TabPanel } from "primereact/tabview";

import useAuthContext from "../../../context/AuthContext.jsx";
import Comments from "./Comments.jsx";
import AuditAreas from "./AuditAreas";
import AuditTypes from "./AuditTypes";
import OriginalTaggedfeedback from "./OriginalTaggedfeedback";
import Timelines from "./Timelines.jsx";
import CurrentStatusDiaglog from "./CurrentStatusDiaglog";

//
import { Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import Actions from "./Actions.jsx";
import ResponsibleDirectorate from "./ResponsibleDirectorate";

//
import { getSummarisedFeedbackById } from "../../../services/feedback/summarised-feedback-service";
//
import useHandleQueryError from "../../../hooks/useHandleQueryError";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

//
//lotties
import MaterialUiLoaderLottie from "../../../assets/lotties/oag-lotties/material-ui-loading-lottie.json";
import SnailErrorLottie from "../../../assets/lotties/oag-lotties/snail-error-lottie.json";
import SateLiteLottie from "../../../assets/lotties/oag-lotties/satelite-loading-lottie.json";
import FileLoadingLottie from "../../../assets/lotties/oag-lotties/FileLoadingLottie.json";
import SkeletonLoadingLottie from "../../../assets/lotties/oag-lotties/SkeletonLoadingLottie.json";
import Lottie from "lottie-react";

function SummarisedFeedbackWithCommentsDetailsModal({ feedbackDetailData, showComments = true, ...props }) {
    console.log("🚀 ~ SummarisedFeedbackWithCommentsDetailsModal ~ feedbackDetailData fsdsdsd:", feedbackDetailData);

    const { getUserQuery } = useAuthContext();
    const activeUser = getUserQuery?.data?.data;

    const getSummarisedFeedbackByIdQuery = useQuery({
        queryKey: ["summarised_feedback", "by_id", feedbackDetailData?.id],
        queryFn: () => getSummarisedFeedbackById(feedbackDetailData?.id),
    });
    console.log("🚀 ~Summarised Feedbacks ListPage ~ data: ", getSummarisedFeedbackByIdQuery?.data);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(getSummarisedFeedbackByIdQuery?.isError, getSummarisedFeedbackByIdQuery?.error);

    const summarisedFeedbackData = getSummarisedFeedbackByIdQuery?.data?.data;

    const getSummarisedFeedbackSeverityColor = (status) => {
        switch (status) {
            case "submitted":
                return "info"; // Blue
            case "Director Stakeholder Engagement":
                return "primary"; // Dark Blue or any strong color to represent high-level management
            case "AAGC":
                return "warning"; // Yellow/Orange (indicating it's under AAGC review)
            case "Accepted by AAGC":
                return "success"; // Green (indicating approval or completion by AAGC)
            case "Rejected by AAGC":
                return "danger"; // Red (indicating rejection or failure by AAGC)
            case "Directorate Officer":
                return "info"; // Blue (indicating it's being handled by a Directorate Officer)
            case "in progress":
                return "warning"; // Yellow/Orange (indicating it's still in progress)
            case "done":
                return "success"; // Green (indicating completion)
            case "failed":
                return "danger"; // Red (indicating failure)
            case "not satisfied":
                return "danger"; // Red (indicating the task is completed but with an unsatisfactory outcome)
            case "completed":
                return "success"; // Green (indicating completion)
            default:
                return "secondary"; // Gray or any other default color for unknown statuses
        }
    };

    //
    const [showEditAgreedActionsDialog, setShowEditAgreedActionsDialog] = useState(false);
    const [agreedActionsType, setAgreedActionsType] = useState();

    const [showEditResDirectorateDialog, setShowEditResDirectorateDialog] = useState(false);
    const [resDirectorateType, setResDirectorateType] = useState();

    const [showEditTimelinesDialog, setShowEditTimelinesDialog] = useState(false);

    const [showEditCurrentStatusDialog, setShowEditCurrentStatusDialog] = useState(false);

    return (
        <Dialog
            header={
                <div>
                    <div style={{ color: "green" }}>Feedback Details</div>
                </div>
            }
            visible={props.show}
            style={{ width: "80%" }}
            onHide={props.onHide}
            maximizable
        >
            {getSummarisedFeedbackByIdQuery?.isLoading ? (
                <div className="col-12">
                    {/* <ProgressBar mode="indeterminate" style={{ height: "6px" }} /> */}
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ maxWidth: "100%" }}>
                            <Lottie animationData={SkeletonLoadingLottie} loop={true} style={{ height: "300px" }} autoplay={true} />
                            <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                        </div>
                    </div>
                </div>
            ) : getSummarisedFeedbackByIdQuery?.isError ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ maxWidth: "400px" }}>
                        <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                    </div>
                </div>
            ) : (
                <>
                    <TabView scrollable={true}>
                        <TabPanel header="Feedback details">
                            {/* Feedback Information */}

                            <div className="card" style={{ marginBottom: "1rem", width: "70vw" }} title="Feedback Information">
                                <Grid container spacing={2}>
                                    {/* Column 1 */}
                                    <Grid item sm={12} md={6} lg={3}>
                                        <Typography variant="body1">
                                            <strong>Feedback Number:</strong>{" "}
                                            <Tag
                                                value={summarisedFeedbackData?.feedback_no ? summarisedFeedbackData?.feedback_no : "N/A"}
                                                severity={getSummarisedFeedbackSeverityColor(summarisedFeedbackData?.spatie_current_status?.name)} // 'info' for blue, 'success' for green
                                            />
                                        </Typography>

                                        <Typography variant="body1">
                                            <strong>Title:</strong> {summarisedFeedbackData?.title || "N/A"}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Sector:</strong> {summarisedFeedbackData?.sector?.name || "N/A"}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Latitude:</strong> {summarisedFeedbackData?.latitude || "N/A"}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Longitude:</strong> {summarisedFeedbackData?.longitude || "N/A"}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Has Attachments:</strong> {summarisedFeedbackData?.has_attachments ? "Yes" : "No"}
                                        </Typography>
                                    </Grid>

                                    {/* Location Details */}
                                    {summarisedFeedbackData?.location_scope === "Central Government" && (
                                        <Grid item sm={12} md={6} lg={3}>
                                            <Typography variant="body1">
                                                <strong>Location Scope:</strong> {summarisedFeedbackData?.location_scope || "N/A"}
                                            </Typography>

                                            <Typography variant="body1">
                                                <strong>Region:</strong> {summarisedFeedbackData?.region?.name || "N/A"}
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>District:</strong> {summarisedFeedbackData?.district?.name || "N/A"}
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>County:</strong> {summarisedFeedbackData?.county?.name || "N/A"}
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>Subcounty:</strong> {summarisedFeedbackData?.subcounty?.name || "N/A"}
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>Parish:</strong> {summarisedFeedbackData?.parish?.name || "N/A"}
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>Village:</strong> {summarisedFeedbackData?.village?.name || "N/A"}
                                            </Typography>
                                        </Grid>
                                    )}

                                    {summarisedFeedbackData?.location_scope === "International" && (
                                        <Grid item sm={12} md={6} lg={3}>
                                            <Typography variant="body1">
                                                <strong>Location Scope:</strong> {summarisedFeedbackData?.location_scope || "N/A"}
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>Country:</strong> {summarisedFeedbackData?.country?.name || "N/A"}
                                            </Typography>
                                        </Grid>
                                    )}

                                    {summarisedFeedbackData?.location_scope === "Local Government" && (
                                        <Grid item sm={12} md={6} lg={3}>
                                            <Typography variant="body1">
                                                <strong>Location Scope:</strong> {summarisedFeedbackData?.location_scope || "N/A"}
                                            </Typography>

                                            <Typography variant="body1">
                                                <strong>Region:</strong> {summarisedFeedbackData?.region?.name || "N/A"}
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>District:</strong> {summarisedFeedbackData?.district?.name || "N/A"}
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>County:</strong> {summarisedFeedbackData?.county?.name || "N/A"}
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>Subcounty:</strong> {summarisedFeedbackData?.subcounty?.name || "N/A"}
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>Parish:</strong> {summarisedFeedbackData?.parish?.name || "N/A"}
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>Village:</strong> {summarisedFeedbackData?.village?.name || "N/A"}
                                            </Typography>
                                        </Grid>
                                    )}

                                    {/* Column 2 */}
                                    <Grid item sm={12} md={6} lg={3}>
                                        {/* <Typography variant="body1">
                                    <strong>Status:</strong> {tagStatus(summarisedFeedbackData?.status)}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Submit Status:</strong> {tagStatus(summarisedFeedbackData?.feedback_submit_status)}
                                </Typography> */}

                                        <Typography variant="body1">
                                            <strong>Created By:</strong> {summarisedFeedbackData?.created_by?.name || "N/A"}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Created At:</strong> {summarisedFeedbackData?.created_at ? moment(summarisedFeedbackData.created_at).format("MMMM Do YYYY, h:mm:ss a") : "N/A"}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Updated By:</strong> {summarisedFeedbackData?.updated_by?.name || "N/A"}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Updated At:</strong> {summarisedFeedbackData?.updated_at ? moment(summarisedFeedbackData.updated_at).format("MMMM Do YYYY, h:mm:ss a") : "N/A"}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Tagged to Report:</strong> {summarisedFeedbackData?.feedback_report_summarised_feedback?.feedback_report?.name ?? "N/A"}
                                        </Typography>
                                    </Grid>

                                    {/* Full Width Row for Message */}
                                    <Grid item xs={12}>
                                        <Typography variant="body1" style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                                            <strong>Message:</strong> {summarisedFeedbackData?.message || "No message provided."}
                                        </Typography>
                                    </Grid>

                                    {/* Spatie Status */}

                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            <strong>Current Status:</strong>{" "}
                                            <Tag value={summarisedFeedbackData?.spatie_current_status?.name ? summarisedFeedbackData?.spatie_current_status?.name : "N/A"} severity={getSummarisedFeedbackSeverityColor(summarisedFeedbackData?.spatie_current_status?.name)} />
                                            {activeUser?.permissions.includes("update summarised feedback current status") && (
                                                <IconButton
                                                    onClick={() => {
                                                        setShowEditCurrentStatusDialog(true);
                                                    }}
                                                >
                                                    <Tooltip title="Edit Current Status">
                                                        <div>
                                                            <EditIcon />
                                                        </div>
                                                    </Tooltip>
                                                </IconButton>
                                            )}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Reason:</strong> {summarisedFeedbackData?.spatie_current_status?.reason}
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={12} md={6} lg={3}>
                                        {/* proposed_actions */}
                                        <Typography variant="body1" style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                                            <strong>Proposed Actions:-</strong>{" "}
                                            {activeUser?.permissions.includes("update summarised feedback proposed actions") && (
                                                <IconButton
                                                    onClick={() => {
                                                        setAgreedActionsType("proposed");
                                                        setShowEditAgreedActionsDialog(true);
                                                    }}
                                                >
                                                    <Tooltip title="Edit Proposed Actions">
                                                        <div>
                                                            <EditIcon />
                                                        </div>
                                                    </Tooltip>
                                                </IconButton>
                                            )}
                                            <p>{summarisedFeedbackData?.proposed_actions || "No proposed actions provided yet."}</p>
                                        </Typography>

                                        {/* agreed_actions */}
                                        <Typography variant="body1" style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                                            <strong>Agreed Actions:-</strong>{" "}
                                            {activeUser?.permissions.includes("update summarised feedback agreed actions") && (
                                                <Tooltip title="Edit Agreed Actions">
                                                    <IconButton
                                                        onClick={() => {
                                                            setAgreedActionsType("agreed");
                                                            setShowEditAgreedActionsDialog(true);
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            <p>{summarisedFeedbackData?.agreed_actions || "No agreed actions provided yet."}</p>
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={12} md={6} lg={3}>
                                        <Typography variant="body1">
                                            <strong>Timelines:-</strong>{" "}
                                            {activeUser?.permissions.includes("update summarised feedback timelines") && (
                                                <Tooltip title="Edit Timelines">
                                                    <IconButton
                                                        onClick={() => {
                                                            setShowEditTimelinesDialog(true);
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            <p>Start Date : {summarisedFeedbackData?.timeline_start_date || "N/A"}</p>
                                            <p>End Date : {summarisedFeedbackData?.timeline_end_date || "N/A"}</p>
                                            {summarisedFeedbackData?.timeline_start_date && summarisedFeedbackData?.timeline_end_date && (
                                                <p>
                                                    {/* <strong>Date Range:</strong> {moment(summarisedFeedbackData?.timeline_start_date).format("MMMM Do YYYY")} - {moment(summarisedFeedbackData?.timeline_end_date).format("MMMM Do YYYY")}{" "} */}
                                                    <span>({moment.duration(moment(summarisedFeedbackData?.timeline_end_date).diff(moment(summarisedFeedbackData?.timeline_start_date))).humanize()})</span>
                                                </p>
                                            )}
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={12} md={6} lg={3}>
                                        <Typography variant="body1">
                                            <strong>Proposed Responsible Directorate:-</strong>{" "}
                                            {activeUser?.permissions.includes("update summarised feedback proposed responsible directorate") && (
                                                <Tooltip title="Edit Proposed Responsible Directorate">
                                                    <IconButton
                                                        onClick={() => {
                                                            setResDirectorateType("proposed");
                                                            setShowEditResDirectorateDialog(true);
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            <p>{summarisedFeedbackData?.proposed_directorate?.name || "N/A"}</p>
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Responsible Directorate:-</strong>{" "}
                                            {activeUser?.permissions.includes("update summarised feedback responsible directorate") && (
                                                <Tooltip title="Edit Responsible directorate">
                                                    <IconButton
                                                        onClick={() => {
                                                            setResDirectorateType("responsible");
                                                            setShowEditResDirectorateDialog(true);
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            <p>{summarisedFeedbackData?.responsible_directorate?.name || "N/A"}</p>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </TabPanel>

                        <TabPanel header="Tagged Feedback">
                            <OriginalTaggedfeedback feedbackDetailData={summarisedFeedbackData} />
                        </TabPanel>

                        <TabPanel header="Audit Areas">
                            <AuditAreas feedbackDetailData={summarisedFeedbackData} />
                        </TabPanel>

                        <TabPanel header="Audit Types">
                            <AuditTypes feedbackDetailData={summarisedFeedbackData} />
                        </TabPanel>

                        {showComments && (
                            <TabPanel header="Comments">
                                <Comments feedbackDetailData={summarisedFeedbackData} />
                            </TabPanel>
                        )}
                    </TabView>
                    <Actions type={agreedActionsType} feedbackDetailData={summarisedFeedbackData} show={showEditAgreedActionsDialog} onClose={() => setShowEditAgreedActionsDialog(false)} />
                    <ResponsibleDirectorate type={resDirectorateType} feedbackDetailData={summarisedFeedbackData} show={showEditResDirectorateDialog} onClose={() => setShowEditResDirectorateDialog(false)} />
                    <Timelines feedbackDetailData={summarisedFeedbackData} show={showEditTimelinesDialog} onClose={() => setShowEditTimelinesDialog(false)} />
                    <CurrentStatusDiaglog feedbackDetailData={summarisedFeedbackData} show={showEditCurrentStatusDialog} onClose={() => setShowEditCurrentStatusDialog(false)} />
                </>
            )}
        </Dialog>
    );
}

export default SummarisedFeedbackWithCommentsDetailsModal;
