import React, { useEffect, useState, useRef, useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import SummarisedFeedbackDetailsModal from "../../global_views/summarised_feedback/SummarisedFeedbackDetailsModal";

import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";

import { useNavigate } from "react-router-dom";

import { getAllSummarisedFeedbacks, getSummarisedFeedbackById, postSummarisedFeedback, postToBulkDestroySummarisedFeedback, updateSummarisedFeedback, deleteSummarisedFeedbackById } from "../../../services/feedback/summarised-feedback-service";

import { Dropdown } from "primereact/dropdown";
import MuiTable from "../../../components/general_components/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import Tooltip from "@mui/material/Tooltip";

import { Tag } from "primereact/tag";

//
import useHandleQueryError from "../../../hooks/useHandleQueryError";
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

//
import { MultiSelect } from "primereact/multiselect";
import { classNames } from "primereact/utils";

import { useReactToPrint } from "react-to-print";

import AdvancedFilterForm from "./AdvancedFilterForm";
import MuiTablePdfTemplateWithTable from "../../global_views/report_templates/MuiTablePdfTemplateWithTable";
import jsPDF from "jspdf";
import { isMobile } from "react-device-detect";
import InlineExpandableText from "../../../components/helpers/InlineExpandableText";

function ListPage({ loggedInUserData, productCategoryBrandData, ...props }) {
    const navigate = useNavigate();

    const [selectedFilters, setSelectedFilters] = useState({
        startDate: moment().subtract(30, "days").toDate(),
        endDate: moment().toDate(),
        selectedStatuses: [],
    });

    const queryClient = useQueryClient();
    const { data, isLoading, isError, error, status } = useQuery({
        queryKey: ["summarised_feedback", selectedFilters],
        queryFn: () => getAllSummarisedFeedbacks({ ...selectedFilters, statuses: selectedFilters?.selectedStatuses }),
    });
    console.log("🚀 ~Director Stakeholder Engagement Summarised Feedbacks ListPage ~ data: ", data);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(isError, error);

    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: (variables) => postToBulkDestroySummarisedFeedback(variables),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["summarised_feedback"] });
            toast.success("Deleted Successfully");
            setDeleteMutationIsLoading(false);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(deleteMutation?.error, setDeleteMutationIsLoading);

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (e, data) => {
        console.log("🚀 ~ handleDelete ~ data:", data);
        // let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(data),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteData) => {
        if (selectedDeleteData !== null) {
            setDeleteMutationIsLoading(true);
            deleteMutation.mutate({ feedbacks: selectedDeleteData });
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    //
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState(null);

    const handleShowFeedbackModal = (rowData) => {
        setSelectedFeedback(rowData);
        setShowFeedbackModal(true);
    };

    const handleCloseFeedbackModal = () => {
        setShowFeedbackModal(false);
    };

    const getSummarisedFeedbackSeverityColor = (status) => {
        switch (status) {
            case "submitted":
                return "info"; // Blue
            case "Director Stakeholder Engagement":
                return "primary"; // Dark Blue or any strong color to represent high-level management
            case "AAGC":
                return "warning"; // Yellow/Orange (indicating it's under AAGC review)
            case "Accepted by AAGC":
                return "success"; // Green (indicating approval or completion by AAGC)
            case "Rejected by AAGC":
                return "danger"; // Red (indicating rejection or failure by AAGC)
            case "Directorate Officer":
                return "info"; // Blue (indicating it's being handled by a Directorate Officer)
            case "in progress":
                return "warning"; // Yellow/Orange (indicating it's still in progress)
            case "done":
                return "success"; // Green (indicating completion)
            case "failed":
                return "danger"; // Red (indicating failure)
            case "not satisfied":
                return "danger"; // Red (indicating the task is completed but with an unsatisfactory outcome)
            case "completed":
                return "success"; // Green (indicating completion)
            default:
                return "secondary"; // Gray or any other default color for unknown statuses
        }
    };

    const columns = [
        { title: "#", width: "5%", field: "id", hidden: true },

        {
            title: "Feedback No",
            field: "feedback_no",
            render: (rowData) => (
                <Tag
                    value={rowData?.feedback_no ? rowData?.feedback_no : "N/A"}
                    severity={getSummarisedFeedbackSeverityColor(rowData?.spatie_current_status?.name)} // 'info' for blue, 'success' for green
                />
            ),
        },

        {
            title: "Title",
            field: "title",
            render: (rowData) => {
                const maxLength = 20; // Define max length before truncation
                const title = rowData?.title || "No title";
                return <InlineExpandableText text={title} maxLength={maxLength} />;
            },
        },
        {
            title: "Message",
            field: "message",
            render: (rowData) => {
                const maxLength = 20; // Define max length before truncation
                const message = rowData?.message || "No message";
                return <InlineExpandableText text={message} maxLength={maxLength} />;
            },
        },

        { title: "Sector", field: "sector.name", hidden: false },
        {
            title: "Has Attachments",
            field: "has_attachments",
            render: (rowData) => {
                return rowData?.has_attachments ? "Yes" : "No";
            },
            hidden: false,
        },
        { title: "Location Scope", field: "location_scope", hidden: false },
        { title: "Country", field: "country.name", hidden: false },
        { title: "Region", field: "region.name", hidden: false },
        { title: "District", field: "district.name", hidden: false },
        { title: "County", field: "county.name", hidden: false },
        { title: "Subcounty", field: "subcounty.name", hidden: false },
        { title: "Parish", field: "parish.name", hidden: false },
        { title: "Village", field: "village.name", hidden: false },
        { title: "Latitude", field: "latitude", hidden: false },
        { title: "Longitude", field: "longitude", hidden: false },

        { title: "Current Status", field: "spatie_current_status.name", hidden: false },
        { title: "Current Status Reason", field: "spatie_current_status.reason", hidden: false },
        { title: "Created By", field: "created_by.name", hidden: false },
        { title: "Created By Email", field: "created_by.email", hidden: false },
        { title: "Updated By", field: "updated_by.name", hidden: false },
        {
            title: "Date",
            field: "created_at",
            hidden: false,
            render: (rowData) => {
                return moment(rowData?.created_at).format("lll");
            },
        },
        {
            title: "Updated At",
            field: "updated_at",
            hidden: false,
            render: (rowData) => {
                return moment(rowData?.updated_at).format("lll");
            },
        },
        { title: "Proposed Directorate", field: "proposed_directorate.name", hidden: false },
        { title: "Responsible Directorate", field: "responsible_directorate.name", hidden: false },
        {
            title: "Timeline Start Date",
            field: "timeline_start_date",
            render: (rowData) => (rowData?.timeline_start_date ? moment(rowData?.timeline_start_date).format("MMMM Do YYYY") : "N/A"),
            hidden: false,
        },
        {
            title: "Timeline End Date",
            field: "timeline_end_date",
            render: (rowData) => (rowData?.timeline_end_date ? moment(rowData?.timeline_end_date).format("MMMM Do YYYY") : "N/A"),
            hidden: false,
        },
        {
            title: "Proposed Actions",
            field: "proposed_actions",
            render: (rowData) => {
                const maxLength = 50; // Max length for truncation
                const actions = rowData?.proposed_actions || "No actions proposed";
                return <InlineExpandableText text={actions} maxLength={maxLength} />;
            },
            hidden: false,
        },
        {
            title: "Agreed Actions",
            field: "agreed_actions",
            render: (rowData) => {
                const maxLength = 50; // Max length for truncation
                const actions = rowData?.agreed_actions || "No agreed actions";
                return <InlineExpandableText text={actions} maxLength={maxLength} />;
            },
            hidden: false,
        },
        { title: "Status", field: "status", hidden: true },
        { title: "Submit Status", field: "feedback_submit_status", hidden: true },
        {
            title: "View",
            field: "created_by.name",
            render: (rowData) => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleShowFeedbackModal(rowData)}>
                        <Tooltip title="Click to view feedback">
                            <VisibilityIcon style={{ color: "blue" }} />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    //============== status change ===================

    const handleSubmitAdvancedFilter = (formData) => {
        console.log("Form submitted with:", formData);

        setSelectedFilters(formData);
        // Handle form submission (e.g., API call, state update)
    };

    // Conditionally format start and end dates or use "N/A" if null
    const formattedStartDate = selectedFilters.startDate ? moment(selectedFilters.startDate).format("MMMM D, YYYY") : "N/A";
    const formattedEndDate = selectedFilters.endDate ? moment(selectedFilters.endDate).format("MMMM D, YYYY") : "N/A";

    return (
        <div style={{ width: "100%" }}>
            {/* <div className="col-12 xl:col-12">
                <div className="card">
                    <p>Funders Are Attched onto subprojects</p>
                </div>
            </div> */}
            <Panel header="Summarised Feedback Overview" style={{ marginBottom: "20px" }} toggleable>
                <div>
                    <AdvancedFilterForm initialData={selectedFilters} onSubmit={handleSubmitAdvancedFilter} />
                </div>

                <MuiTable
                    // tableTitle="Citized Feedback"
                    tableData={data?.data?.data ?? []}
                    tableColumns={columns}
                    // showEdit={activeUser?.permissions.includes("update feedback")}
                    // handleShowEditForm={handleShowEditForm}
                    // handleBulkDelete={(e, item) => handleDelete(e, item)}
                    // showBulkDelete={activeUser?.permissions.includes("delete feedback")}
                    loading={isLoading || status === "loading" || deleteMutationIsLoading}
                    // //
                    // handleViewPage={(rowData) => {
                    //     navigate("product-category-brand", { state: { productCategoryBrandData: rowData } });
                    // }}
                    // showViewPage={true}
                    // hideRowViewPage={false}
                    //
                    exportButton={true}
                    pdfExportTitle={`Citizen Feedback Plaform Report: ${formattedStartDate} - ${formattedEndDate}`}
                    csvExportTitle={`Citizen Feedback Plaform Report: ${formattedStartDate} - ${formattedEndDate}`}
                    //
                    pdfCustomExporting={true}
                    pdfCustomReportTemplate={MuiTablePdfTemplateWithTable}

                    // //multiple select
                    // selection={true}
                    // showSelectAllCheckbox={true}
                    // showTextRowsSelected={true}
                    // selectionChange={(selectedRows) => {
                    //     console.log("selected rows on sselection change : ", selectedRows);
                    //     setTableSelectedRows(selectedRows);
                    // }}
                />

                {showFeedbackModal && <SummarisedFeedbackDetailsModal feedbackDetailData={selectedFeedback} show={showFeedbackModal} onHide={handleCloseFeedbackModal} onClose={handleCloseFeedbackModal} />}
            </Panel>
        </div>
    );
}

export default ListPage;
