import React, { useState } from "react";
import ListPage from "./ListPage";
import BreadcrumbNav from "../../components/general_components/BreadcrumbNav";
import { TabView, TabPanel } from "primereact/tabview"; // Import TabView components
import useAuthContext from "../../context/AuthContext";

function RowReportsPage({ loggedInUserData }) {
    const { getUserQuery } = useAuthContext();
    const [activeIndex, setActiveIndex] = useState(0); // State to manage active tab index

    return (
        <div>
            <BreadcrumbNav />

            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Recieved">
                    {/* Render the ListPage component with submitted feedback */}
                    <ListPage loggedInUserData={getUserQuery?.data?.data} spatieStatus="submitted" />
                </TabPanel>
                <TabPanel header="In Progress">
                    {/* Render the ListPage component with in-progress feedback */}
                    <ListPage loggedInUserData={getUserQuery?.data?.data} spatieStatus="in progress" />
                </TabPanel>
                <TabPanel header="Resolved">
                    {/* Render the ListPage component with resolved feedback */}
                    <ListPage loggedInUserData={getUserQuery?.data?.data} spatieStatus="resolved" />
                </TabPanel>

                <TabPanel header="Not Applicable">
                    {/* Render the ListPage component with resolved feedback */}
                    <ListPage loggedInUserData={getUserQuery?.data?.data} spatieStatus="not applicable" />
                </TabPanel>

                <TabPanel header="Compliments">
                    {/* Render the ListPage component with resolved feedback */}
                    <ListPage loggedInUserData={getUserQuery?.data?.data} spatieStatus="compliment" />
                </TabPanel>
            </TabView>
        </div>
    );
}

export default RowReportsPage;
