import axiosAPI from "../axiosApi";

export async function getServicesFeedbackByStatusPieChartStats(params = {}) {
    const response = await axiosAPI.get("getServicesFeedbackByStatusPieChartStats", { params: params });
    return response;
}

export async function getServicesFbByTopicBarChartStats(params = {}) {
    const response = await axiosAPI.get("getServicesFbByTopicBarChartStats", { params: params });
    return response;
}
