import axiosAPI from "../axiosApi";

export async function getAllAuditTypes(params = {}) {
    const response = await axiosAPI.get("audit-types", { params: params });
    return response;
}

export async function getAuditTypesById(id) {
    const response = await axiosAPI.get(`audit-types/` + id);
    return response;
}

export async function postAuditType(data) {
    const response = await axiosAPI.post(`audit-types`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateAuditType(id, data) {
    const response = await axiosAPI.post(`audit-types/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteAuditTypeById(id) {
    const response = await axiosAPI.delete(`audit-types/${id}`);
    return response;
}
