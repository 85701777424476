import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

//
import ActionsRow from "./widgets/ActionsRow.jsx";
import useAuthContext from "../../../context/AuthContext.jsx";

//
import { postToUpdateSummarisedFeedbackSatisAgreedActions } from "../../../services/feedback/summarised-feedback-service.js";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

import moment from "moment";

//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

function Actions({ feedbackDetailData, type, ...props }) {
    console.log("🚀 ~ Actions ~ type:", type);
    const { getUserQuery } = useAuthContext();
    const activeUser = getUserQuery?.data?.data;

    const queryClient = useQueryClient();

    const [creactMutationIsLoading, setCreactMutationIsLoading] = useState(false);
    const creactMutation = useMutation({
        mutationFn: postToUpdateSummarisedFeedbackSatisAgreedActions,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["summarised_feedback", "by_id", feedbackDetailData?.id] });
            // queryClient.invalidateQueries({ queryKey: ["summarised_feedback"] });
            queryClient.invalidateQueries({ queryKey: ["feedback-reports", "by_id"] });
            // queryClient.invalidateQueries({
            //     queryKey: ["summarised_feedback", "by_id", feedbackDetailData?.id],
            //     exact: true,
            // });
            toast.success("Updated Successfully");
            setCreactMutationIsLoading(false);
            props.onClose();
        },
    });
    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(creactMutation?.error, setCreactMutationIsLoading);

    const handleSubmit = async (data) => {
        setCreactMutationIsLoading(true);

        creactMutation.mutate({ actions: data?.actions, type, summarised_feedback_id: feedbackDetailData?.id });
    };

    return (
        <Dialog header={`${type} actions`} visible={props.show} maximizable style={{ minWidth: "50vw" }} onHide={() => props.onClose()}>
            <p>Fill in the form below</p>
            <ActionsRow
                onSubmit={handleSubmit}
                initialData={{
                    actions: type === "agreed" ? feedbackDetailData?.agreed_actions : feedbackDetailData?.proposed_actions,
                }}
            />
            {/* <h4>{creactProgramsMutation.status}</h4> */}
            {creactMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
        </Dialog>
    );
}

export default Actions;
