import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";

import UpdateStatusMutation from "./mutations/UpdateStatusMutation";
import AcceptOrRejectMutation from "./mutations/AcceptOrRejectMutation";
import SendReportToAAGAMutation from "./mutations/SendReportToAAGAMutation";
import SendReportToAMCMutation from "./mutations/SendReportToAMCMutation";

function TakeActionForm({ level, ...props }) {
    return (
        <Dialog header="Take Action" visible={props.show} maximizable style={{ minWidth: "50vw" }} onHide={() => props.onHide()}>
            {["reports", "Director Stakeholder Engagement"].includes(level) && <UpdateStatusMutation {...props} />}
            {["AAGC", "Rejected by AAGC"].includes(level) && <AcceptOrRejectMutation {...props} />}
            {["Accepted by AAGC"].includes(level) && <SendReportToAAGAMutation {...props} />}
            {["AAGA"].includes(level) && <SendReportToAMCMutation {...props} />}
        </Dialog>
    );
}

export default TakeActionForm;
